<template >
    <div id="support">
        <div class="title">
            <div class="container">
                <div class="row title-div">
                    <div class="col-10">
                        <div class="text-center d-flex flex-wrap justify-content-center">
                            <div>
                                <h2 class="mb-3">DECENTRALISED AUTONOMOUS ORGANISATION (DAO)</h2>
                            </div>                            
                            <div style="width:750px;">
                                <p>Join our decentralized community protecting the integrity of the Protocol through research, discussion and on-chain voting.</p>
                                                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="title-bg"></div>
        <div class="row d-flex justify-content-center">
            <div class="daoNav-wrap">
                <div class="daoNav d-flex justify-content-center mb-4" >
                    <div class="daoTabWrap">
                        <div class="m-2 pt-2 daoTab">
                            <router-link  to="/polling" class="preeBtn activeBtn"><b>Polling Votes</b></router-link>
                        </div>
                        <div class="m-2 pt-2 daoTab">
                            <router-link  to="/proposal" class="preeBtn "><b>New Proposal</b></router-link>
                        </div>
                    </div>
                    <div class="daoTabWrap">
                        <div class="m-2 pt-2 daoTab">
                            <router-link  to="/bot" class="preeBtn"><b>Board of Trustee</b></router-link>
                        </div>
                    
                        <div class="m-2 pt-2 daoTab">
                            <button type="button" class="preBtn" style="padding-left:0px;" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">
                                <span class="me-3">&#9654;</span><b>How to vote</b>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-top">
                <div class="p-top">
                    <h5><b>Langkah Sheraton DAO Voting Guideline</b></h5>            
                </div> 
                <div>
                    <p>As owner of Langkah Sheraton NFT, you are also responsible for the management and art kick of the Langkah Sheraton Painting. Therefore every NFT owner are eligible to vote for any action to be taken for the painting. 
                    Below are the illustration of the proposal submission and voting process. 
                    </p>
                </div>
                <div class="p-bottom" >
                    <div class="content-left"><img src="../img/polling_new.png" alt=""></div>
                    <div class="content-right"><i data-bs-toggle="modal" data-bs-target="#exampleModalCenter">Check out our <b>video</b> to see the process of how to vote.</i></div>
                </div>
                
            </div>
            <div class="text-center mt-5">                
                <h5>Poling Votes</h5>
                <p>Polls take place to establish a consensus of community sentiment before votes are conducted.</p>
            </div>
            <div class="section-title">
                <h5><b>Motion</b></h5>            
            </div>  
            <div class="text-center" v-if="preproposal.length == 0">
                <span>Motion list is empty</span>
            </div>
            <div class="support" v-for="proposal in preproposal" :key="'dao'+proposal.id">                
                <div style="width:100%;">
                    <div>
                        <span>POSTED {{((String(new Date(proposal.createdAt))).substring(0,21).toUpperCase())}} | POLL ID {{("00" + proposal.id).slice(-3)}}</span>
                    </div>
                    <div class="my-2">
                        <h5><b>{{proposal.proposalTitle}}</b></h5>
                    </div>
                    <div v-if="((proposal.proposalDesc).length)<180" style="min-height:50px">
                        <p>{{(proposal.proposalDesc).substr(0, 95)}}</p>
                    </div>
                    <div v-else style="min-height:50px">
                        <p>{{(proposal.proposalDesc).substr(0, 95) + "...."}}</p>                        
                    </div>
                    <div class="d-flex">
                        <div class="me-3 mt-3">
                            <span>&#9719; {{timeRemaining(proposal.predeadline)}}</span>
                            <!-- <span>{{proposal.predeadline}}</span> -->                            
                        </div>                                                                                              
                    </div>                    
                    <!-- <div class="mt-2" v-if="userAddress == (proposal.proposeBy).toLowerCase()">
                        <span>You proposed this proposal</span> 
                    </div> -->
                    <div class="mt-2" v-if="userAddress == proposal.proposeBy">
                        <span style="font-size:14px;">You proposed this proposal</span> 
                    </div>
                    <div v-else class="mt-2">                        
                        <div v-if="proposal.isVote == true">
                            <div v-if="proposal.option == 'Support'">
                                <span style="font-size:14px;">You have <span class="y-vote">supported</span> this proposal</span>                                
                            </div>
                            <div v-else>
                                <span style="font-size:14px;">You have <span class="n-vote">opposed</span> this proposal</span>
                            </div>
                        </div>
                        <div v-else class="d-flex gap-1">
                            <button class="motion-btn activeBtn" @click="support(proposal.id,1)"><b style="font-size:13px;">YES</b></button>
                            <button class="motion-btn" style="width:54px;" @click="support(proposal.id,0)"><b style="font-size:13px;">NO</b></button>                         
                        </div>
                    </div>
                </div>
                <div style=" text-align:right;">
                    <div>
                        <div class="d-flex justify-content-end" >
                            <div class="status"><span style="font-size:14px; color:#00B050;">Active</span></div>
                        </div>
                        <div class="result"><h6><b>CURRENT SUPPORTED MOTION</b></h6></div>
                        <!-- <span class="y-vote">{{((proposal.support)/500*100).toFixed()}}%</span> -->
                        <span class="y-vote">{{proposal.support}} / 500</span>
                    </div>
                </div>                
            </div>

            <!-- from dao contract -->
            <div class="section-title-dao">
                <h5><b>DAO Proposal</b></h5>            
            </div>
            <!-- <div class="proposal-wrap"> -->
                <div class="text-center" v-if="paginatedDao.length == 0">
                    <span>DAO Proposal list is empty</span>
                </div>
                <div class="support" v-for="dao in paginatedDao" :key="'proposal'+dao.id">
                    <div style="width:80%;">
                        <div>
                            <span>POSTED {{((String(new Date(dao.createdAt))).substring(0,21)).toUpperCase()}} | POLL ID {{("00" + dao.id).slice(-3)}}</span>
                        </div>
                        <div class="my-1">
                            <h5><b>{{dao.proposalTitle}}</b></h5>
                        </div>
                        <div v-if="((dao.proposeDesc).length)<180" style="min-height:20px">
                            <p>{{(dao.proposeDesc).substr(0, 95)}}</p>
                        </div>
                        <div v-else style="min-height:20px">
                            <p>{{(dao.proposeDesc).substr(0, 95) + "...."}}</p>                        
                        </div>
                        <div class="d-flex">
                            <div class="me-3" style="min-height:30px">
                                <div class="gap-1 d-flex align-items-center" v-if="dao.propose_status == 'Open'">
                                    <span style="font-size:20px;">&#9719;</span><span> {{timeRemaining(dao.deadline)}}</span>
                                </div>                                                      
                            </div>                                              
                        </div>
                        <div class="mt-2">
                            <a v-bind:href="'/vote/'+dao.propose_id" class="detail-btn2 activeBtn"><b style="font-size:13px;">VIEW DETAILS</b></a>
                        </div>
                    </div>
                    <div class="right-content">
                        <div class="d-flex justify-content-center">
                            <div v-if="dao.propose_status == 'Open'" class="status">
                                <span style="color:#00B050;">Active</span>
                            </div>
                            <div v-else class="status">
                                <span style="color:#ff0000;">Closed</span>
                            </div>
                        </div>
                        <div class="result">
                            <div><h6><b>RESULTS</b></h6></div>
                            <div class="percentage">
                                <div v-if="isNaN(dao.for_total/(dao.for_total+dao.against_total)) == true">
                                    <span class="y-vote">0%</span>
                                </div>
                                <div v-else>
                                    <span class="y-vote">{{((dao.for_total/(dao.for_total+dao.against_total))*100).toFixed()}}%</span>                                
                                </div>
                                | 
                                <div v-if="isNaN(dao.against_total/(dao.for_total+dao.against_total)) == true">
                                    <span class="n-vote">0%</span>
                                </div>
                                <div v-else>
                                    <span class="n-vote">{{((dao.against_total/(dao.for_total+dao.against_total))*100).toFixed()}}%</span>                                
                                </div>
                            </div>
                        </div>                      
                    </div>
                </div>
            <!-- </div> -->
            <!-- <div class="pagination">
                <b-pagination
                    @change="onPageChanged"
                    :total-rows="totalDaoRows"
                    :per-page="perPage"
                    v-model="currentDaoPage"
                    class="my-0"
                />
            </div> -->
            

            

            <!-- <div class="support" v-for="dao in daoproposal" :key="'proposal'+dao.id">
                <div style="width:80%;">
                    <div>
                        <span>POSTED {{(String(new Date(dao.createdAt))).substring(0,21)}} | POLL ID {{("00" + dao.id).slice(-3)}}</span>
                    </div>
                    <div class="my-2">
                        <h5><b>{{dao.proposalTitle}}</b></h5>
                    </div>
                    <div v-if="((dao.proposeDesc).length)<180" style="min-height:50px">
                        <p>{{(dao.proposeDesc).substr(0, 180)}}</p>
                    </div>
                    <div v-else style="min-height:50px">
                        <p>{{(dao.proposeDesc).substr(0, 180) + "...."}}</p>                        
                    </div>
                    <div class="d-flex mt-3">
                        <div class="me-3">
                            <div v-if="dao.propose_status == 'Open'">
                                <span style="font-size:20px;">&#9719;</span><span> {{timeRemaining(dao.deadline)}}</span>
                            </div>                                                      
                        </div>                                              
                    </div>
                    <div class="mt-3">
                        <a v-bind:href="'/vote/'+dao.propose_id" class="detail-btn2 activeBtn"><b style="font-size:13px;">VIEW DETAILS</b></a>
                    </div>
                </div>
                <div class="right-content">
                    <div class="d-flex justify-content-center">
                        <div v-if="dao.propose_status == 'Open'" class="status">
                            <span style="font-size:14px; color:#008000;">Active</span>
                        </div>
                        <div v-else class="status">
                            <span style="font-size:14px; color:#ff0000;">Closed</span>
                        </div>
                    </div>
                    <div class="result">
                        <div><h6><b>RESULTS</b></h6></div>
                        <div class="percentage">
                            <div v-if="isNaN(dao.for_total/dao.for_total+dao.against_total) == true">
                                <span class="y-vote">0%</span>
                            </div>
                            <div v-else>
                                <span class="y-vote">{{((dao.for_total/dao.for_total+dao.against_total)*100).toFixed()}}%</span>                                
                            </div>
                            | 
                            <div v-if="isNaN(dao.against_total/dao.for_total+dao.against_total) == true">
                                <span class="n-vote">0%</span>
                            </div>
                            <div v-else>
                                <span class="n-vote">{{((dao.against_total/dao.for_total+dao.against_total)*100).toFixed()}}%</span>                                
                            </div>
                        </div>
                    </div>                      
                </div>
            </div> -->



            <!-- Modal -->
            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">                    
                        <div class="modal-body">
                            <video width="100%" controls loop>
                                <source src="../img/LS_Guide.mp4" type="video/mp4"> 
                            </video>
                        </div>                    
                    </div>
                </div>
            </div>
          
        </div>
    </div>
</template>

<script>

import axios from "axios";
import {ethers} from "ethers";
import ABImint from "/src/ABI/mint.json";
import WalletConnectProvider from "@walletconnect/web3-provider";

const providerWC = new WalletConnectProvider({
    rpc: {
    //   56:"https://bsc-dataseed.binance.org/"
        137:"https://polygon-rpc.com/"
    },
    qrcodeModalOptions: {
      mobileLinks: [
        "rainbow",
        "metamask",
        "argent",
        "trust",
        "imtoken",
        "pillar",
      ]
    }
});

// console.log(useraddress);
export default {
    name: 'preproposal',
    data(){
        return{   
            mintAddress: process.env.VUE_APP_MINT_ADDRESS,
            mintAbi: ABImint,
            userAddress: "",
            preProposeData: "",
            preDataLength: "",
            preproposal:[],

            daoData:"",
            daoDataLength: "",
            daoproposal: [],

            nomineeData:"",
            nomineeDataLength: "",
            nomineeList: [],

            paginatedDao: "",
            currentDaoPage: 1,
            perPage: 5,
            totalDaoRows: "",

            providerType:"",

        }
    },

    async mounted() {
        if(sessionStorage.connectType == "WC"){
            console.log('testing');
            console.log(providerWC);
            await providerWC.enable();
            this.providerType = providerWC;
        }else{
            this.providerType = window.ethereum;
        }

        window.scrollTo(0, 0) //load page from top
        // this.metaMask();
        this.getAddressTest();
        this.getBot();
    },

    methods:{
        //-- pagination --//
        paginateDao(page_number) {
            this.currentDaoPage = page_number
            this.getDao()
        },
        onPageChanged(page) {
            this.paginateDao(page);
        },
        //---//
        test(){
            axios.get(            
            'https://api.langkahsheraton.com/api/proposal/getOpenProposals', {
                params: {
                    address: this.userAddress,
                }
            }
            )
            .then((response) => {
                // console.log(this.userAddress)
                // console.log(response)
                this.preProposeData = response.data.data
                // console.log(Object.values(this.preProposeData[0]))
                this.preDataLength = this.preProposeData.length;  
                // console.log(this.preDataLength)
                
                for (let i = 0; i < this.preProposeData.length; i++){                    
                    // let test = Object.values(this.preProposeData[i])[9]
                    // console.log(Object.values(test))
                    // let test2 = Object.values(Object.values(test)[0])[1]
                    // console.log(test2)
                    // this.preProposeData[i].
                    // console.log(this.preProposeData[i])
                    // this.preProposeData[i].push(test2)
                    this.preproposal.push(this.preProposeData[i])                    
                }
                // console.log(this.preproposal);

            })
            .catch(error =>{
                console.log(error)
            });
        },
        getDao(){
            axios.get(            
            'https://api.langkahsheraton.com/api/dao/getAllDAO', {
                params: {
                    address: this.userAddress,
                    page: this.currentDaoPage,
                    pageSize: 1000
                }
            }
            )
            .then((response) => {
                this.daoData = response.data.data                
                this.daoDataLength = this.daoData.length;
                this.totalDaoRows = response.data.count
                this.daoproposal = []
                for (let i = 0; i < this.daoData.length; i++){                      
                    this.daoproposal.push(this.daoData[i])                    
                }

            })
            .then(()=>{
                this.paginatedDao = this.daoproposal;
                // console.log(this.paginatedDao, this.totalDaoRows)
            })
            .catch(error =>{
                console.log(error)
            });
        },
        getBot(){
            axios.get(            
            'https://api.langkahsheraton.com/api/botvote/getBOTVoteDetails'
            )
            .then((response) => {
                // console.log(this.userAddress)
                // console.log(response)
                this.nomineeData = response.data.data
                this.nomineeDataLength = this.nomineeData.length;  
                
                for (let i = 0; i < this.nomineeData.length; i++){ 
                    this.nomineeList.push(this.nomineeData[i])                    
                }
                // console.log(this.nomineeList);

            })
            .catch(error =>{
                console.log(error)
            });
        },
        async getAddressTest(){
            // console.log('test')
            try{
                let provider = new ethers.providers.Web3Provider(this.providerType)
                const signer = provider.getSigner()
                this.userAddress = await signer.getAddress()
                // console.log(this.userAddress)
                this.test()
                this.getDao()
            }catch(e){
                console.log(e)
                this.test()
                this.getDao()
            }               
        },
        // getAddress(){
        //     console.log('test')
        //     let provider = new ethers.providers.Web3Provider(window.ethereum)                
        //     provider.send("eth_requestAccounts", []);
        //     window.ethereum.request({method: 'eth_requestAccounts'})
        //     .catch((error) => {
        //         console.log(error); 
        //     })
        //     .then(async () => {
        //         // const address = window.ethereum.selectedAddress; 
        //         // this.userAddress = address;                
        //         const signer = provider.getSigner()
        //         this.userAddress = await signer.getAddress()
        //         console.log(this.userAddress)
        //     })        
        // },
        timeRemaining(time){
            var date1 = new Date();
            var date2 = new Date(time);
            // console.log(date1 , date2);

            var diff = new Date(date2.getTime() - date1.getTime());
            // console.log(diff)   

            var days = diff.getUTCDate()-1;
            var hours = diff.getUTCHours();
            var mins = diff.getUTCMinutes();

            if(hours == 0){
                return(mins+"min REMAINING")
            }else{
                return(days+"D "+hours+"H REMAINING")
            }
        },
        async support(preId,choice){
            try{
                let provider = new ethers.providers.Web3Provider(this.providerType) 
                const signer = provider.getSigner()
                let message = 'Support Proposal';
                let signature = await signer.signMessage(message);
                let supportVote = "";
                // let test = preId;
                // const address = window.ethereum.selectedAddress;
                const address = await signer.getAddress();
                // console.log(address)
                try{
                    const contract = new ethers.Contract(this.mintAddress, this.mintAbi, signer);
                    let balance = await contract.balanceOf(address)
                    // console.log(parseInt(balance._hex))
                    if(parseInt(balance._hex) > 0){
                        if(choice == 1){
                            supportVote = "Support"
                        }else if (choice == 0){
                            supportVote = "NotSupport"
                        }
                        // console.log(supportVote)
                        // console.log(message)
                        // console.log(signature)
                        // console.log(test)

                        await axios.post('https://api.langkahsheraton.com/api/proposal/castPreVote', {
                            proposalID: parseInt(preId),
                            address: await signer.getAddress(),
                            signature: signature,
                            message: message,
                            voteSelect: supportVote
                        },{ headers: {'Authorization' : `Bearer ${sessionStorage.bt}`} }).then((response) => {
                            console.log(response)  
                            if (response.statusText == "Created"){
                                this.$swal.fire({
                                    icon: 'success',
                                    title: 'Motion Voted',
                                    text: response.data.message,
                                    confirmButtonText: 'OK',
                                    // allowOutsideClick: false,                        
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        window.location.replace("/polling");
                                    }
                                    if (result.isDismissed) {
                                        window.location.replace("/polling");
                                    }
                                });
                            }               
                        })
                        .catch(error =>{
                            console.log(error)
                        });
                    }else{
                        this.$swal.close()
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Insufficient NFTs',
                            text: 'You do not own any Langkah Sheraton NFTs.',
                            confirmButtonText: 'OK',                       
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload()
                            }
                            if (result.isDismissed) { 
                                window.location.reload()                  
                            }
                        });
                    }
                }catch(e){
                    console.log(e.message)
                    this.$swal.close()
                    this.$swal.fire({
                    icon: "error",
                    title: 'voting failed',
                    html: 'Voting transaction failed. Please try again.',
                    confirmButtonText: 'CONTINUE',
                    background: "#FDF0E9",                       
                    }).then((result) => {
                        if (result.isConfirmed) {
                        window.location.reload()
                        }
                        if (result.isDismissed) { 
                        window.location.reload()                  
                        }
                    });
                }
            }catch(e){
                console.log(e)
            }
            
        },
        async metaMask(){
            if(sessionStorage.connect != "true"){
                this.$swal.fire({
                    icon: 'error',
                    title: 'MetaMask not connected',
                    text: 'Please connect to your Metamask wallet.',
                    confirmButtonText: 'Return',
                    allowOutsideClick: false,                        
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/home");
                    }
                });
            }            
        }
    }
}
</script>

<style>
.proposal-wrap{
    width:auto;
    min-height: 1100px;
    border: 1px solid #2c3e50;
    padding: 10px;
    margin: 10px;
}
.pagination{
    display: flex;
    justify-content: center;
}
.content-top{
    
    position: relative;
    width:1000px;
    padding: 15px;
    border-bottom: 2px solid #2c3e50

}

.p-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-title,
.section-title-dao{
    width:1000px;
    padding-top: 2%;
}

.section-title-dao{
    margin-top:3%;
}

.p-bottom{
    display: flex;
    gap:10%;

}

.p-bottom .content-left img{
    max-width:430px;
    margin-top: 5%;
}

.content-right i{
    position: absolute;
    bottom: 2%;
    right: 0%;
}

.content-right i:hover{
    text-decoration: underline;
}

.y-vote{
    color:#00B050;

}

.n-vote{
    color: red;
}


td > a{
    text-decoration: none;
    color:rgb(0, 0, 0);
}

td > a:hover{
    color:rgb(0, 0, 0);
    text-decoration-line: underline;
}

.support{
    position: relative;
    width:1000px;
    height: inherit;
    border-style: solid;
    border-color: #2c3e50;
    /* border-radius: 16px; */
    border-width: 2px;
    padding: 16px 15px;
    display: flex;
    justify-content: space-between;
    margin: 5px 0; 
     
}

.preBtn {
    background-color: transparent;
    border: solid 2px #2c3e50;
    color:#2c3e50;
    border-radius: 20px;
    padding: 7px 35px;
}

.preeBtn {
    background-color: transparent;
    border: solid 2px #2c3e50;
    color:#2c3e50;
    border-radius: 20px;
    padding: 11px 35px;
}

#support .status {
    background-color: transparent;
    border: solid 2px #2c3e50;
    color:#2c3e50;
    border-radius: 20px;
    padding: 1px 8px;
    width: auto;
}

#support .result {
    /* position: absolute;
    bottom: 0; */
    margin-top: 80px;
}

#support .percentage{
    display:flex;
    justify-content: space-between;
}

.right-content{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}



.detail-btn {
    background-color: transparent;
    color:#2c3e50;
    padding: 3px 12px;
}

.detail-btn2 {
    background-color: transparent;
    color:#2c3e50;
    padding: 8px 14px;
}

.motion-btn {
    background-color: white;
    color:#2c3e50;
    padding: 3px 12px;
}

/* #support a{
    text-decoration: none;
    color:white;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
} */

#support a:hover,
#propose a:hover,
#proposal a:hover{
    color:#2c3e50;
}

#support .modal-content,
#proposal .modal-content,
#propose .modal-content{
    background: transparent !important;
    border: none !important;
}

.bot-wallet-address{
    word-wrap: break-word;
}

#support .support span{
    font-size: 14px;
}

@media screen and (max-width: 1024px){
    .p-bottom{
        flex-wrap: wrap;
    }
    .content-right > i{
        position:relative;
    }
    .content-left > img{
        height: auto;
        width: 100%;
        margin: 5% 0;
    }    
}

/* DAO nav */
.daoTabWrap{
    width: 100%;
    display: flex;
    justify-content: center;
}

.daoTab{
    width: 230px;
    display: flex;
    justify-content: center;
}

.daoTab a, .daoTab button{
    display: flex;
    justify-content: center;
    padding: 7px 15px;
    width: 95%;
}

@media screen and (min-width: 1025px){
    .daoNav{
        width: 800px;
    }
    .daoNav a{
        height: 100%;
    }
    .daoNav-wrap{
        width: 1000px;
        display: flex;
        justify-content: center;
    }

    #exampleModalCenter .modal-dialog{
        max-width: 80% !important;
    }  

}

@media screen and (max-width: 1024px){
    
    .daoTabWrap{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .daoTab{
        margin-left: 3% !important;
        margin-right: 3% !important;
        width: 150px;
    }
    .daoTab a, .daoTab button{
        display: flex;
        justify-content: center;
        padding: 11px 15px;
        font-size: 14px;
        width: 100%;
    }
    .daoNav{
        flex-wrap:wrap;
    }

    .support {
        width: 100% !important;
    }
}

@media screen and (min-width: 2000px){
 .daoNav-wrap{
    width: 100% !important;
 }
 .section-title, .section-title-dao, .support, .top-btn-wrap, .proposal{
    width: 60% !important;
 }
 .board{
    margin: 0 auto !important;
 }
}



</style>