import Vue from 'vue'
import App from './App.vue'
import 'bootstrap'
import router from './router'
import $ from "jquery"
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2';
import { BSidebar } from 'bootstrap-vue'

import 'sweetalert2/dist/sweetalert2.min.css';
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false
Vue.use($)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueSweetalert2)
Vue.component('b-sidebar', BSidebar)


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
