import Vue from "vue";
import VueRouter from  "vue-router";
import Home from "../views/home.vue"
import NFTCollection from "../views/mint.vue"
import Proposal from "../views/propose.vue"
import About from "../views/about.vue"
import DAP from "../views/dap.vue"
import Origin from "../views/origin.vue"
import Artist from "../views/artist.vue"
import Polling from "../views/proposal.vue"
import Vote from "../views/vote.vue"
import Presales from "../views/presales.vue"
import Bot from "../views/bot.vue"
import Guide from "../views/guidelines.vue"
import Review1 from "../views/review1.vue"
import Review2 from "../views/review2.vue"
import ReviewEdge from "../views/reviewEdge.vue"
import Painting from "../views/painting.vue"
import Admin from "../views/admin.vue"
import Minting from "../views/minting.vue"
import Contact from "../views/contact.vue"
import Tnc from "../views/tnc.vue"


Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/nft",
        name: "NFTCollection",
        component: NFTCollection,
    },
    {
        path: "/mintnft",
        name: "NFTMinting",
        component: Minting,
    },
    {
        path: "/presales",
        name: "Presales",
        component: Presales,
    },
    {
        path: "/about",
        name: "About",
        component: About,
    },
    {
        path: "/dap",
        name: "DAP",
        component: DAP,
    },
    {
        path: "/origin",
        name: "Origin",
        component: Origin,
    },
    {
        path: "/artist",
        name: "Artist",
        component: Artist,
    },
    {
        path: "/painting",
        name: "Painting",
        component: Painting,
    },
    {
        path: "/proposal",
        name: "Proposal",
        component: Proposal,
    },
    {
        path: "/polling",
        name: "Polling",
        component: Polling,
    },
    {
        path: "/vote/:proposalId",
        name: "Vote",
        component: Vote,
    },
    {
        path: "/bot",
        name: "Bot",
        component: Bot,
    },
    {
        path: "/guide",
        name: "Guide",
        component: Guide,
    },
    {
        path: "/review/Johnni_Wong",
        name: "Johnni_Wong",
        component: Review1,
    },
    {
        path: "/review/Shalini_Ganendra",
        name: "Shalini_Ganendra",
        component: Review2,
    },
    {
        path: "/review/Edge",
        name: "The_Edge",
        component: ReviewEdge,
    },
    {
        path: "/admin",
        name: "Admin",
        component: Admin,
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
    },
    {
        path: "/tnc",
        name: "Tnc",
        component: Tnc,
    },
    { path: "*", redirect: "/" },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });
  
  export default router;