<template >
    <div id="review">
        <div class="title">
            <div class="container">
                <div class="row title-div">
                    <div class="col-9 d-flex justify-content-center flex-wrap">
                        <div class="col-12 text-center">
                            <h1>Review</h1>
                        </div>
                        <div class="mt-3 d-flex">
                            <h5>Johnni Wong</h5>                            
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="title-bg"></div>
        <div class="row d-flex justify-content-center">
            <div class="img-main d-flex justify-content-center">
                <img src="../img/home.jpg" alt="">
                <div class="paint d-flex justify-content-center">
                    <img src="../img/oilpaint2.png" alt="">
                </div>
            </div>
            <div class="content">
                <div class="content-head">
                    <h5> <b>Langkah Sheraton</b> </h5>
                    <span>A Review by Johnni Wong</span>
                </div>

                <p>Artworks with a political or satirical theme aren’t that uncommon in Malaysia but a work painted by a serving member of Parliament is indeed rare if not unique.</p>   
                <p>The oil on canvas painting in question, Langkah Sheraton by Damansara MP Tony Pua, is obviously a provocative comment on the infamous Sheraton Move that culminated on 24 February 2020 that brought down the ill-fated Pakatan Harapan (PH) government, which barely crossed the one-third mark of its five-year term.</p> 
                <p>For non-Malaysians or locals who are oblivious or indifferent to the countless shenanigans of leading local politicians, sometimes collectively referred as politikus (poli-rats) or other derisive terms, suffice for them to know that this artwork pokes fun at the major players.</p> 
                <p>But for the numerous political observers and commentators – within and outside the country – the Sheraton Move can be viewed from two perspectives. On the one hand, the political coup was engineered to “save” the country from the clutches of the “Nons” and on the other hand it was a shameful assault on democratic principles.</p>  
                <p>But who says the sizeable number of politikus who crossed over the other side to form the Perikatan Nasional (PN) government had any principle? Whether they leaped willingly with the promised of “rewards” or coerced into joining the other side is immaterial. Basically, the country’s political system allowed it and it has come to pass. And Malaysia isn’t even unique in this situation. Whether morally wrong or not, it was all perfectly legal or so it seems.</p>  
                <p>Coming back to the painting and the painter, the composition is original unlike another political painting depicting members of Parliament who resemble simians and cold-blooded amphibians. Observers were quick to point out that this recent work bears a remarkable resemblance to internationally acclaimed artist Banksy’s Devolved Parliament 2009 painting, touted as the Briton’s “dystopian view of the UK House of Commons”. The local work certainly got its 15-minute worth of fame.</p> 
                <p>Thus, composing a political or satirical work is no easy task. Whatever an artist can think of these days as a subject matter would probably have been covered and depicted. Perhaps, not necessarily in Malaysia but somewhere in the world, some other artist would have a similar idea. Of course, there is nothing wrong with having a coincidental concept. But it is quite another when the execution of the work closely resembles somebody else’s well-documented art.</p>  
                <p>The challenge is to compose something that says what you want to say and say it well enough for others to appreciate your objective.</p> 
                <p>Hanging at the obscure location of the Theatre Impian art gallery at Plaza Bukit Jalil just outside Kuala Lumpur, Langkah Sheraton certainly grabs your attention amidst all the other artworks commissioned for a “visual art biography” exhibition to commemorate veteran DAP leader Lim Kit Siang’s 80th birthday in 2021. The catalogue documents all the 100 works by 80 selected artists and details how the entire project came about and how and why Pua - who spearheaded the exhibition - took up the brush.</p> 
                <p>Spanning 121cm by 152cm, Langkah Sheraton features all the main politicians at play during the time of the Sheraton Move. Obviously, the outgoing Prime Minister Tun Dr Mahathir is in there but he doesn’t hold centre stage and neither does then incoming Prime Minister Tan Sri Muhyiddin Yasin who ominously is unsheathing a keris behind Dr Mahathir’s back. And below him is positioned a broken cabinet, among other token reminders. The other characters in the painting are Lim Guan Eng, Mohamad Sabu, Datuk Seri Anwar Ibrahim, Datuk Seri Mohamed Azmin Ali, Datuk Zuraida Kamaruddin, Datuk Seri Mohd Shafie Apdal, Datuk Seri Hishammuddin Tun Hussein, Tan Sri Abdul Hadi Awang, Datuk Seri Najib Tun Abdu Razak, Datuk Seri Ahmad Zahid Hamidi and Datuk Seri Wee Ka Siong as well as then, Parliamentary Speaker Tan Sri Mohamad Ariff bin Mohd Yusof.</p> 
                <p>And in the centre of the composition is Lim Kit Siang holding up the 2018 Buku Harapan election manifesto in a futile attempt to remind the players of the need to uphold principles of nation building policies. The artist cast Kit Siang as the protagonist or the earnest saviour who was ultimately defeated by treacherous characters. But Kit Siang as the conscientious character is probably due, in context of the event this painting was to be featured. But recent history has revealed who the real players were. But we still get the message.</p>  
                <p>Largely a self-taught and part-time artist, Pua obviously had focused on capturing the likeness of the people featured and the expressions on their faces in this work of realism. And this respect he had succeeded. But the artist seems to have a bit of unresolved issue in proportioning, for example, the hand in Muhyiddin’s figure or the body in Shafie’s composition. </p> 
                <p>The work was painted over a period of 10 months, largely during the Movement Control Order “lockdowns” that started on March 18 of 2020. And by that time, the PH government had lost power and Pua’s appointment as the political secretary to the Finance Minister had also ended.</p> 
                <p>There are quite a few witty references alluding to that period in Malaysian history with the Covid-!9 Corona virus image ominously dominating the background, a partially hidden Keluar sign, chess playing, a treasure chest, an air ticket to Maroc (Morrocco) and a hotel room key with the Sheraton logo but not necessarily referring to the Sheraton in Petaling Jaya. </p> 
                <p>Anyway, other Malaysian artists who have done original and spicy political works include Jalaini Abu Hassan, Chang Fee Ming, Kow Leong Kiang and Samsuddin Wahab, just to name a few whose memorable works come to mind. But their works have long been sold to private collectors and we probably won’t have the chance to see them again.</p> 
                <p>But for members of the public who are keen on owning an piece of political art, Langkah Sheraton will be sold as an Non-Fungible Token (NFT) artwork to raise funds for the DAP’s the forthcoming GE15 general election campaign.</p>  

                <p class="mt-5"><i style="color: #7FA5F9">The writer, Johnni Wong, is a former journalist who also set up and managed an art gallery and auction house for a media group between 2013-2018. He co-wrote the book, Southeast Asian Art, Auction Benchmarks & Market Insights published in 2018.</i>  </p> 

            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style>

#review .content-head {
    border-bottom: 1px solid;
    padding-bottom: 10px;
    margin:20px 0;

}

#review .content-head > span {
    font-size: 20px;

}
</style>