<template >
    <div id="proposal">
        <div class="title">
            <div class="container">
                <div class="row title-div">
                    <div class="col-10">
                        <div class="text-center d-flex flex-wrap justify-content-center">
                            <div>
                                <h2 class="mb-3">BOARD OF TRUSTEE (BOT)</h2>
                            </div>                            
                            <div style="width:750px;">
                                <p>Join our decentralized community protecting the integrity of the Protocol through research, discussion and on-chain voting.</p>
                                                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="title-bg"></div>
            
        <div class="row d-flex justify-content-center">
            <div class="d-flex flex-wrap justify-content-center">
                <div class="daoNav d-flex justify-content-center mb-4" >
                    <div class="daoTabWrap">
                        <div class="m-2 pt-2 daoTab">
                            <router-link  to="/polling" class="preeBtn "><b>Polling Votes</b></router-link>
                        </div>
                        <div class="m-2 pt-2 daoTab">
                            <router-link  to="/proposal" class="preeBtn "><b>New Proposal</b></router-link>
                        </div>
                    </div>
                    <div class="daoTabWrap">
                        <div class="m-2 pt-2 daoTab">
                            <router-link  to="/bot" class="preeBtn activeBtn"><b>Board of Trustee</b></router-link>
                        </div>
                    
                        <div class="m-2 pt-2 daoTab">
                            <button type="button" class="preBtn" style="padding-left:0px;" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">
                                <span class="me-3">&#9654;</span><b>How to vote</b>
                            </button>
                        </div>
                    </div>
                </div>                
            </div>
            <div class="proposal mb-5"> 
                <div class="p-top">
                    <h5><b>Board of Trustee Introduction</b></h5>
                </div>
                <div class="p-content">                    
                    <span>The Board of Trustee will be appointed based on the holding power of Langkah Sheraton NFT Painting.</span>
                    <span>All Board of Trustee have equivalent votes towards the proposal submission whether to delegate to all NFT owner for voting.</span>     
                    <span>Therefore every NFT owner are eligible to vote for any action to be taken for the painting.</span>          
                    <br/><br/>
                    <b >Rules for BOT member nomination</b>
                    <p class="p-content-list">
                        <ul>
                            <li>Only NFT owner with 100 NFTs and above can propose the BOT Member. Else, the nomination submission section will be hidden.</li>
                            <li>Each NFT address are limited to only vote 2 times for BOT nomination</li>
                        </ul>
                    </p>
                
                </div>
                <div class="board-head">
                    <div><h5><b>Welcome our Langkah Sheraton Board of Trustee</b></h5></div>
                    <div><i><span>Up to date: {{updatedDate}}</span></i></div>                   
                    
                </div>
                <div class="board">
                    <div class="board-content board1">
                        <div class="board-a">B</div>
                        <div v-if="checkBotData(currentBotData[0], 'nft') == 0 || checkBotData(currentBotData[0], 'nft') == '-' ">
                            <div class="board-b">
                                <div><span>WALLET ADDRESS</span></div>
                                <div class="mb-2"><span>-</span></div>
                                <div><span>Holding Power</span></div>
                                <div><span>-</span></div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="board-b">
                                <div><span>WALLET ADDRESS</span></div>
                                <div class="mb-2"><span>{{checkBotData(currentBotData[0], 'address')}}</span></div>
                                <div><span>Holding Power</span></div>
                                <div><span>{{(checkBotData(currentBotData[0], 'nft'))/totalMinted*100}}% ({{checkBotData(currentBotData[0], 'nft')}} NFTs)</span></div>
                            </div>
                        </div>                        
                    </div>
                    <div class="board-content board2">
                        <div class="board-a">O</div>
                        <div v-if="(checkBotData(currentBotData[1], 'nft')) == 0 || checkBotData(currentBotData[0], 'nft') == '-'">
                            <div class="board-b">
                                <div><span>WALLET ADDRESS</span></div>
                                <div class="mb-2"><span>-</span></div>
                                <div><span>Holding Power</span></div>
                                <div><span>-</span></div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="board-b">
                                <div><span>WALLET ADDRESS</span></div>
                                <div class="mb-2"><span>{{checkBotData(currentBotData[1], 'address')}}</span></div>
                                <div><span>Holding Power</span></div>
                                <div><span>{{(checkBotData(currentBotData[1], 'nft'))/totalMinted*100}}% ({{checkBotData(currentBotData[1], 'nft')}} NFTs)</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="board-content board3">
                        <div class="board-a">A</div>
                        <div v-if="(checkBotData(currentBotData[2], 'nft')) == 0 || checkBotData(currentBotData[0], 'nft') == '-'">
                            <div class="board-b">
                                <div><span>WALLET ADDRESS</span></div>
                                <div class="mb-2"><span>-</span></div>
                                <div><span>Holding Power</span></div>
                                <div><span>-</span></div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="board-b">
                                <div><span>WALLET ADDRESS</span></div>
                                <div class="mb-2"><span>{{checkBotData(currentBotData[2], 'address')}}</span></div>
                                <div><span>Holding Power</span></div>
                                <div><span>{{(checkBotData(currentBotData[2], 'nft'))/totalMinted*100}}% ({{checkBotData(currentBotData[2], 'nft')}} NFTs)</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="board-content board4">
                        <div class="board-a">R</div>
                        <div class="board-b">
                            <div><span>WALLET ADDRESS</span></div>
                            <div class="mb-2"><span>{{checkBotData(currentBotData[3], 'address')}}</span></div>
                            <div><span>Holding Power</span></div>
                            <div v-if="isNaN((checkBotData(currentBotData[3], 'nft'))/totalMinted*100) == true">
                                <span>-</span>
                            </div>
                            <div v-else>  
                                <span>{{(checkBotData(currentBotData[3], 'nft'))/totalMinted*100}}% ({{checkBotData(currentBotData[3], 'nft')}} NFTs)</span>                             
                            </div>
                        </div>
                    </div>
                    <div class="board-content board5">
                        <div class="board-a">D</div>
                        <div class="board-b">
                            <div><span>WALLET ADDRESS</span></div>
                            <div class="mb-2"><span>{{checkBotData(currentBotData[4], 'address')}}</span></div>
                            <div><span>Holding Power</span></div>
                            <div v-if="isNaN((checkBotData(currentBotData[4], 'nft'))/totalMinted*100) == true">
                                <span>-</span>
                            </div>
                            <div v-else>  
                                <span>{{(checkBotData(currentBotData[4], 'nft'))/totalMinted*100}}% ({{checkBotData(currentBotData[4], 'nft')}} NFTs)</span>                             
                            </div>
                        </div>
                    </div>
                </div>                               
            </div>
            <div v-if="nomineeSession == true" class="proposal botSubmit">
                <form @submit.prevent="proposeBOT" class="submitBot">
                    <h5 class="text-center">Board of Trustee (BOT) Nomination</h5>
                    <span>I nominate candidate wallet address below to be part of the BOT Member.</span>
                    <div class="d-flex justify-content-between my-3">
                        <textarea v-model="nomineeAddress" id="bot_address" class="form-control" aria-label="With textarea" placeholder="Enter wallet address" style="resize: none; height:40px; width:100%;" maxlength="150" required></textarea>
                    </div>
                    <div>
                        <input type="submit" value="SUBMIT" class="preBtn activeBtn"/>
                    </div>                    
                </form>
            </div>


            <div v-if="nomineeSession == true" class="proposal" style="padding-left: 0;">
                <!-- bot nomination -->
                <div class="section-title-dao">
                    <h5><b>BOT Nomination</b></h5>
                    <div>
                        <span class="mx-2" style="font-size:20px;">&#9719;</span><span>{{timeRemaining(botTime)}}</span>  
                    </div>                              
                </div>
                <div class="text-center" v-if="nomineeList.length == 0">
                    <span>BOT Nomination list is empty</span>
                </div>
                <div class="support" v-for="nominee in nomineeList" :key="'bot'+nominee.address">
                    <div style="width:70%;">
                        <div>
                            <span>POSTED {{((String(new Date(nominee.botpostAt))).substring(0,21)).toUpperCase()}} | POLL ID {{("00" + nominee.id).slice(-3)}}</span>
                        </div>
                        <div class="bot-wallet-address my-2">
                            <h6><b>{{nominee.address}}</b></h6>
                        </div>
                        
                        <div class="mt-2" v-if="userAddress == nominee.address">
                            <span style="font-size:14px;">You proposed this proposal</span> 
                        </div>
                        <div v-else class="" style="margin-top: 40px;">  

                            <div v-if="nominee.isVote == true" style="min-height: 34px;">
                                <span style="font-size:14px;">You have supported this nominee</span>
                            </div>
                            <div v-else>
                                <button class="motion-btn activeBtn" @click="supportBOT(nominee.address)"><b style="font-size:13px;">SUPPORT</b></button>                       
                            </div>
                        </div>
                    </div>
                    <div style=" text-align:right;">
                        <div>
                            <!-- <div class="d-flex justify-content-end" >
                                <div class="status"><span style="font-size:14px; color:#008000;">Active</span></div>
                            </div> -->
                            <div class="result" style="margin-top: 20px;"><h6><b>CURRENT SUPPORTED VOTES</b></h6></div>
                            <span class="y-vote" style="font-size:30px">{{((nominee.botsupport/totalMinted)*100).toFixed()}}%</span>
                        </div>
                    </div>                
                </div>
            </div>
            

            <!-- Modal -->
            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">                    
                        <div class="modal-body">
                            <video width="100%" controls loop>
                                <source src="../img/LS_Guide.mp4" type="video/mp4"> 
                            </video>
                        </div>                    
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</template>

<script>
import {ethers} from "ethers";
import axios from "axios";
import Web3 from "web3";
import ABI from "/src/ABI/mint.json";
import WalletConnectProvider from "@walletconnect/web3-provider";

const providerWC = new WalletConnectProvider({
    rpc: {
    //   56:"https://bsc-dataseed.binance.org/"
        137:"https://polygon-rpc.com/"
    },
    qrcodeModalOptions: {
      mobileLinks: [
        "rainbow",
        "metamask",
        "argent",
        "trust",
        "imtoken",
        "pillar",
      ]
    }
});

export default {

    data(){
        return{
            mintAddress: process.env.VUE_APP_MINT_ADDRESS,
            mintAbi: ABI,
            nomineeAddress:"",
            botData: "",
            current_bot: [],
            current_nominee: [],
            currentBotData:[],

            nomineeData:"",
            nomineeDataLength: "",
            nomineeList: [],

            nomineeSession: "",
            voteAvailable: 2,

            botTime: "",
            totalMinted: "",

            providerType:"",
            updatedDate:"",
        }        
    },
    async mounted() {
        if(sessionStorage.connectType == "WC"){
            console.log('testing');
            console.log(providerWC);
            await providerWC.enable();
            this.providerType = providerWC;
        }else{
            this.providerType = window.ethereum;
        }

        // this.metaMask();
        this.getBOT();
        this.getNominee();
        this.getBotProposal();
        this.getMintedAmount();

    },
    methods:{
        checkBotData(data, type){
            if(data){
                if(type == 'address'){
                    return(data.address)
                }
                if(type == 'nft'){
                    
                    return(data.totalNft)
                }                
            }else{
                return('-')
            }
        },
        async getBotProposal(){
            let provider = new ethers.providers.Web3Provider(this.providerType) 
            const signer = provider.getSigner()
            axios.get(                      
            'https://api.langkahsheraton.com/api/botvote/getBOTVoteDetails',{
                params: {
                    address: await signer.getAddress(),
                }
            }
            )
            .then((response) => {
                // console.log(this.userAddress)
                // console.log(response)
                // console.log(response.data.remainVote)
                this.nomineeData = response.data.data
                this.nomineeDataLength = this.nomineeData.length; 
                this.nomineeSession = response.data.enableVote;      
                this.voteAvailable = response.data.remainVote;
                for (let i = 0; i < this.nomineeData.length; i++){ 
                    this.nomineeList.push(this.nomineeData[i])                    
                }
                // console.log(this.nomineeList);
                

            })
            .catch(error =>{
                console.log(error)
            });
        },
        async metaMask(){
            if(sessionStorage.connect != "true"){
                this.$swal.fire({
                    icon: 'error',
                    title: 'MetaMask not connected',
                    text: 'Please connect to your Metamask wallet.',
                    confirmButtonText: 'Return',
                    allowOutsideClick: false,                        
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/home");
                    }
                });
            }            
        },
        getMintedAmount(){
            let provider = new ethers.providers.Web3Provider(this.providerType)
            provider.send("eth_requestAccounts", []);
            this.providerType.request({method: 'eth_requestAccounts'})
            .catch((error) => {
                console.log(error); 
            })
            .then(async () => {
                const signer = provider.getSigner()
                try{
                    const contract = new ethers.Contract(this.mintAddress, this.mintAbi, signer);
                    let minted = await contract.totalSupply()
                    this.totalMinted = parseInt(minted._hex);
                    // console.log(this.totalMinted)
                }catch(e){
                    console.log(e.message)
                }
            })
        },
        getBOT(){
            axios.get(            
            'https://api.langkahsheraton.com/api/botvote/getAllBOT'
            )
            .then((response) => {
                this.botData = response.data.data
                for (let i = 0; i < this.botData.length; i++){ 
                    this.current_bot.push(this.botData[i].address)
                    this.currentBotData.push(this.botData[i])                 
                }
                this.updatedDate = (Date(response.data.updatedDate)).substring(4,15);
                // console.log(this.currentBotData)
            })
            .catch(error =>{
                console.log(error)
            });
        },
        async getNominee(){
            let provider = new ethers.providers.Web3Provider(this.providerType) 
            const signer = provider.getSigner()
            axios.get(            
            'https://api.langkahsheraton.com/api/botvote/getBOTVoteDetails',{
                params: {
                    address: await signer.getAddress(),
                }
            }
            )
            .then((response) => {
                // console.log(response)
                this.nomineeData = response.data.data
                this.botTime = response.data.deadline
                // console.log(this.nomineeData)
                
                for (let i = 0; i < this.nomineeData.length; i++){ 
                    // console.log(this.nomineeData[i].address)
                    this.current_nominee.push(this.nomineeData[i].address)                 
                }
                // console.log(this.current_nominee);

            })
            .catch(error =>{
                console.log(error)
            });
        },
        proposeBOT(){
            // console.log(this.nomineeAddress);
            if(Web3.utils.isAddress(this.nomineeAddress) == true){
                if(this.current_bot.includes(this.nomineeAddress)||this.current_nominee.includes(this.nomineeAddress)){
                    // console.log("no")
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Wallet address unavailable',
                        text:'Your nominated candidate as a Board of Trustee has either an existing Trustee or has already been nominated.',
                        confirmButtonText: 'OK',                
                    })
                }else{
                    // console.log("yes")
                    let provider = new ethers.providers.Web3Provider(this.providerType)                
                    provider.send("eth_requestAccounts", []);

                    this.providerType.request({method: 'eth_requestAccounts'})
                    .catch((error) => {
                        console.log(error); 
                    })
                    .then(async () => {
                        const signer = provider.getSigner()
                        let message = 'BOT Nomination';
                        let signature = await signer.signMessage(message);
                        // console.log(message)
                        // console.log(signature)
                        // console.log(await signer.getAddress())
                        // console.log(this.nomineeAddress)
                        await axios.post('https://api.langkahsheraton.com/api/botvote/proposeBOT', {                        
                            address: await signer.getAddress(),
                            signature: signature,
                            message: message,
                            nominatedAddress: this.nomineeAddress,
                        },{ headers: {'Authorization' : `Bearer ${sessionStorage.bt}`} }).then((response)=>{
                            if (response.statusText == "Created"){
                                this.$swal.fire({
                                    icon: 'success',
                                    title: 'Nomination Submitted',
                                    confirmButtonText: 'OK',                    
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        window.location.replace("/bot");
                                    }
                                    if (result.isDismissed) {
                                        window.location.replace("/bot");
                                    }
                                });
                            }
                        }).catch(error =>{
                            console.log(error.response.data.message)
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Nomination submission failed',
                                html: 'You must have a minimum of 100 NFTs to be eligible to nominate.',
                                confirmButtonText: 'OK',                    
                            })
                        })                        
                    
                    })     
                }
            }else{
                this.$swal.fire({
                    icon: 'error',
                    title: 'You have not nominated a Board of Trustee candidate with a valid Metamask wallet address.',
                    confirmButtonText: 'return',                    
                })
            }                             

        },
        async supportBOT(wallet_address){

            let provider = new ethers.providers.Web3Provider(this.providerType)
            provider.send("eth_requestAccounts", []);
            this.providerType.request({method: 'eth_requestAccounts'})
            .catch((error) => {
                console.log(error); 
            })
            .then(async () => {
                const signer = provider.getSigner()
                // const address = window.ethereum.selectedAddress;
                const address = await signer.getAddress()
                // console.log(address)
                try{
                    const contract = new ethers.Contract(this.mintAddress, this.mintAbi, signer);
                    let balance = await contract.balanceOf(address)
                    // console.log(parseInt(balance._hex))
                    if(parseInt(balance._hex) < 1){
                        this.balance = parseInt(balance._hex);
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Insufficient NFTs',
                            text: 'You must have atleast 1 NFT to be eligible to support a nominee.',
                            confirmButtonText: 'OK',                       
                        })
                    }else{
                        if(this.voteAvailable > 0){
                            try{
                                let provider = new ethers.providers.Web3Provider(this.providerType) 
                                const signer = provider.getSigner()
                                let message = 'Support Proposal';
                                let signature = await signer.signMessage(message);
                                // console.log(message)
                                // console.log(signature)
                                await axios.post('https://api.langkahsheraton.com/api/botvote/proposeBOTVote', {                    
                                    address: await signer.getAddress(),
                                    signature: signature,
                                    message: message,
                                    votedAddress: wallet_address,
                                },{ headers: {'Authorization' : `Bearer ${sessionStorage.bt}`} }).then((response) => {
                                    // console.log(response)  
                                    if (response.statusText == "Created"){
                                        this.$swal.fire({
                                            icon: 'success',
                                            title: 'Nominee Supported',
                                            text: response.data.message,
                                            confirmButtonText: 'OK',
                                            // allowOutsideClick: false,                        
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                window.location.replace("/bot");
                                            }
                                            if (result.isDismissed) {
                                                window.location.replace("/bot");
                                            }
                                        });
                                    }               
                                })
                                .catch(error =>{
                                    if(error.response){                            
                                        console.log(error.response.data.message)
                                        if (error.response.data.message == "Top 3 of the BOT are declined to be vote the BOT Voting"){
                                            this.$swal.fire({
                                                icon: 'error',
                                                title: 'Voting Failed',
                                                text: 'You are currently in the BOT list, and are not allow to vote other nominees.',
                                                confirmButtonText: 'OK',
                                                // allowOutsideClick: false,                        
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    window.location.replace("/bot");
                                                }
                                                if (result.isDismissed) {
                                                    window.location.replace("/bot");
                                                }
                                            });
                                        }                                                            
                                    }
                                });

                                // window.location.reload();
                            }catch(e){
                                console.log(e)
                            }
                        }else{
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Voting Limit Reached',
                                text: 'Each user are allowed to only vote 2 nominees',
                                confirmButtonText: 'OK',
                                // allowOutsideClick: false,                        
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.replace("/bot");
                                }
                                if (result.isDismissed) {
                                    window.location.replace("/bot");
                                }
                            });
                        }
                    }
                }catch(e){
                    console.log(e.message)
                }
            })
            
            
        },
        timeRemaining(time){
            var date1 = new Date();
            var date2 = new Date(time);
            // console.log(date1 , date2);

            var diff = new Date(date2.getTime() - date1.getTime());
            // console.log(diff)   

            var days = diff.getUTCDate()-1;
            var hours = diff.getUTCHours();
            var mins = diff.getUTCMinutes();

            if(hours == 0){
                return(mins+"min REMAINING")
            }else{
                return(days+"D "+hours+"H REMAINING")
            }
        },
    }
    
}
</script>

<style>
.proposal{
    
    position: relative;
    width:1000px;
   
    border-style: none;
    border-color: #2c3e50;
    border-radius: 16px;
    border-width: 1px;
    padding: 15px;

}

#proposal .table{
    width: 970px;
    margin-top: 30px;
}

.p-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.p-content li{
    margin: 0;
	padding: 0 0 0 24px;
    margin: 5px 0;
	list-style: none;
	background-image: url("../img/tick.png");
	background-repeat: no-repeat;
	background-position: left 5px;
	background-size: 16px;
}

.p-content ul{
    padding-left: 0;
}

.y-vote{
    color:#00B050;

}

.n-vote{
    color: red;
}


td > a{
    text-decoration: none;
    color:rgb(0, 0, 0);
}

td > a:hover{
    color:rgb(0, 0, 0);
    text-decoration-line: underline;
}

.board-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 3px solid #2c3e50;
    padding: 20px 0;
    margin-top: 50px;
}
.board-content{
    border-radius: 65px;
    background-color: #cac5c5;
    background-position: left top;
    background-repeat: repeat;
    padding: 10px;
    width: 600px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    
}
.board-a{
  border-radius: 50%;
  background-color: #999a9c;
  background-position: left top;
  background-repeat: repeat;
  color:#2c3e50;
  font-size: 50px;
  padding: 10px; 
  font-weight: 550;
  width: 105px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.board-b {
    margin-left: 15px;
}

.board1{
    margin-left: 20px;
}
.board2{
    margin-left: 100px;
}
.board3{
    margin-left: 180px;
}
.board4{
    margin-left: 260px;
}
.board5{
    margin-left: 340px;
}

.submitBot{
    justify-content: center;
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    position: relative;
}

.submitBot h5{
    font-size:24px;
    margin-bottom: 20px;
}

.submitBot .preBtn{
    position: absolute;
    right: 0;
}

.botSubmit {
    min-height: 240px;
    border-bottom: 3px solid black;
    border-radius: 0 !important;
}

#proposal .section-title-dao{
   display: flex;
   justify-content: space-between;
}

@media screen and (max-width: 1024px){
    .board-head{
        flex-wrap: wrap;
    }

    .board1, .board2, .board3, .board4, .board5{
        margin-left: 0;
    }
    
    .board-content{
        width: 100%;
        font-size: 3vw;
        display: flex;
        justify-content: center;
    }

    .board-a {
        display: none;
    }

    .proposal {
        width: 100%;
    }

    .support {
        width: 100%;
    }

    .section-title-dao{
        width: 100%;
    }
    
}

@media screen and (min-width: 1025px){
    .p-content-list{
        margin-left: 50px;
    }
}

</style>