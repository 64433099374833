<template >
    <div id="painting">
        <div class="title">
            <div class="container">
                <div class="row title-div">
                    <div class="col-10 d-flex justify-content-center flex-wrap">
                        <div class="col-12 text-center">
                            <!-- <h1>Langkah Sheraton <b>Painting</b></h1> -->
                            <h1>Langkah Sheraton Painting</h1>
                        </div>
                        <div class="mt-4 d-flex">
                            <div class="mx-3"><router-link to="/painting" style="color:orange">THE PAINTING</router-link></div>
                            <div class="mx-3"><router-link to="/artist" >THE ARTIST</router-link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="title-bg"></div>
        <div class="row content-wrap">
            <div class="img-main d-flex justify-content-center">
                <video controls autoplay loop muted>
                    <source src="../img/LS_painting.mp4" type="video/mp4">
                </video>                
            </div>
            <div class="content">                

                <p>‘Langkah Sheraton’ records moments leading to the notorious ‘Sheraton Move’ as key political players maneuvered and out- maneuvered each other in the attempt to secure majority control of the Malaysian Parliament.</p>
                
                <p>The Pakatan Harapan coalition had triumphantly defeated the dominant Barisan Nasional ruling coalition which has ruled Malaysia since her independence in 1957, in the 2018 General Election.  Malaysians were euphoric, for it marked a peaceful, democratic, against all odds victory against a corrupt kleptocracy led by the infamous former Prime Minister, Najib Razak.</p>
                
                <p>Unfortunately, the hopes and dreams of ordinary Malaysians were crushed when Harapan was betrayed by certain leaders within the coalition. The treachery by leaders who colluded with the fallen UMNO-led Barisan coalition, brought the collapse of the Harapan administration in just 22-months.</p>
                
                <p>All the shenanigans were taking place as the COVID-19 epidemic was taking the world by storm in the first two months of 2020.</p>
            </div>
            <content-pic img1='painting1.jpg' img2='painting2.jpg' img3='painting3.jpg' img4='painting4.jpg' img5='painting5.jpg'></content-pic>
            <div class="content">

                <p> <b>Langkah Sheraton: The Story</b> </p>
                
                <p>On 23 February 2020, Bersatu President Muhyiddin Yassin and PKR Deputy President Azmin Ali launched the infamous ‘Sheraton move’ to install a new ruling Government. The pretext was to constitute a ‘Malay-dominant’ government without the DAP.</p>
                
                <p>Dr Mahathir Mohammad, the then Harapan Prime Minister, who was also the Chairman of Bersatu, was aware of the plans. However, he did not want to lead a Government reliant on the support of the corrupt in UMNO who were being charged in court. He resigned as the Prime Minister in protest.</p>
                
                <p>Pakatan Harapan Members of Parliament rallied Mahathir to continue to lead the coalition to fulfil the people’s mandate in 2018, albeit with a smaller majority.</p>
                
                <p>However, Mahathir had other ideas, and attempted to put together a new ‘unity government’ of all parties by constituting a new Cabinet that will be independent of the political leadership of all the parties. </p>
                
                <p>It was an untenable position for the DAP and all other parties. Ultimately, the parties must be answerable to the electorate who had supported them and the policies they espoused. Mahathir, who originally had the support of the breakaway factions, as well as from Pakatan Harapan to remain as Prime Minister, then lost the support from all sides.</p>
                
                <p>In the end, Muhyiddin was installed as the 8th Prime Minister of Malaysia on 29 February 2020. He was backed by UMNO and BN, PAS, Gabungan Parti Sarawak, Bersatu and the PKR dissidents led by Azmin Ali with a razor thin majority in Parliament of not more than five persons.</p>
                
                <p>In less than 22 months, PKR, DAP, Amanah, Warisan and the Bersatu splinter faction led by Mahathir became the opposition.</p>
                
                <p>Upon reflection, the collapse was down to the inability of the Pakatan Harapan component parties to commit to the spirit and principles of Buku Harapan, the coalition manifesto of the 2018 elections.</p>
                
                <p>At the core, Bersatu was an UMNO splinter party, whose leaders were used to dominating the Government. Despite owning the Prime Minister-ship, they could not dominate in the Harapan coalition when they won only 13 seats compared to 47 and 42 by PKR and DAP.</p>
                
                <p>To strengthen their political position, Bersatu attempted to win over core UMNO supporters with an overtly conservative and ‘Malay’ agenda, which was in conflict with many of the Buku Harapan multiracial principles. </p>
                
                <p>Bersatu’s failure was exposed in their Tanjong Piai by-election debacle in November 2019. They suffered a double whammy, failing to win over the UMNO voters, and lost the votes of Harapan supporters who won them the seat in 2018.</p>
                
                <p>In the end, Bersatu decided that the Harapan principles and manifesto were crosses they were not willing to bear. They would rather negotiate a deal with a corrupt UMNO whose leaders are facing numerous charges in the Courts.</p>
                
                <p>Bersatu’s betrayal was aided by a long- standing internal split in PKR, led by Azmin Ali against Anwar Ibrahim.</p>
                
                <p>When Anwar Ibrahim supporters began to apply undue intense pressure for Mahathir to hand over the reigns to Anwar, while Mahathir remained evasive and ambivalent on the transition, it created the most conducive environment for a ‘coup’ to take place.</p>
                
                <p>The biggest winners of Langkah Sheraton were obviously the leading duo of Najib Razak and Zahid Hamidi, the President of UMNO. Both were charged with the most damaging corruption cases in Court, with no reprieve in sight during the Harapan reign.  However, since the Sheraton Move, their political fortunes have reversed and are now rising, with the chances of ‘getting out of jail’, a much brighter prospect</p>
                
                  
            </div>
        </div>
    </div>
</template>

<script>
import contentPic from "../components/contentPic.vue"

export default {
    components:{contentPic},

    async mounted () {
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
.img-main video{
    width:1170px;
}

@media screen and (max-width:768px){

.img-main video{
    width: 100%;
    height: auto;
}
}

</style>