<template>
<div id="mint">
    <div class="title">
        <div class="container">
            <div class="row title-div">
                <div class="col-9">
                    <div class="text-center">
                        <h1>NFT Collection</h1>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <div class="title-bg"></div>
    <div id="main-wrapper">
        <div class="site-wrapper">
            <div class="container">
                <div class="row mt-5">
                    <div class="col-12">
                        
                        <div class="nft-filter gap-4 my-5" > 
                            <button type="button" @click="getRarity(4)" v-bind:class="{ filterActive: uRare}"><h4>ULTRA RARE</h4></button>
                            <button type="button" @click="getRarity(3)" v-bind:class="{ filterActive: rare}"><h4>RARE</h4></button>
                            <button type="button" @click="getRarity(2)" v-bind:class="{ filterActive: limit}"><h4>LIMITED</h4></button>
                            <button type="button" @click="getRarity(1)" v-bind:class="{ filterActive: ori}"><h4>ORIGINAL</h4></button>
                        </div>
                        <div class="row">
                            <div class="col-md-4 d-flex justify-content-center my-3" v-for="nft in nfts" :key="nft.cid"> 
                                <div class="nft-box d-flex align-items-center justify-content-center">
                                    <div class="nft-img">
                                        <img :src="require('../img/nft/'+(nft.title)+'.jpg')" alt="" loading="lazy">
                                    </div>
                                    <div class="nft-title">
                                        <!-- <h2>{{nft.title}}</h2> -->
                                    </div> 
                                </div>
                            </div>                            
                        </div>
                        <div class="nft-filter gap-3 my-5">
                            <div v-for="n in pages" :key="n">
                                <div v-if="n == pgCurrent">
                                    <button class="btn pgActive" @click="getNft(n)"><h6>{{n}}</h6></button>
                                </div>
                                <div v-else>
                                    <button class="btn" @click="getNft(n)"><h6>{{n}}</h6></button>
                                </div>
                            </div>                                                         
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>
</template>

<script>
import axios from "axios";
import ABI from "/src/ABI/mint.json";

export default {
    name: 'mint',

    
    data(){
        return{
            mintAddress: process.env.VUE_APP_MINT_ADDRESS,
            mintAbi: ABI,
            nfts2:[
                {title:'Title1',image:require('../img/nft/full_artwork.jpg')},
                {title:'Title2',image:require('../img/nft/full_black.jpg')},
                {title:'Title3',image:require('../img/nft/full_black2.jpg')},
                {title:'Title4',image:require('../img/nft/full_blue.jpg')},
                {title:'Title5',image:require('../img/nft/full_green.jpg')},
                {title:'Title6',image:require('../img/nft/full_lightblue.jpg')},
                {title:'Title7',image:require('../img/nft/full_orange.jpg')},
                {title:'Title8',image:require('../img/nft/full_red.jpg')},
                {title:'Title9',image:require('../img/nft/head_color.jpg')},
                {title:'Title9',image:require('../img/nft/item_color.jpg')},
            ],
            nfts: [],
            rarity: 'none',
            pages:1,
            uRare: false,
            rare: false,
            limit: false,
            ori: false,
            pgCurrent: 1,
        }
    },
    async mounted () {
        window.scrollTo(0, 0)
        this.getNft(1);
    },
    methods: {
        wallet(){
            this.$emit("connectWallet");
        },
        getRarity(rarity){
            this.rarity = rarity;
            this.getNft(1);

            if(rarity == 4){
                this.uRare = true;
            }else{
                this.uRare = false;
            }

            if(rarity == 3){
                this.rare = true;
            }else{
                this.rare = false;
            }

            if(rarity == 2){
                this.limit = true;
            }else{
                this.limit = false;
            }

            if(rarity == 1){
                this.ori = true;
            }else{
                this.ori = false;
            }


            
        },
        getNft(page){
            this.pgCurrent = page;
            if(this.rarity == "none"){
                axios.get(            
                'https://api.langkahsheraton.com/api/nft/getAllNft', {
                        params: {
                            page: page,
                            pageSize: 18
                        }
                    }
                )
                .then((response) => {
                    this.nfts = [];
                    // console.log(response)
                    let nftData = response.data.data
                    // console.log((response.data.total)/18)
                    this.pages = Math.ceil((response.data.total)/18)     
                    for (let i = 0; i < nftData.length; i++){
                        this.nfts.push(nftData[i])                    
                    }
                    // console.log(this.nfts)
                    window.scrollTo(0, 0)
                    
                })       
                .catch(error =>{
                    console.log(error)
                });
            }else{
                axios.get(            
                'https://api.langkahsheraton.com/api/nft/getAllNftSort', {
                        params: {
                            page: page,
                            pageSize: 18,
                            rarity: this.rarity,
                        }
                    }
                )
                .then((response) => {
                    this.nfts = [];
                    // console.log(response)
                    let nftData = response.data.data
                    // console.log((response.data.total)/18)
                    this.pages = Math.ceil((response.data.total)/18)
                    for (let i = 0; i < nftData.length; i++){
                        this.nfts.push(nftData[i])                    
                    }
                    // console.log(this.nfts)
                    window.scrollTo(0, 0)                    
                })       
                .catch(error =>{
                    console.log(error)
                });
            }            
        }
    }
    
}
</script>

<style>

    h1{
        font-size: 72px;
    }

    body{
        background-color: rgba(252,239,231,255);
    }

    .header-wrap{
        background-color: rgba(40,41,62,255);
    }

    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

    }

   

    .footer{
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        position: relative;
        

    }

  

    .wallet {
        padding: 0 35px;
    }

    .filterActive h4{
        text-decoration: underline;
    }

    .title-div{
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;

    }

    .nft-box{
        background-color: rgb(0, 0, 0);
        height: 267px;
        width: 335px;
        position: relative;
        border: 2px solid #eee;
        box-shadow: 0 0 40px rgba(51, 51, 51, 0.25) !important;
    }

    .nft-title{
        position: absolute;
        top: 65%;
        left: 10%;
    }

    .mint-btn{
        margin-bottom: 15px;
        border-radius: 15px;
        width: 100px;
    }

    .nft-title > h2 {
        color: white;
    }

    .nft-filter{
        display: flex;
        justify-content: center;    
    }

    .nft-filter > button {
        background: transparent;
        border: none;
    }

    .nft-img{
        opacity: 0.8;
    }

    .nft-img > img{
        width: 335px;
    }

    .nft-box > img{
        width: 335px;
    }

    .nft-filter .btn {
        background-color: white; 
        width: 40px; 
        height: 38px;
        padding-top: 10px;
    }

    .nft-filter .pgActive {
        background-color: rgb(150,76,63);
    }

    .nft-filter .pgActive h6{
        color: white;
    }

    @media screen and (max-width: 768px){
        .nft-filter h4{
            font-size:14px;
        }
    }
    
</style>
