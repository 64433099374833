<template>
  <div id="contact">
        <div class="title row">
            <div class="container">
                <div class="row title-div">
                    <div class="col-10">
                        <div class="text-center d-flex flex-wrap justify-content-center">
                            <div>
                                <h1 class="mb-3">Contact Us</h1>
                            </div>                            
                            <div >
                                <p></p>                       
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="title-bg"></div>
        <div class="d-flex justify-content-center flex-wrap">
            <div class="slogan">
                <h2>Own a piece of history and <br/>give Malaysia a brighter future!</h2>
            </div>
            <div class="content-wrap">
                <div class="content-left">
                    <div class="contact-content">    
                        <a href="https://discord.gg/Kq3CXUY23j" target="_blank" class="me-2" ><img class="mobi-nav-logo" style="max-width: 38px;" src="../img/icon-discord.png" alt=""></a>       
                        <a href="https://discord.gg/Kq3CXUY23j" target="_blank"><span>langkahsheraton</span></a>                        
                    </div>
                    <div class="contact-content">
                        <a href="/contact" target="_blank" class="me-2" ><img class="mobi-nav-logo" style="max-width: 38px;" src="../img/icon-email.png" alt=""></a>       
                        <a href="/contact" target="_blank"><span>langkahsheraton@dapmalaysia.org</span></a>
                    </div>
                    <div class="contact-content">
                        <a href="https://www.langkahsheraton.com/" target="_blank" class="me-2" ><img class="mobi-nav-logo" style="max-width: 38px;" src="../img/icon-website.png" alt=""></a>       
                        <a href="https://www.langkahsheraton.com/" target="_blank"><span>www.langkahsheraton.com</span></a>                             
                    </div>
                    <div>

                    </div>
                </div>
                <div class="content-right">
                    <span>Have some questions?</span>
                    <form style="margin-top:15px;" @submit.prevent="sendMsg">
                        <textarea v-model="nameF" id="nameF" placeholder="First Name" style="resize: none; height:40px; width:100%;" maxlength="50" required></textarea>
                        <textarea v-model="nameL" id="nameL" placeholder="Last Name" style="resize: none; height:40px; width:100%;" maxlength="50" required></textarea>
                        <!-- <textarea v-model="email" id="email" type="email" placeholder="Email" style="resize: none; height:40px; width:100%;" maxlength="150" required></textarea> -->
                        <input type="email" class="inputEmail" v-model="email" id="email" placeholder="Email" style="resize: none; height:40px; width:100%; border-width: 1px;" maxlength="150" required>
                        <textarea v-model="question" id="question" placeholder="Your questions..." style="resize: none; height:150px; width:100%;" maxlength="2500" required></textarea>
                        <div class="text-center mt-3">
                            <input type="submit" value="SEND MESSAGE" class="sendBtn activeBtn"/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'contact',
    data(){
        return{
            nameF:"",
            nameL:"",
            email:"",
            question:"",
        }
    },

    mounted: function() {
    },

    methods:{
        async sendMsg(){
            // console.log(this.nameF, this.nameL, this.email, this.question)
            let a = await axios.post('https://api.langkahsheraton.com/api/sheraton/sendContactEmail', {
                firstName: this.nameF,
                lastName: this.nameL,
                email: this.email,
                question: this.question,
            }).then((response)=>{
                // console.log(response)
                if (response.data.data == "Question Send"){
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Your message has been sent successfully.',                                       
                        confirmButtonText: 'OK',                       
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.replace("/contact");
                        }
                        if (result.isDismissed) {
                            window.location.replace("/contact");
                        }
                    });
                }
            })
            console.log(a)
        },
    }
}
</script>

<style scoped>
.slogan{
    width:100%;
    margin-top: 6%;
    margin-bottom: 4%;
    text-align: center;
    font-family: "darkRoast", Helvetica, Arial;

}
.slogan h2{
    font-size: 50px;
}

.content-wrap{
    width: 60%;
    align-items: center;
    display:flex;
    flex-wrap: wrap;

}

.content-left{
    width: 50%;
    
}

.content-right{
    width: 50%;
}

.content-right span{
    font-size: 22px;
    font-weight: bold;
}

.content-right textarea,
.inputEmail{    
    border-radius: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 15px;
    padding-top: 8px;
}

.contact-content{
    display: flex;
    align-items: center;
    margin: 15px 0;
}

.contact-content span{
    color:#2c3e50;
    font-weight: 600;
}

.sendBtn{
    background-color: transparent;
    border: solid 2px #2c3e50;
    color:#2c3e50;
    border-radius: 20px;
    padding: 7px 15%; 
    font-weight: bold;   
}

@media screen and (max-width: 1024px){
    .content-wrap{
        width: 80%;
    }

    .content-left,
    .content-right{
        width: 100%;
        margin-bottom: 10%;
    }

    .slogan h2{
        font-size: 38px;
    }

    .slogan {
        width: 90%;
    }
}

</style>