<template >
    <div id="about">
        <div class="title">
            <div class="container">
                <div class="row title-div">
                    <div class="col-9 d-flex justify-content-center flex-wrap">
                        <div class="col-12 text-center">
                            <h1>About</h1>
                        </div>
                        <div class="mt-4 d-flex">
                            <div class="mx-3"><router-link to="/about" style="color:orange">ABOUT</router-link></div>
                            <div class="mx-3"><router-link to="/origin">ORIGIN</router-link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="title-bg"></div>
        <div class="row content-wrap">
            <div class="img-main d-flex justify-content-center">
                <img src="../img/about.jpg" alt="">
                <div class="paint d-flex justify-content-center">
                    <img src="../img/OILPAINT3.png" alt="">
                </div>
            </div>
            <div class="content">
                <p>
                    The Langkah Sheration NFT project is a novel and innovative venture to enable thousands of fans to own a piece of critically-acclaimed artwork which recorded a pivotal moment in Malaysian political history.
                </p>
                <p>
                    ‘Langkah Sheraton’ is a large 120cm x 160cm oil painting by Tony Pua which records the moments leading up to the historic betrayal by members of the Harapan coalition in February 2020, resulting in the latter’s collapse after barely 22 months in Government.
                </p>
                <p>
                    It was an extremely painful and sobering moment for millions of Malaysians. They had only in 2018, celebrated the historic peaceful and democratic overthrow of the corrupt and authoritarian Barisan Nasional government who has ruled Malaysia since independence in 1957.
                </p>
            </div>
            <content-pic img1='about01.jpg' img2='about02.jpg' img3='about03.jpg' img4='about04.jpg' img5='about05.jpg'></content-pic>
            <div class="content">
                <p>
                    The Langkah Sheraton NFT is unlike most NFT art in the market today which is entirely digital, and somewhat throttled by excessive supply. Instead, the Langkah Sheraton NFT is backed by ownership of a physical, rare and critically-acclaimed oil painting of immense historical value which will last for generations to come.
                </p>
                <p>
                    For the supporters of the Malaysian Dream, to build a nation where every citizen sees themselves as Malaysian First, and not be prejudiced by race and religion - the entire proceeds from the Langkah Sheraton NFT sale will go towards the Democratic Action Party (DAP) of Malaysia, a part of the campaign funds for the 15th General Election.
                </p>
                <p>
                    For the supporters of democracy and good governance, there can be no more meaningful way to contribute towards the cause of fighting kleptocracy, abuse of power, corruption and cronyism, by owning a piece of history..
                </p>
            </div>            
        </div>
    </div>
</template>

<script>
import contentPic from '../components/contentPic.vue';


export default {
    components:{contentPic}, 

    async mounted () {
        window.scrollTo(0, 0)
    }
}
</script>

<style>
#about .img-main img{
    max-width: 1167px;
}


</style>