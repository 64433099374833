<template >
    <div id="review">
        <div class="title">
            <div class="container">
                <div class="row title-div">
                    <div class="col-9 d-flex justify-content-center flex-wrap">
                        <div class="col-12 text-center">
                            <h1>Review</h1>
                        </div>
                        <div class="mt-3 d-flex">
                            <h5>The Edge</h5>                            
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="title-bg"></div>
        <div class="row d-flex justify-content-center">
            <div class="img-main d-flex justify-content-center">
                <img src="../img/home.jpg" alt="">
                <div class="paint d-flex justify-content-center">
                    <img src="../img/oilpaint2.png" alt="">
                </div>
            </div>
            <div class="content">
                <!-- <div class="content-head">                 
                </div> -->

                <p>Two negative turns of events early last year led to something positive for Damansara member of parliament Tony Pua: He found time to paint something for the Theatre Impian tribute to Lim Kit Siang. Pua had been asked by Projek Siang sponsor Datuk KY Chan, who knew he could draw, to contribute a piece. At that juncture, he was the political secretary to the then finance minister Lim Guan Eng and had weighty responsibilities.</p>

                <p>The tumultuous Sheraton Move of Feb 23 toppled the Pakatan Harapan government and Pua got the boot too. Then MCO 1.0 came into force on March 18 and, suddenly, there was time to paint.</p>
                
                <p>“The thing that moved deep in the head then was Langkah Sheraton. I thought a painting is a good way to put down my personal record of what took place. It became the most meaningful project to undertake for my time in isolation and solace.” </p>
                
                <p>There is no message to his oil on canvas artwork, he adds, just a historical record of what happened that night and the key political players who “manoeuvred and out-manoeuvred each other in the attempt to secure majority control of the Malaysian Parliament”.</p>
                
                <p>Pua places Langkah Sheraton in a pseudo- parliamentary setting, with Pakatan and Barisan Nasional representatives on opposite sides, and various figures hogging attention. He explains what he wants to depict.</p>
                
                <p>“You have [Tan Sri] Muhyiddin [Yassin] trying to persuade Tun [Dr] Mahathir [Mohamad] to go with Umno and PAS and become the new PM with a new coalition. Guan Eng, on the PH side, is trying very hard to persuade Tun to stick to the party’s ideals and promises to the people.</p>
                
                <p>“We know Tun didn’t listen to both sides; he had his own ideas and wanted to set up his own government. At the end of the day, Muhyiddin wanted betrayal, whether he was betraying the Pakatan coalition or Dr M himself.”</p>
                
                <p>PKR deputy president Datuk Seri Mohamed Azmin Ali, who led a splinter faction of PKR, was “literally on his knees because he knew the main discussion then was whether Tun would hand over to [Datuk Seri] Anwar [Ibrahim]. If he did that, as he had promised to do, Azmin might be politically finished”. So, he is strategising his game to ensure political survival.</p>
                
                <p>“Then, you have Kit Siang holding up the 2018 Buku Harapan election manifesto and reminding the coalition that, ‘We need to stick together. Whatever we do, the people voted for us and we must fulfil our promises, as much as we can’. But it was in vain.”</p>
                
                <p>Datuk Seri Najib Razak and Datuk Seri Ahmad Zahid Hamidi are smiling and hopeful, because a change of government would mean they would no longer be prosecuted for corruption, Pua points out.
                Others in the tableau include MCA president Datuk Seri Dr Wee Ka Siong, who appears
                curious about what is going on and wants in too, and PAS president Datuk Seri Abdul Hadi Awang, who sees no harm in a back-door government and does not mind not being a minister because he can become a fisherman, Pua continues.
                Datuk Seri Hishammuddin Hussein — “he’s a bit under the radar” — is squatting, dressed in cool holiday attire. A pensive Datuk Seri Mohd Shafie Apdal, the then Sabah chief minister and a former potential future PM, the artist explains, piques because no one knows what he is thinking.</p>
                
                <p>Amid all this, the Dewan Rakyat Speaker is trying to control the House but no one seems to bother about him. Who is in charge? And hanging above the 15 heads is the coronavirus, a real and present danger until today.
                Pua started working on Langkah Sheraton last March and finished it after 10 months. “The last time I did a proper painting was in Form Four. In school, I liked painting, backdrops and murals, and I know I can paint.”</p>
                
                <p>But work got in the way of art and he never pursued it except for the two weeks in 2016, when he did a painting for Segambut MP Hannah Yeoh that raised RM50,000 for charity. “I was surprised.”</p>
                
                <p>That piece, Shackled, Not Broken, was his way of showing he would not be bowed by the 1Malaysia Development Bhd defamation suit brought against him by Najib. Little people in the street trying to free a lone man from his shackles say something: It is the people who make a difference.
                The toughest thing for him when working on Langkah Sheraton was “learning and relearning because I hadn’t painted for very long. I’m rusty, so I was slow. I made some mistakes and learnt along the way”. Working from left to right, he found the latter stage of his work was so much better and had to redo the earlier bits.</p>
                
                <p>The good thing about oil is that after it dries, you can paint over it, Pua says. Initially unsure whether the Covid-19 idea would work, he decided to try first and change it if it did not. “It came out quite okay.” Nurturing a latent talent during the pandemic gets a nod too.</p>
                


            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style>

#review .content-head {
    border-bottom: 1px solid;
    padding-bottom: 10px;
    margin:20px 0;

}

#review .content-head > span {
    font-size: 20px;

}
</style>