<template >
    <div id="dap">
        <div class="title">
            <div class="container">
                <div class="row title-div">
                    <div class="col-9">
                        <div class="text-center">
                            <h1>DAP Malaysia</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="title-bg"></div>
        <div class="row content-wrap">
            <div class="img-main d-flex justify-content-center">
                <img src="../img/dap.jpg" alt="">
                <div class="paint d-flex justify-content-center">
                    <img src="../img/oilpaint1.png" alt="">
                </div>
            </div>
            <div class="content">
                <p>
                    The <a href="https://dapmalaysia.org/" target="_blank"><b>Democratic Action Party (DAP)</b></a> has been the leading political party fighting for a free, fair and democratic Malaysia since 1966.  The party is committed to the principles of freedom, human rights, equal opportunity, social and economic justice, founded on the institution of parliamentary democracy.
                </p>
                <p>
                    For many decades, DAP’s leaders have been incarcerated, detained without trial, jailed and persecuted by the corrupt and authoritarian Barisan Nasional (BN) regime.  Despite the threats and intimidation, the party stood bravely against the odds, to become the largest party with 42 Members of Parliament today, albeit in the opposition
                </p>
            </div>
            <content-pic img1='dap1.jpg' img2='dap2.jpg' img3='dap3.jpg' img4='dap4.jpg' img5='dap5.jpg'></content-pic>
            <div class="content">
                <p>
                    DAP’s struggles for a better Malaysia briefly tasted success in 2018 when the party, together with its coalition partners in Pakatan Harapan, successfully defeated BN for the first time since the country’s independence.  Unfortunately, the historic achievement was short-lived, as the coalition was betrayed by parties and members who could not adhere to our core principles.  The collapse of the Harapan coalition government was a direct consequence of the ‘Sheraton Move’ in 2020.
                </p>
                <p>
                    However, the DAP remains resolute and unflinching in the determination to build a better Malaysia for all Malaysians, regardless of race, religion or creed.  
                </p>
                <p>
                    The proceeds of the minting and sale of the Langkah Sheraton NFTs will raise crucial funds required for a successful election campaign in the next General Election, expected within a year.  They certainly deserve the support of all, as they fight for democracy, freedom, equal opportunity and justice.
                </p>              
            </div>
        </div>
    </div>
</template>

<script>

import contentPic from "../components/contentPic.vue"

export default {
    components:{contentPic},

    async mounted () {
        window.scrollTo(0, 0)
    }
}
</script>

<style>
#dap .img-main img{
    max-width: 1167px;
}

.content > p > a > b{
    color:#2c3e50 !important;
}
.content > p > b{
    color:#2c3e50 !important;
}

.content a:hover{
    text-decoration: underline !important;
    color:#2c3e50
}

</style>