<template >
    <div id="propose">
        <div class="title row">
            <div class="container">
                <div class="row title-div">
                    <div class="col-10">
                        <div class="text-center d-flex flex-wrap justify-content-center">
                            <div>
                                <h2 class="mb-3">NEW PROPOSAL</h2>
                            </div>                            
                            <div >
                                <p>Suggest a new proposal to create a community that benefit for Langkah Sheraton Painting.</p>                       
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="title-bg"></div>
        <div class="d-flex flex-wrap justify-content-center">                
            <div class="daoNav d-flex justify-content-center mb-4" >
                <div class="daoTabWrap">
                    <div class="m-2 pt-2 daoTab">
                        <router-link  to="/polling" class="preeBtn "><b>Polling Votes</b></router-link>
                    </div>
                    <div class="m-2 pt-2 daoTab">
                        <router-link  to="/proposal" class="preeBtn activeBtn"><b>New Proposal</b></router-link>
                    </div>
                </div>
                <div class="daoTabWrap">
                    <div class="m-2 pt-2 daoTab">
                        <router-link  to="/bot" class="preeBtn"><b>Board of Trustee</b></router-link>
                    </div>
                
                    <div class="m-2 pt-2 daoTab">
                        <button type="button" class="preBtn" style="padding-left:0px;" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">
                            <span class="me-3">&#9654;</span><b>How to vote</b>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div id="main-wrapper">                      
            <div class="site-wrapper">
                <div class="container">
                    <div class="propose-content"> 
                        <form @submit.prevent="propose">
                            <div class="my-4" >
                                <h5><b>PROPOSAL TITLE</b></h5>
                                <textarea v-model="title" id="title" class="form-control" aria-label="With textarea" placeholder="Enter your text here" style="resize: none; height:40px; width:100%;" maxlength="150" required></textarea>
                            </div>
                            <div class="my-4" >
                                <h5><b>PROPOSAL DESCRIPTION</b></h5>                                        
                                <textarea v-model="desc" id="desc" class="form-control" aria-label="With textarea" placeholder="Enter your text here" style="resize: none; height:150px; width:100%;" maxlength="2500" required></textarea>
                            </div>
                            <!-- <div class="py-5" style="width: 350px;">
                                <label for="duration" class="form-label">Approval Duration (day(s)):</label>
                                <input type="range" class="form-range" min="1" max="7" value="1" id="duration" @change="console.log('test')">
                            </div>  -->
                            <div class="btn-wrap" >
                                <input type="submit" value="SUBMIT PROPOSAL" class="preBtn activeBtn"/>     
                            </div>
                        </form>        
                    </div>
                </div>
            </div>   
        </div>  
        
        <!-- Modal -->
        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">                    
                    <div class="modal-body">
                        <video width="100%" controls loop>
                            <source src="../img/LS_Guide.mp4" type="video/mp4"> 
                        </video>
                    </div>                    
                </div>
            </div>
        </div>
        
        
    </div>

</template>

<script>
import {ethers} from "ethers";
import axios from "axios";
import ABI from "/src/ABI/mint.json";
import WalletConnectProvider from "@walletconnect/web3-provider";

const providerWC = new WalletConnectProvider({
    rpc: {
    //   56:"https://bsc-dataseed.binance.org/"
        137:"https://polygon-rpc.com/"
    },
    qrcodeModalOptions: {
      mobileLinks: [
        "rainbow",
        "metamask",
        "argent",
        "trust",
        "imtoken",
        "pillar",
      ]
    }
});

export default {
    name: 'propose',
    data(){
        return{
            mintAddress: process.env.VUE_APP_MINT_ADDRESS,
            mintAbi: ABI,
            title:"",
            desc:"",
            balance:"",
            modalShow: true,
            providerType:"",

        }
    },

    async mounted() {
        if(sessionStorage.connectType == "WC"){
            console.log('testing');
            console.log(providerWC);
            await providerWC.enable();
            this.providerType = providerWC;
        }else{
            this.providerType = window.ethereum;
        }

        this.metaMask();
        this.checkNFT();
        

    },

    methods: {
        async metaMask(){
            if(sessionStorage.connect != "true"){
                this.$swal.fire({
                    icon: 'error',
                    title: 'MetaMask not connected',
                    text: 'Please connect to your Metamask wallet.',
                    confirmButtonText: 'Return',
                    allowOutsideClick: false,                        
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/polling");
                    }
                });
            }            
        },
        checkNFT(){
            // let provider = new ethers.providers.Web3Provider(window.ethereum)                
            // provider.send("eth_requestAccounts", []);
            // const signer = provider.getSigner()
            
            // let test = signer.getAddress()   
            // const test = window.ethereum.selectedAddress;

            // console.log(test)            
            // if(test){
                let provider = new ethers.providers.Web3Provider(this.providerType)
                provider.send("eth_requestAccounts", []);
                this.providerType.request({method: 'eth_requestAccounts'})
                .catch((error) => {
                    console.log(error); 
                })
                .then(async () => {
                    const signer = provider.getSigner();
                    // const address = window.ethereum.selectedAddress;
                    const address = await signer.getAddress();
                    // console.log(address)
                    try{
                        const contract = new ethers.Contract(this.mintAddress, this.mintAbi, signer);
                        let balance = await contract.balanceOf(address)
                        // console.log(parseInt(balance._hex))
                        if(parseInt(balance._hex) < 100){
                            this.balance = parseInt(balance._hex);
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Insufficient NFTs',
                                text: "You do have sufficient NFTs. You need at least 100 Langkah Sheraton NFTs to submit a motion.",
                                confirmButtonText: 'Return',
                                allowOutsideClick: false,                      
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.replace("/polling");
                                }
                            });
                        }
                    }catch(e){
                        console.log(e.message)
                    }
                })
            // }else{
            //     this.$swal.fire({
            //         icon: 'error',
            //         title: 'MetaMask not connected',
            //         text: 'Please connect to your Metamask wallet.',
            //         confirmButtonText: 'Return',
            //         allowOutsideClick: false,                        
            //     }).then((result) => {
            //         if (result.isConfirmed) {
            //             window.location.replace("/polling");
            //         }
            //     });
            // }

            
        },
        wallet(){
            this.$emit("connectWallet");
        },

        propose(){
            // console.log(this.title);
            // console.log(this.desc);
            let provider = new ethers.providers.Web3Provider(this.providerType)                
            provider.send("eth_requestAccounts", []);

            this.providerType.request({method: 'eth_requestAccounts'})
            .catch((error) => {
                console.log(error); 
            })
            .then(async () => {
                // console.log('ok')
                const signer = provider.getSigner()
                let message = 'Proposal Verification | Title : "'+this.title+'"';

                // try{
                    let signature = await signer.signMessage(message);
                    // console.log(signature, message)
                    let a = await axios.post('https://api.langkahsheraton.com/api/proposal/createProposal', {
                        proposal_title: this.title,
                        proposal_desc: this.desc,
                        address: await signer.getAddress(),
                        signature: signature,
                        message: message
                    },{ headers: {'Authorization' : `Bearer ${sessionStorage.bt}`} })
                    // window.location.href = "/proposal";
                    // console.log(a)
                    // console.log(a.statusText)
                    if (a.statusText == "Created"){
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Your proposal has been submitted.',
                            // text: '',
                            confirmButtonText: 'OK',
                            // allowOutsideClick: false,                        
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.replace("/polling");
                            }
                            if (result.isDismissed) {
                                window.location.replace("/polling");
                            }
                        });
                    }

                // }catch(e){
                //     console.log(e.message)
                // }
            })
            

        }
    }
    
}
</script>


<style scoped>

    .container{
        display:flex;
        justify-content: center;
    }
    .propose-content{
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        width:800px;
    }

    .propose-content > form {
        width: 100%;
    }

    

    @media screen and (max-width: 1024px){
        .propose-content{
            width:100%;
        }    
        .btn-wrap{
            display: flex;
            justify-content: center;
        }    
    }

    @media screen and (min-width: 1025px){
        .preBtn{
            float:right;
        }
    }

    

    .swal2-shown {
        /* background-color: rgba(0,0,0,0.7); */
        transition: 0.1s filter;
        filter:blur(10px) !important;
    }

</style>