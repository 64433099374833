<template >
    <div id="vote">
        <div class="title">
            <div class="container">
                <div class="row title-div">
                    <div class="col-10">
                        <div class="text-center d-flex flex-wrap justify-content-center">
                            <div>
                                <h2 class="mb-3">DECENTRALISED AUTONOMOUS ORGANISATION (DAO)</h2>
                            </div>                            
                            <div style="width:750px;">
                                <p>Join our decentralized community protecting the integrity of the Protocol through research, discussion and on-chain voting.</p>
                                                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="title-bg"></div>
        <div class="flex-wrap d-flex justify-content-center">
            <div class="top-btn-wrap my-4 d-flex justify-content-between" style="width:1000px;">                
                <div> <a href="/polling" class="top-btn activeBtn"> <b class="webView">BACK TO ALL POLLS</b> <b class="mobileView" style="font-size: 10px;">BACK TO ALL POLLS</b> </a> </div>    
                <div class="d-flex " style="gap: 5px;">
                    <div v-if="daoData.previousid != null">
                        <a :href="'/vote/'+daoData.previousid" class="top-btn activeBtn"> <b class="webView">PREVIOUS POLLS</b> <b class="mobileView" style="font-size: 10px;">PREVIOUS POLLS</b></a>
                    </div>
                    <div v-if="daoData.nextId != null">
                        <div class="webView">
                            <a :href="'/vote/'+daoData.nextId" class="top-btn activeBtn" style="padding: 10px 30px;"> <b>NEXT POLLS</b></a>
                        </div>
                        <div class="mobileView">
                            <a :href="'/vote/'+daoData.nextId" class="top-btn activeBtn" style="padding: 10px 24px;"> <b style="font-size: 10px;">NEXT POLLS</b></a>
                        </div>
                    </div>
                </div>  
            </div>
            <div class="proposal"> 
                
                <div class="details">
                    <div style="" class="left-content">
                        <div>                            
                            <span>POSTED {{((String(new Date(daoData.data.createdAt))).substring(4,21).toUpperCase())}} | POLL ID {{("00" + daoData.data.id).slice(-3)}} </span>
                        </div>
                        <div class="my-2">
                            <h5><b>{{daoData.data.proposalTitle}}</b></h5>
                        </div>                        
                    </div>
                    <div class="right-content">
                        <div class="d-flex justify-content-center">
                            <div v-if="daoData.data.propose_status == 'Open'" class="status">
                                <span style="font-size:14px; color:#00B050;"> <b>Active</b> </span>
                            </div>
                            <div v-else class="status">
                                <span style="font-size:14px; color:#ff0000;"> <b>Closed</b> </span>
                            </div>
                        </div>
                        <div class="webView">
                            <div class="result">
                                <div><h6><b>LEADING OPTIONS</b></h6></div>
                                <div class="percentage">
                                    <div v-if="isNaN(daoData.data.for_total/(daoData.data.for_total+daoData.data.against_total)) == true">
                                        <span class="y-vote"> <b>0%</b> </span>
                                    </div>
                                    <div v-else>
                                        <span class="y-vote"> <b>{{((daoData.data.for_total/(daoData.data.for_total+daoData.data.against_total))*100).toFixed()}}%</b> </span>                                
                                    </div>
                                    | 
                                    <div v-if="isNaN(daoData.data.against_total/(daoData.data.for_total+daoData.data.against_total)) == true">
                                        <span class="n-vote"> <b>0%</b> </span>
                                    </div>
                                    <div v-else>
                                        <span class="n-vote"> <b>{{((daoData.data.against_total/(daoData.data.for_total+daoData.data.against_total))*100).toFixed()}}%</b> </span>                                
                                    </div>
                                </div>
                            </div>
                        </div>                                              
                    </div>
                </div>
                <div class="details details-mobi">
                    <div v-if="daoData.data.propose_status == 'Open'">
                        <div class="d-flex flex-wrap">
                            <div class="me-3">
                                <span style="font-size:20px;">&#9719;</span><span> {{timeRemaining(daoData.data.deadline)}}</span>                           
                            </div> 
                            <div class="mobileView">
                                <div class="d-flex" v-if="daoData.data.isVote == true"  style="margin-top: 5px;">
                                    <div v-if="daoData.data.option == 'Agree'">
                                        <span style="font-size:14px; text-align:right;">You have voted '<span class="y-vote">Agree</span>' for this proposal</span>                                                         
                                    </div>
                                    <div v-else>
                                        <span style="font-size:14px; text-align:right;">You have voted '<span class="n-vote">Disagree</span>' for this proposal</span>                                                           
                                    </div>                            
                                </div>
                                <div class="voteBtn-wrap" v-else  style="margin-top: 5px;">
                                    <button class="motion-btn activeBtn" v-on:click="vote('agree')"> <b style="font-size: 14px;">AGREED</b> </button>
                                    <button class="motion-btn" v-on:click="vote('disagree')"> <b style="font-size: 14px;">DISAGREED</b> </button>
                                </div>
                            </div>                                             
                        </div>
                    </div>                    
                    <div v-if="daoData.data.propose_status == 'Closed'">
                        <span>Voting Period Ended</span>
                    </div>
                    <div v-else class="webView">
                        <div class="d-flex" v-if="daoData.data.isVote == true">
                            <div v-if="daoData.data.option == 'Agree'">
                                <span style="font-size:14px; text-align:right;">You have voted '<span class="y-vote">Agree</span>' for this proposal</span>                                                         
                            </div>
                            <div v-else>
                                <span style="font-size:14px; text-align:right;">You have voted '<span class="n-vote">Disagree</span>' for this proposal</span>                                                           
                            </div>
                        </div>
                        <div class="voteBtn-wrap" v-else>
                            <button class="preBtn" style="padding: 7px 42px;" v-on:click="vote('agree')">AGREED</button>
                            <button class="preBtn activeBtn" v-on:click="vote('disagree')">DISAGREED</button>
                        </div>
                    </div>
                    <div class="mobileView">
                        <div class="result" style="width:30%;">
                            <div><h6><b>LEADING OPTIONS</b></h6></div>
                            <div class="percentage">
                                <div v-if="isNaN(daoData.data.for_total/(daoData.data.for_total+daoData.data.against_total)) == true">
                                    <span class="y-vote"> <b>0%</b> </span>
                                </div>
                                <div v-else>
                                    <span class="y-vote"> <b>{{((daoData.data.for_total/(daoData.data.for_total+daoData.data.against_total))*100).toFixed()}}%</b> </span>                                
                                </div>
                                | 
                                <div v-if="isNaN(daoData.data.against_total/(daoData.data.for_total+daoData.data.against_total)) == true">
                                    <span class="n-vote"> <b>0%</b> </span>
                                </div>
                                <div v-else>
                                    <span class="n-vote"> <b>{{((daoData.data.against_total/(daoData.data.for_total+daoData.data.against_total))*100).toFixed()}}%</b> </span>                                
                                </div>
                            </div>
                        </div> 
                    </div>
                    
                    
                </div>       
                <div class="description my-3 p-2">
                    <div>
                        <h5> <b>Description</b> </h5>
                    </div>  
                    <div>
                        <p>{{daoData.data.proposeDesc}}</p>
                    </div>
                </div>

                 <div>
                    <h5> <b>Voting by Address</b> </h5>
                    <table class="table">
                        <thead>
                            <tr>
                                <th style="width:60%; max-width:300px;">WALLET ADDRESS</th>
                                <th style="width:22.5%;">OPTION</th>
                                <th style="width:22.5%;">VOTING POWER</th>
                            </tr>                       
                        </thead>
                        <tbody>
                            <tr v-for="vote in voteData" :key="'votes'+vote.id">                                
                                <td style="word-break: break-word;">
                                    <span >{{vote.address}}</span>
                                    <!-- <span class="mobileView">{{vote.address.substr(0, 8) + "......" + vote.address.substr(-6, 6)}}</span> -->
                                </td>
                                <td v-if="vote.option == 'Agree'"><span class="y-vote" > <b>Agreed</b> </span></td>
                                <td v-else><span class="n-vote" > <b>Disagreed</b> </span></td>
                                <td v-if="isNaN(vote.nftVote/(daoData.data.for_total+daoData.data.against_total)) == true">
                                    <span>0%</span>
                                </td>
                                <td v-else>
                                    <span>{{((vote.nftVote/(daoData.data.for_total+daoData.data.against_total))*100).toFixed()}}% ({{vote.nftVote}})</span>
                                </td>                                
                            </tr>                      
                        </tbody>
                    </table>
                </div>
                               
            </div>
           
        </div>
    </div>
</template>

<script>

import axios from "axios";
import {ethers} from "ethers";
import ABIdao from "/src/ABI/dao.json";
import ABImint from "/src/ABI/mint.json";
import WalletConnectProvider from "@walletconnect/web3-provider";

const providerWC = new WalletConnectProvider({
    rpc: {
    //   56:"https://bsc-dataseed.binance.org/"
        137:"https://polygon-rpc.com/"
    },
    qrcodeModalOptions: {
      mobileLinks: [
        "rainbow",
        "metamask",
        "argent",
        "trust",
        "imtoken",
        "pillar",
      ]
    }
});

export default {
                    // $route.params.proposalId
    name: 'vote',
    data(){
        return{
           daoAddress: process.env.VUE_APP_DAO_ADDRESS,
           daoAbi: ABIdao,
           mintAddress: process.env.VUE_APP_MINT_ADDRESS,
           mintAbi: ABImint,
           pid: this.$route.params.proposalId,
           daoData: "",
           voteData: [],
           providerType:"",

        }
    },async mounted() {
        if(sessionStorage.connectType == "WC"){
            console.log('testing');
            console.log(providerWC);
            await providerWC.enable();
            this.providerType = providerWC;
        }else{
            this.providerType = window.ethereum;
        }

        this.metaMask();
        this.getAddressTest();
    },
    methods:{    
        async metaMask(){
            if(sessionStorage.connect != "true"){
                this.$swal.fire({
                    icon: 'error',
                    title: 'MetaMask not connected',
                    text: 'Please connect to your Metamask wallet.',
                    confirmButtonText: 'Return',
                    allowOutsideClick: false,                        
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/home");
                    }
                });
            }            
        }, 
        getDao(){
            axios.get(            
            'https://api.langkahsheraton.com/api/dao/getDAO', {
                params: {
                    address: this.userAddress,
                    proposalId: this.$route.params.proposalId
                }
            })
            .catch((error) => {
                console.log(error);
            })           
            .then((response) => {
                // console.log(this.userAddress)
                let test = response.data
                this.daoData = test
                // console.log(test)
                // console.log(this.daoData)
                this.voteData = response.data.data.votes
                let votes = response.data.data.votes
                // console.log(votes.length)

                // console.log(Object.values(this.preProposeData[0]))
                
                
                for (let i = 0; i < votes.length; i++){   
                    // console.log(votes[i].address)                 
                    // let test = Object.values(this.preProposeData[i])[9]
                    // console.log(Object.values(test))
                    // let test2 = Object.values(Object.values(test)[0])[1]
                    // console.log(test2)
                    // this.preProposeData[i].
                    // console.log(this.preProposeData[i])
                    // this.preProposeData[i].push(test2)
                    // this.daoproposal.push(this.daoData[i])                    
                }

            })
            .catch(error =>{
                console.log(error)
            });
        },
        async getAddressTest(){
            // console.log('test')
            try{
                let provider = new ethers.providers.Web3Provider(this.providerType)
                const signer = provider.getSigner()
                this.userAddress = await signer.getAddress()
                // console.log(this.userAddress)
                this.getDao()
            }catch(e){
                console.log(e)
                this.getDao()
            }               
        },
        timeRemaining(time){
            var date1 = new Date();
            var date2 = new Date(time);
            // console.log(date1 , date2);

            var diff = new Date(date2.getTime() - date1.getTime());
            // console.log(diff)   

            var days = diff.getUTCDate()-1;
            var hours = diff.getUTCHours();
            var mins = diff.getUTCMinutes();

            if(hours == 0){
                return(mins+"M REMAINING")
            }else{
                return(days+"D "+hours+"H REMAINING")
            }
        },
        // sleep func
        sleep(ms) {
            return new Promise((resolve) => {
                setTimeout(resolve, ms);
            });
        },    
        vote(choice){
            let provider = new ethers.providers.Web3Provider(this.providerType)                
            // provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner()
            let test = signer.getAddress()            
            // console.log(test)            
            if(test){
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Please Wait !',
                    html: 'Your Vote is being recorded.  Do not refresh or close this page.<br/> (Check Metamask confirmation pop-up window.)',
                    background: "#FDF0E9",       
                    allowOutsideClick: false,
                    didOpen: () => {
                        this.$swal.showLoading()
                    },
                });

                let provider = new ethers.providers.Web3Provider(this.providerType)
                provider.send("eth_requestAccounts", []);
                this.providerType.request({method: 'eth_requestAccounts'})
                .catch((error) => {
                    console.log(error); 
                })
                .then(async () => {
                    const signer = provider.getSigner()
                    // const address = window.ethereum.selectedAddress;
                    const address = await signer.getAddress();
                    // console.log(address)
                    try{
                        const contract = new ethers.Contract(this.mintAddress, this.mintAbi, signer);
                        let balance = await contract.balanceOf(address)
                        // let checkCurrent = await contract.getCurrentVotes(address)
                        // console.log(parseInt(balance._hex),parseInt(checkCurrent._hex))
                        let balanceInt  = parseInt(balance._hex)
                        // let checkCurrentInt = parseInt(checkCurrent._hex)
                        if(balanceInt > 0){
                            // console.log(choice);
                            var daoChoice = 0;
                            // let provider = new ethers.providers.Web3Provider(window.ethereum)                
                            provider.send("eth_requestAccounts", []);
                            this.providerType.request({method: 'eth_requestAccounts'})
                            .catch((error) => {
                                console.log(error); 
                            })
                            .then(async () => {
                            const signer = provider.getSigner()
                            // const address = window.ethereum.selectedAddress; 
                                    
                            if(choice == 'agree'){
                                daoChoice = 1;
                            }else if(choice == 'disagree'){
                                daoChoice = 0;
                            }
                            // console.log(daoChoice);
                                    
                                try{                                    
                                    const contract = new ethers.Contract(this.daoAddress, this.daoAbi, signer);
                                    // console.log(contract)
                                    // console.log(ethers.utils.parseEther(this.pid))
                                    // console.log(ethers.BigNumber.from(this.pid))                                    
                                    let vote = await contract.castVote(ethers.BigNumber.from(this.pid) , parseInt(daoChoice))
                                    // console.log(vote)
                                    // console.log(vote.hash)
                                    if(vote.hash){
                                        // console.log('10 sec')
                                        await this.sleep(10000)
                                        // console.log('calling..')
                                        let a = await axios.post('https://api.langkahsheraton.com/api/dao/castVote', {
                                            trxHash: vote.hash,                                     
                                        },{ headers: {'Authorization' : `Bearer ${sessionStorage.bt}`} })
                                        // console.log(a)
                                        // console.log(a.statusText)
                                        if (a.statusText == "Created"){
                                            this.$swal.close()
                                            this.$swal.fire({
                                                icon: 'success',
                                                title: 'Vote Submitted',                                       
                                                confirmButtonText: 'OK',                       
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    window.location.replace("/polling");
                                                }
                                                if (result.isDismissed) {
                                                    window.location.replace("/polling");
                                                }
                                            });
                                        }
                                    }                                    
                                
                                }catch(e){
                                    console.log(e.message)
                                    this.$swal.close()
                                    this.$swal.fire({
                                    icon: "error",
                                    title: 'voting failed',
                                    html: 'Voting transaction failed. Please try again.',
                                    confirmButtonText: 'CONTINUE',
                                    background: "#FDF0E9",                       
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                        window.location.reload()
                                        }
                                        if (result.isDismissed) { 
                                        window.location.reload()                  
                                        }
                                    });
                                }
                            })                                                       
                        }else{
                            this.$swal.close()
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Insufficient NFTs',
                                text: 'You do not own any Langkah Sheraton NFTs.',
                                confirmButtonText: 'OK',                       
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.reload()
                                    // window.location.replace("/#homeSec4");
                                }
                                if (result.isDismissed) { 
                                    window.location.reload()                  
                                }
                            });
                        }
                    }catch(e){
                        console.log(e.message)
                        this.$swal.close()
                        this.$swal.fire({
                        icon: "error",
                        title: 'voting failed',
                        html: 'Voting transaction failed. Please try again.',
                        confirmButtonText: 'CONTINUE',
                        background: "#FDF0E9",                       
                        }).then((result) => {
                            if (result.isConfirmed) {
                            window.location.reload()
                            }
                            if (result.isDismissed) { 
                            window.location.reload()                  
                            }
                        });
                    }
                })
            }else{
                this.$swal.fire({
                    icon: 'error',
                    title: 'MetaMask not connected',
                    text: 'Please connect to your Metamask wallet.',
                    confirmButtonText: 'Return',
                    // allowOutsideClick: false,                        
                });
            }
            
        }

    }

}
</script>

<style>
#vote .proposal{
    
    position: relative;
    width:1000px;
    border-radius: 0 ;
    border-style: solid;
    border-color: #2c3e50;
    border-width: 3px;
    padding: 15px;

}

#vote .percentage{
    display:flex;
    gap:5px;
    justify-content: end;
}

#vote .table{
    /* width: 770px; */
    /* margin-top: 30px; */
}

.p-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.y-vote{
    color:#00B050;

}

.n-vote{
    color: red;
}

.top-btn {
    background-color: transparent;
    color:hsl(210, 29%, 24%);
    padding: 10px 12px;
    /* margin: 5px; */
}
.top-btn {
    font-size: 14px !important; 
}

#vote .details{
    position: relative;
    /* width:1000px; */
    height: inherit;    
    padding: 0px 15px 15px 15px;
    display: flex;
    justify-content: space-between;
    margin: 5px 0;      
}

#vote .status {
    background-color: transparent;
    border: solid 2px #2c3e50;
    color:#2c3e50;
    border-radius: 20px;
    padding: 1px 8px;
    width: auto;

}

#vote .result {
    /* position: absolute;
    bottom: 0; */
    margin-top: 20%;
    text-align: end;
}

#vote td{
    border: none !important;
}

.description{
    border-top: 2px solid #2c3e50;
    border-bottom: 2px solid #2c3e50;
}

td > a{
    text-decoration: none;
    color:rgb(0, 0, 0);
}

td > a:hover{
    color:rgb(0, 0, 0);
    text-decoration-line: underline;
}

.status{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px !important;
}

.right-content{
    display:flex;
    align-items: flex-end;
}

.top-btn-wrap{
    display: flex;
    justify-content: space-between;
    width: 1000px;
}

@media screen and (min-width:1025px){
    .webView{
        display: contents;
    }
    .mobileView{
        display: none;
    }
}

@media screen and (max-width:1024px){
    .webView{
        display: none;
    }
    .mobileView{
        display: contents;
    }
    .top-btn-wrap{
        padding: 0 5px;
    }

    #vote .proposal {
        padding:5px ;
    }
    .right-content{
        margin-top: 0;
    }

    #vote .result{
        margin-top: 0;
    }
    #vote .left-content{
        width: 70%;
    }
}

.voteBtn-wrap {
    display: flex;
    justify-content: right;
    gap: 10px;
    flex-wrap:wrap;
}

</style>