<template>
  <div class="home">  
    <div class="section1BG">
      <div class="mobi-content">
        <video controls autoplay loop muted playsinline>
          <source src="../img/ls_video.mp4" type="video/mp4">
        </video>
      </div>
      <div class="section1">        
        <div class="left-content">
          <p class="home_title">
            Langkah 
            Sheraton 
            <b>NFT Project</b>
          </p>
          <p class="desc">
            The Langkah Sheraton NFT project is a novel and innovative venture to enable thousands of fans to own a piece of critically-acclaimed artwork which recorded a pivotal moment in Malaysian political history.  All proceeds will go towards reviving democracy and redeeming the people’s mandate in the next General Election.
          </p>
          <div class="button-wrapper">
            <!-- <button>About</button> -->
            <!-- <button>Origin</button> -->
            <a href="/about">About</a>
            <a href="/origin">Origin</a>
          </div>
        </div>
        <div class="right-content">
          <video controls autoplay loop muted playsinline>
            <source src="../img/ls_video.mp4" type="video/mp4">
          </video>
        </div>
      </div>
    </div>
    <div class="title-bg"></div>
    <div class="section2">
       <div class="left-content">
         <div class="painting-img">
           <img src="../img/home.jpg" alt="">
           <img class="splat" src="../img/oilpaint1.png" alt="">
         </div>
       </div>
       <div class="right-content">
          <p class="home_title">
            Langkah Sheraton  
            <b>Painting</b>
          </p>
          <p class="desc">
            ‘Langkah Sheraton’ records moments leading to the notorious ‘Sheraton Move’ as key political players manoeuvred and out-manoeuvred each other in the attempt to secure majority control of the Malaysian Parliament.
          </p>
          <div class="button-wrapper">
            <!-- <button>The Painting</button>
            <button>The Artist</button> -->
            <a href="/painting">The Painting</a>
            <a href="/artist">The Artist</a>
          </div>
       </div>
    </div>
    <div  class="section3">
      <p id="home_review" class="home_title">
        <b>Reviews:</b><br/>
        What People Are Saying
      </p>
      <div class="review-wrapper">
        <div class="review-card">
          <div class="review">
            <p>"The tumultuous Sheraton Move of Feb 23 toppled the Pakatan Harapan government and Pua got the boot too. Then MCO 1.0 came into force on March 18 and, suddenly, there was time to paint...</p>
            <p>Nurturing a latent talent during the pandemic gets a nod too."</p>
          </div>
          <div class="user-review">
            <img src="../img/the-edge.png" alt="">
            <p class="name">The Edge</p>
          </div>
          <div class="review-more">
            <a href="/review/Edge">More</a>
          </div>
        </div>
        <div class="review-card">
          <div class="review">
            <p>“Artworks with a political or satirical theme aren’t that uncommon in Malaysia but a work painted by a serving member of Parliament is indeed rare if not unique.</p>
            <p>…the composition is original… Langkah Sheraton certainly grabs your attention amidst all the other artworks…”</p>           
          </div>
          <div class="user-review">
            <img src="../img/Johnni.jpg" alt="">
            <div>
              <p class="name">Johnni Wong</p>
              <p>Art curator & writer</p>
            </div>            
          </div>
          <div class="review-more">
            <a href="/review/Johnni_Wong">More</a>
          </div>
        </div>
        <div class="review-card">
          <div class="review">
            <p>“If a picture paints a 1000 words, this one writes a book that is easily understandable and viscerally accessible…  </p>
            <p>The Langkah Sheraton NFT allows collectors to own a piece of history with a sharing of truths, the consideration of which, should be collected to make change.”</p>
          </div>
          <div class="user-review">
            <img src="../img/Shalini.jpg" alt="">
            <div >
              <p class="name">Shalini Ganendra</p>
              <p>Art Curator & Independent Scholar</p>
            </div>            
          </div>
          <div class="review-more">
            <a href="/review/Shalini_Ganendra">More</a>
          </div>
        </div>        
      </div>
    </div>
    <div class="section4BG">
      <div id="homeSec4" class="section4">
        <div class="home_title">
          <span><b>NFT</b> Collection</span>
          <!-- <span>EXPLORE MORE</span> -->
          <a href="/nft">EXPLORE MORE</a>
        </div>
        <div class="mint-desc">
            <b> <u>Mint NFT</u> </b><br/><br/>
            <span>The Langkah Sheraton NFTs will mint for 45 $MATIC each after its official launch on 31 August 2022.</span><br/><br/>
            <span>You can mint up to 250 NFTs (250 x 45 $MATIC) for every transaction. There is no limit on the number of transactions you can perform.There will be a minimal additional ‘gas fee’ charge for every transaction.</span><br/><br/>
            <span>All NFTs are minted and randomly allocated. We have 6,000 Original, 2,400 Limited, 400 Rare and 88 Ultra-Rare NFTs.</span><br/><br/>
            <span>However, for every 100 NFTs minted, the purchaser will have a guaranteed (randomly generated) Ultra Rare NFT.  Don’t miss this opportunity!</span>  
        </div> 
        <vueper-slides
          :arrows-outside="false"
          class="no-shadow"
          :visible-slides="3"
          slide-multiple
          :gap="3"
          :slide-ratio="1 / 4"
          :dragging-distance="200"
          :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }"
        >
          <template #arrow-left>
            <button class="dir-arrows"><img src="../img/caret-left-solid.png" alt="left arrow"></button>
          </template>
          <template #arrow-right>
            <button class="dir-arrows"><img src="../img/caret-right-solid.png" alt="right arrow"></button>
          </template>
          <vueper-slide v-for="(slide, i) in nfts" :key="i" :image="slide" />
        </vueper-slides>
        <vueper-slides
          :arrows-outside="false"
          class="no-shadow"
          :visible-slides="3"
          slide-multiple
          :gap="3"
          :slide-ratio="1 / 4"
          :dragging-distance="200"
          :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }"
        >
          <template #arrow-left>
            <button class="dir-arrows"><img src="../img/caret-left-solid.png" alt="left arrow"></button>
          </template>
          <template #arrow-right>
            <button class="dir-arrows"><img src="../img/caret-right-solid.png" alt="right arrow"></button>
          </template>
          <vueper-slide v-for="(slide, i) in nfts2" :key="i" :image="slide" />
        </vueper-slides>
        <div v-if="mintingAvailable >= mintMin  && connectStatus == 'true'" class="mint-section">
          <form @submit.prevent="mint2(mintingStage)">

            <div class="nftAmount">
              <div v-if="mintingStage == 0" class="nftStage">
                <span><b>Minting stage: Pre-Sales</b> </span>
              </div>
              <div v-if="mintingStage == 1" class="nftStage">
                <span><b>Minting stage: Whitelist</b> </span>
              </div>
              <div v-if="mintingStage == 2" class="nftStage">
                <span><b>Minting stage: Public</b> </span>
              </div>
              <span>No. of NFT(s) available: {{mintingAvailable}}</span>              
            </div>
            <div class="qty-wrapper">              
              <button v-on:click="mintDEC()" type="button">-</button>
              <input v-model="mintQty" :min="mintMin" :max="mintMax" type="number" v-on:input="mintQtyInput();">
              <button v-on:click="mintINC()" type="button">+</button>
            </div>
            <div class="mintAlert" v-bind:class="{hideBtn: dontHideMint}"><span>Mint Quantity: min {{mintMin}} , max {{mintMax}}</span></div>
            <div v-if="mintingStage == 0">
              <button type="submit" class="mintBtn" v-bind:class="{hideBtn: hideMint}" data-bs-toggle="modal" data-bs-target="#exampleModal"  v-on:click="getLimit()">
                MINT NOW
              </button>
            </div>
            <div v-else>
              <div class="d-flex mt-3">
                  <div class="tncBox"><input type="checkbox" @change="tncCheckbox" ></div>              
                  <div class="tnc"><span>I have read and agree to Langkah Sheraton NFT <a href="/tnc" target="_blank"><i>Terms and Conditions</i></a> and <a href="/files/Privacy Policy - NOTICE UNDER THE PERSONAL DATA PROTECTION ACT 2010.pdf" download><i>Privacy Policy</i></a> </span></div>

              </div>
              <button type="submit" class="mintBtn" v-bind:class="{hideBtn: hideMint}" v-on:click="getLimit()">
                MINT NOW
              </button>
            </div>
            <button type="submit" class="mintBtn" v-bind:class="{hideBtn: dontHideMint}" data-bs-toggle="modal" data-bs-target="#exampleModal"  v-on:click="getLimit()" disabled>
              MINT NOW
            </button>
          </form>          
        </div>
        <div v-else class="mint-section">
          <form @submit.prevent="mint2()">
            <div class="nftAmount"><span>No. of NFT(s) available: {{mintingAvailable}}</span></div>
            <div class="qty-wrapper">              
              <button v-on:click="mintDEC()" type="button" disabled>-</button>
              <input v-model="mintQty" :min="mintMin" :max="mintMax" type="number" v-on:input="mintQtyInput();" disabled>
              <button v-on:click="mintINC()" type="button" disabled>+</button>
            </div> 
            <div v-if="connectStatus == 'true'" class="mintAlert" ><span>Mintable NFTs for current stage are less than the minimum minting amount. </span></div>
            <div v-else class="mintAlert"><span>Please connect to your Metamask wallet</span></div>
            <div class="d-flex mt-3">
                <div class="tncBox"><input type="checkbox" @change="tncCheckbox"  disabled ></div>                                
                <div class="tnc"><span>I have read and agree to Langkah Sheraton NFT <a href="/tnc" target="_blank"><i>Terms and Conditions</i></a> and <a href="/files/Privacy Policy - NOTICE UNDER THE PERSONAL DATA PROTECTION ACT 2010.pdf" download><i>Privacy Policy</i></a> </span></div>
            </div>
            <button type="submit" class="mintBtn"  data-bs-toggle="modal" data-bs-target="#exampleModal"  v-on:click="getLimit()" disabled>
              MINT NOW
            </button>
          </form>
        </div>
      </div>
    </div>
    <img src="../img/header.png" style="width: 100%;margin-top: -100px;">
    <div class="section5" id="home_howto">
      <p class="home_title">
        <b>How</b> to get <br/> started
      </p>
      <div class="content-container">
        <div class="content-wrapper">
          <img src="../img/icon1.png">
          <div class="sub-title">
            Get your MetaMask <br/> wallet ready 
          </div>
          <div class="desc">
            Make sure you have a MetaMask wallet to start with. Do not use any other non-custodial wallets.<br/><br/>
            If you do not have a MetaMask wallet, please proceed to open one at <a href="http://metamask.io"><b>metamask.io</b></a> to store your cryptocurrency and NFTs.
          </div>
        </div>
        <div class="content-wrapper">
          <img src="../img/icon2.png">
          <div class="sub-title">
            Purchase sufficient <br/> Polygon $MATIC
          </div>
          <div class="desc">
            If you have Ethereum (ETH) in your Metamask wallet, you could bridge your ETH to $MATIC by logging in to the Polygon Web Wallet (within the Metamask wallet).<br/><br/>
            Alternatively, you can also purchase $MATICs directly with fiat currency via decentralised exchanges such as Coinbase, Kraken or <a href="https://crypto.com/">crypto.com</a>.
          </div>
        </div>
        <div class="content-wrapper">
          <img src="../img/icon3.png">
          <div class="sub-title">
            Choose the number of NFT <br/> you want and mint it
          </div>
          <div class="desc">
            Connect your MetaMask to this website (click on the ‘wallet’ icon on the top right corner).<br/><br/>
            Start minting Langkah Sheraton NFTs <a href="https://www.langkahsheraton.com/mintnft"><b>here</b></a> on 31 Aug 2022 .
          </div>
        </div>
      </div>
    </div>
    <div id="home_whatsin" class="section5">
      <p class="home_title">
        What’s in it  <br/> for <b>you</b>
      </p>
      <p class="desc">
        By acquiring and minting the <br/>
        Langkah Sheraton NFTs, for every <br/>
        NFT, you will:
      </p>
      <div class="content-container">
        <div class="content-wrapper">
          <img src="../img/iconStar.png">
          <div class="desc">
            Receive a randomised limited edition Langkah Sheration NFT digital token, with different rarity values.  These NFTs will be tradeable in Opensea.io, a global NFT marketplace.
        </div>
        </div>
        <div class="content-wrapper">
          <img src="../img/iconEqual.png">
          <div class="desc">
            Receive an equal share of the physical ‘Langkah Sheraton’ oil painting masterpiece by Tony Pua; accompanied by an equal right toward managing the asset via the Decentralised Autonomous Organisation (DAO).
          </div>
        </div>
        <div class="content-wrapper">
          <img src="../img/iconValue.png">
          <div class="desc">
            Receive an opportunity for the appreciation of value of either, or both of the limited edition NFT digital token, and the ‘Langkah Sheraton’ painting over time.
          </div>
        </div>
        <div class="content-wrapper">
          <img src="../img/iconNotif.png">
          <div class="desc">
            Receive priority notifications and allocations in all future NFT projects by both Tony Pua and the DAP.
          </div>
        </div>
        <div class="content-wrapper">
          <img src="../img/iconDemo.png">
          <div class="desc">
            Contribute significantly towards to cause of democracy, freedom and justice, particularly for the developing nation of Malaysia.
          </div>
        </div>
        <div class="content-wrapper dark">
          <img src="../img/iconDisc.png">
          <div class="desc">
            Join our Discord Channel! You’ll be updated on our upcoming actions, events and initiatives!
          </div>
          <!-- <button class="join-btn">Join</button> -->
          <a href="https://discord.gg/Kq3CXUY23j" target="_blank">Join</a>
        </div>
      </div>
    </div>
    <div class="section6">
      <div class="mobi-content">
        <img src="../img/groupPhoto.png">
      </div>
      <div class="left-content">
        <p class="home_title">
          Let us make <br />
          Malaysia a better<br />
          society for all!<br />
        </p>
        <p class="desc mb-5">
          The Democratic Action Party (DAP) has been the leading political party fighting for a free, fair and democratic Malaysia since 1966.  The party is committed to the principles of freedom, human rights, equal opportunity, social and economic justice, founded on the institution of parliamentary democracy. Despite various setbacks throughout its struggles, the latest being the Sheraton Move, the DAP remains resolute and unflinching in the determination to build a better Malaysia for all Malaysians, regardless of race, religion or creed.
        </p>
        <a href="/dap">ABOUT DAP</a>
      </div>
      <div class="right-content">
        <img src="../img/groupPhoto.png">
      </div>
    </div>  

    <!-- modal -->
    <div class="modal fade" tabindex="-1" id="exampleModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">You will be guaranteed a Ultra Rare NFTs for every 100 NFTs purchased.</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- <p>{{mintQty}}</p> -->
            <div class="mint-select-info">
              <div class="me-5"><p>Mint amount: {{mintQty}}</p></div>
              <div class="me-5"><p>Selection available: {{selectLimit - selectedNFT.length}}</p></div>
                   
              <!-- <div v-if="selectedNFT.length != 0"><p>Rare NFT selected (id): {{selectedNFT}}</p></div>  -->
              <!-- <div v-else><p>Rare NFT selected: none</p></div> -->
            </div>             

            <div class="d-flex" >
              <div class="nft-modal-btn">
                <button class="dir-arrows" v-on:click="nftModalPage()"><img src="../img/caret-left-solid.png" alt="left arrow"></button>
              </div>
              <div class="nft-modal" :class="{hideBtn: display2nd}">
                <div class="col-md-4 d-flex justify-content-center my-3"  v-for="nft in availableNFT.slice(0,6)" :key="nft.id" id="itemList">
                  <label :for="'nftSelect'+nft.id">
                    <input :id="'nftSelect'+nft.id" :value="nft.id" type="checkbox" class="flip-checkbox selectNFT" v-on:change="selectNFT(nft.id)" />
                    <div class="nft-box d-flex align-items-center justify-content-chideBtnenter">
                        <div class="nft-img">
                            <img :src="require('../img/nft/'+(nft.title)+'.jpg')" alt="">
                        </div>
                        <div class="nft-title">
                            <!-- <h3>{{nft.title}}</h3> -->
                        </div> 
                    </div>
                  </label>                
                </div> 
              </div> 
              <div class="nft-modal" :class="{hideBtn: display1st}">
                <div class="col-md-4 d-flex justify-content-center my-3"  v-for="nft in availableNFT.slice(6,12)" :key="nft.id" id="itemList">
                  <label :for="'nftSelect'+nft.id">
                    <input :id="'nftSelect'+nft.id" :value="nft.id" type="checkbox" class="flip-checkbox selectNFT" v-on:change="selectNFT(nft.id)" />
                    <div class="nft-box d-flex align-items-center justify-content-center">
                        <div class="nft-img">
                            <img :src="require('../img/nft/'+(nft.title)+'.jpg')" alt="">
                        </div>
                        <div class="nft-title">
                            <!-- <h3>{{nft.title}}</h3> -->
                        </div> 
                    </div>
                  </label>                
                </div>                 
              </div>
              <div class="nft-modal-btn">
                <button class="dir-arrows" v-on:click="nftModalPage()"><img src="../img/caret-right-solid.png" alt="right arrow"></button>
              </div>           
            </div>

            <div class="nft-modal-btn-btm">
              <button class="dir-arrows" v-on:click="nftModalPage()"><img src="../img/caret-left-solid.png" alt="left arrow"></button>
              <button class="dir-arrows" v-on:click="nftModalPage()"><img src="../img/caret-right-solid.png" alt="right arrow"></button>
            </div>

            
            <!-- latest -->
            <!-- <div class="d-flex flex-wrap">
              <div class="col-md-4 d-flex justify-content-center my-3" v-for="nft in paginatedItems" :key="nft.id" id="itemList">
                <label :for="'nftSelect'+nft.id">
                  <input :id="'nftSelect'+nft.id" :value="nft.id" type="checkbox" class="flip-checkbox selectNFT" v-on:change="selectNFT(nft.id)" />
                  <div class="nft-box d-flex align-items-center justify-content-center">
                      <div class="nft-img">
                          <img :src="nft.image" alt="">
                      </div>
                      <div class="nft-title">
                          <h3>{{nft.title}}</h3>
                      </div> 
                  </div>
                </label>                
              </div> 
            </div>
            <div class="d-flex justify-content-center mt-5">
              <b-pagination
                @change="onPageChanged"
                :total-rows="totalRows"
                :per-page="perPage"
                v-model="currentPage"
                class="my-0"
              />
            </div> -->
            
          </div>
          <div class="modal-footer">
            <div class="d-flex my-3 modal-tnc">
                <div class="tncBox"><input type="checkbox" @change="tncCheckbox" ></div>                                
                <div class="tnc"><span>I have read and agree to Langkah Sheraton NFT <a href="/tnc" target="_blank"><i>Terms and Conditions</i></a> and <a href="/files/Privacy Policy - NOTICE UNDER THE PERSONAL DATA PROTECTION ACT 2010.pdf" download><i>Privacy Policy</i></a> </span></div>
            </div>
            <button type="button" class="btn btn-primary"  v-on:click="mint()">Confirm</button>
            <!-- <button type="button" class="btn btn-primary" v-on:click="mint()" >Mint</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import {ethers} from "ethers";
import ABI from "/src/ABI/mint.json";
import axios from "axios";
import WalletConnectProvider from "@walletconnect/web3-provider";

const providerWC = new WalletConnectProvider({
    rpc: {
    //   56:"https://bsc-dataseed.binance.org/"
        137:"https://polygon-rpc.com/"
    },
    qrcodeModalOptions: {
      mobileLinks: [
        "rainbow",
        "metamask",
        "argent",
        "trust",
        "imtoken",
        "pillar",
      ]
    }
});

export default {
  name: 'Home',
  components: { VueperSlides, VueperSlide },
  data(){
    return{
      mintAddress: process.env.VUE_APP_MINT_ADDRESS,
      mintAbi: ABI,
      transitioning: false,
      initialStepUpper: '',
      initialStepLower: '',
      stepNftUp: '',
      stepNftDown: '',
      innerStylesUp: {},
      innerStylesDown: {},
      mintQty: 0,
      selectLimit: 0,
      baseAmt: 0,
      nftData: "",
      availableNFT: [],
      selectedNFT:[],
      paginatedItems: "",
      currentPage: 1,
      perPage: 6,
      totalRows: "",
      hideMint: false,
      dontHideMint: true,
      display1st: true,
      display2nd: false,
      mintingStage: 0,
      mintingAvailable: 0,
      mintMin: 0,
      mintMax: 500,
      connectStatus: "",
      providerType:"",
      tncCheck: false,
      nfts: [
        require("../img/nft/full_artwork.jpg"),
        require("../img/nft/full_black.jpg"),
        require("../img/nft/full_black2.jpg"),
        require("../img/nft/full_blue.jpg"),
        require("../img/nft/full_green.jpg"),
        require("../img/nft/full_lightblue.jpg"),        
      ],
      nfts2: [
        require("../img/nft/head_color.jpg"),
        require("../img/nft/item_color.jpg"),
        require("../img/nft/tony_artwork.jpg"),
        require("../img/nft/full_orange.jpg"),
        require("../img/nft/full_red.jpg")
      ],
    }
  },
  async mounted () {
    if(sessionStorage.connectType == "WC"){
        console.log('testing');
        console.log(providerWC);
        await providerWC.enable();
        this.providerType = providerWC;
    }else{
        this.providerType = window.ethereum;
    } // identify wallet provider

    window.scrollTo(0, 0) //load page from top
    this.getNFT()  //retrieve NFTs

    if(sessionStorage.connect == "true"){
      this.mintStatus('load') //get minting status from contract
    }
    
    //--nft imgs--//
    await this.setStep()
    this.innerStylesUp = {
        transition: 'none',
        transform: `translateX(-${this.initialStepUpper})`
    }
    this.innerStylesDown = {
        transition: 'none',
        transform: `translateX(-${this.initialStepLower})`
    }
    //-----//
    

  },
  methods: {
    tncCheckbox(){
        this.tncCheck = !this.tncCheck;
        console.log(this.tncCheck)
    },
    mintStatus(x){
      let provider = new ethers.providers.Web3Provider(this.providerType)
      provider.send("eth_requestAccounts", []);
      this.providerType.request({method: 'eth_requestAccounts'})
      .catch((error) => {
          console.log(error); 
      })
      .then(async () => {
          const signer = provider.getSigner()
          // const address = this.providerType.selectedAddress;
          // console.log(address)
          try{
            const contract = new ethers.Contract(this.mintAddress, this.mintAbi, signer);
            let mintStage = await contract.stageNow();
            this.mintingStage = parseInt(mintStage._hex);
            // this.mintingStage = 0;
            // let mintedAmount = await contract.totalSupply();
            // this.mintingAvailable = 8888 - mintedAmount;
            
            axios.get('https://api.langkahsheraton.com/api/sheraton/getSales'
            ).then((response) => {
              console.log(response)
              this.mintingAvailable = response.data.availableMint
            })

            if(this.mintingStage == 0){
              // this.mintingAvailable = 4500 - mintedAmount;
              this.mintMin = 300;
              this.mintMax = 500;
            }else if(this.mintingStage == 1){
              // this.mintingAvailable = 7000 - mintedAmount;
              this.mintMin = 20;
              this.mintMax = 100;
            }else if(this.mintingStage == 2){
              // this.mintingAvailable = 8888 - mintedAmount;
              this.mintMin = 1;
              this.mintMax = 500;
            }
            if (x == 'load'){
              this.mintQty = this.mintMin;
              // console.log(this.mintQty)
              
            }else if( x == 'check'){
              if (this.mintQty > this.mintingAvailable){
                this.$swal.fire({
                    icon: 'error',
                    title: 'Exceeded mintable amount',
                    text: 'Selected mint amount exceeded amount available.',
                    confirmButtonText: 'Return',
                    // allowOutsideClick: false,                        
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                    if (result.isDismissed) {
                        window.location.reload();
                    }
                });
              }
            }
              
          }catch(e){
              console.log(e.message)
          }
      })
    },    
    mintQtyInput(){   
      this.mintQty = parseInt(this.mintQty)
      if(this.mintQty>this.mintMax || this.mintQty<this.mintMin){
        this.hideMint = true;
        this.dontHideMint = false;
      }else{
        this.hideMint = false;
        this.dontHideMint = true;
      }
    },
    //-- wallet validation --/
    metaMask(){
        if(sessionStorage.connect != "true"){
            this.$swal.fire({
                icon: 'error',
                title: 'MetaMask not connected',
                text: 'Please connect to your Metamask wallet.',
                confirmButtonText: 'Return',
                // allowOutsideClick: false,                        
            })
        }
    },
    //-- pagination --//
    paginate(page_size, page_number) {
      let itemsToParse = this.availableNFT;
      this.paginatedItems = itemsToParse.slice(
        page_number * page_size,
        (page_number + 1) * page_size
      );
    },
    onPageChanged(page) {
      this.paginate(this.perPage, page - 1);
    },
    //-- nft selection --//
    selectNFT(nftId){
      // console.log('change')
      //-- nft select --//
      var divId = "nftSelect"+nftId
      var getDiv = document.getElementById(divId);
      var content = getDiv.nextElementSibling;   
      var array = this.selectedNFT     

      if(getDiv.checked){    
        // console.log('check')
        if(this.baseAmt < this.selectLimit)   {
          if(array.includes(nftId) == false){
            content.classList.add("testClass")
            this.baseAmt = this.baseAmt + 1;
            array.push(nftId);
          }          
        }else{
          getDiv.checked = false;
        }      
      }else{
        // console.log('no')
        content.classList.remove("testClass")
        this.baseAmt = this.baseAmt - 1;
        var index = array.indexOf(nftId);
        array.splice(index, 1);
      }

      // console.log(array)

    },
    async getLimit(){
      if(sessionStorage.connect != "true"){
          this.$swal.fire({
              icon: 'error',
              title: 'MetaMask not connected',
              text: 'Please connect to your Metamask wallet.',
              confirmButtonText: 'Return',
              allowOutsideClick: false,                        
          }).then((result) => {
              if (result.isConfirmed) {
                  // window.location.reload();
                  window.location.reload();
              }
          });
      }

      this.mintStatus('check');
      this.mintQtyInput();
      let provider = new ethers.providers.Web3Provider(this.providerType)
      const signer = provider.getSigner()
      let userAddress = await signer.getAddress()
      axios.get('https://api.langkahsheraton.com/api/nft/getAllowSelect', {
        params: {          
          address: userAddress,
          mintAmount: this.mintQty,
        }
      }
      ).then((response) => {
        this.selectLimit = response.data.allow   
        // console.log(this.selectLimit)
      })
      // this.selectLimit = Math.floor(mint/100);
      // return(this.selectLimit)
    },

    //-- retrieve nft --//
    async getNFT(){
      
      this.connectStatus = sessionStorage.connect;

      axios.get(            
        'https://api.langkahsheraton.com/api/nft/getAvailableSelectedNft'
        )
        .then((response) => {
            // console.log(response)
            this.nftData = response.data.data
            this.rows = this.nftData.length            
            for (let i = 0; i < this.nftData.length; i++){
                this.availableNFT.push(this.nftData[i])                    
            }
            
        }) 
        .then(()=>{
          this.paginatedItems = this.availableNFT;
          this.totalRows = this.availableNFT.length;
          this.paginate(this.perPage, 0); //pagination
          // console.log(this.paginatedItems , this.totalRows)
        })       
        .catch(error =>{
            console.log(error)
        });
      
      
    },
    //-- mint nft --//
    mintINC(){
      // this.mintQty = this.mintQty + 1;
      this.mintQty++;
      this.mintQtyInput()
    },
    mintDEC(){
      this.mintQty--;
      this.mintQtyInput()
    },
    async mint(){
      // console.log(this.mintAddress) 
      // console.log(this.selectedNFT.length , this.selectLimit)     

      if(this.selectedNFT.length == this.selectLimit){
        if( this.tncCheck == true){
          this.$swal.fire({
            icon: 'warning',
            title: 'Please Wait !',
            html: 'NFT(s) minting is in progress.  Do not refresh or close this page.<br/> (Check Metamask confirmation pop-up window.)',
            background: "#FDF0E9",       
            allowOutsideClick: false,
            didOpen: () => {
                this.$swal.showLoading()
            },
          });
          try{
            let totalMintFee = 35*this.mintQty
            let provider = new ethers.providers.Web3Provider(this.providerType) 
            const signer = provider.getSigner()
            let message = 'Mint NFT';
            let signature = await signer.signMessage(message);
            // console.log(await signer.getAddress(),)
            // console.log(message, signature, this.mintQty, Object.values(this.selectedNFT))

            const contract = new ethers.Contract(this.mintAddress, this.mintAbi, signer);
            let mint = await contract.mint(parseInt(this.mintQty), {value: ethers.utils.parseEther(totalMintFee.toString())})
            // console.log(mint)
            // console.log(mint.hash)
            await this.sleep(3000)
            // console.log('sleep')
            await axios.post('https://api.langkahsheraton.com/api/nft/mintNFT', {          
                address: await signer.getAddress(),
                signature: signature,
                message: message,
                amount: Number(this.mintQty),
                imgID: Object.values(this.selectedNFT)
            },{ headers: {'Authorization' : `Bearer ${sessionStorage.bt}`} }).then(async (response)=>{
              console.log(response)
              if(mint.hash){
                this.$swal.close()
                this.$swal.fire({
                  imageUrl:"../img/oilpaint1.fb5db1fb.png",
                  title: 'Congratulation!',
                  html: 'You have successfully minted <br/> your Langkah Sheraton NFT(s).<br/><br/> You can view your minted NFT here:<br/><a href="https://opensea.io/account" target="_blank" style="color:black;">opensea.io/account</a>',
                  confirmButtonText: 'CONTINUE',
                  background: "#FDF0E9",
                  // allowOutsideClick: false,                        
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                    if (result.isDismissed) {
                        window.location.reload();
                    }
                });
              } 
            }).catch((error) =>{
              console.log(error)
              this.$swal.close()
              this.$swal.fire({
                icon: "error",
                title: 'Minting failed',
                html: 'Metamask minting transaction failed. Please ensure you have sufficient $MATIC and try again.',
                confirmButtonText: 'CONTINUE',
                background: "#FDF0E9",
                // allowOutsideClick: false,                        
              }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload()
                      // window.location.replace("/#homeSec4");
                  }
                  if (result.isDismissed) { 
                    window.location.reload()                  
                  }
              });
            })
        
          }catch(e){
              this.$swal.close()
              this.$swal.fire({
                icon: "error",
                title: 'Minting failed',
                html: 'Metamask minting transaction failed. Please ensure you have sufficient $MATIC and try again.',
                confirmButtonText: 'CONTINUE',
                background: "#FDF0E9",
                // allowOutsideClick: false,                        
              }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload()
                      // window.location.replace("/#homeSec4");
                  }
                  if (result.isDismissed) { 
                    window.location.reload()                  
                  }
              });
              console.log(e.message)
          }
        }else{
          this.$swal.fire({
            icon: "error",
            title: 'Terms and Conditions not agreed.',
            html: 'Please agree to the Terms and Conditions and Privacy Policy',
            confirmButtonText: 'CONTINUE',
            background: "#FDF0E9",                       
          })
        }        
      }else{
        this.$swal.fire({
          icon: "error",
          title: 'Available NFT Selection',
          html: 'Please select your available ultra rare NFT(s).',
          confirmButtonText: 'CONTINUE',
          background: "#FDF0E9",                       
        })
        // .then((result) => {            
        // });
      }


      // await axios.post('https://api.langkahsheraton.com/api/nft/mintNFT', {          
      //     address: await signer.getAddress(),
      //     signature: signature,
      //     message: message,
      //     amount: Number(this.mintQty),
      //     imgID: Object.values(this.selectedNFT)
      // }).then((response)=>{
      //   console.log(response)
      //   let provider = new ethers.providers.Web3Provider(this.providerType)
      //   provider.send("eth_requestAccounts", []);
      //   this.providerType.request({method: 'eth_requestAccounts'})
      //   .catch((error) => {
      //       console.log(error); 
      //   })
      //   .then(async () => {
      //       const signer = provider.getSigner()
      //       const address = this.providerType.selectedAddress;
      //       // let mintAmt = document.getElementById('mintAmt').value;
      //       let mintAmt = this.mintQty;          
      //       console.log(address, mintAmt)
      //       console.log(this.mintAddress)          
      //       try{
      //           const contract = new ethers.Contract(this.mintAddress, this.mintAbi, signer);
      //           let mint = await contract.mint(parseInt(mintAmt))
      //           console.log(mint)
      //           console.log(mint.hash)
      //           if(mint.hash){
      //             this.$swal.fire({
      //               imageUrl:"../img/oilpaint1.fb5db1fb.png",
      //               title: 'Congratulation!',
      //               html: 'You have successfully minted <br/> your Langkah Sheraton NFT(s). ',
      //               confirmButtonText: 'CONTINUE',
      //               background: "#FDF0E9",
      //               // allowOutsideClick: false,                        
      //             }).then((result) => {
      //                 if (result.isConfirmed) {
      //                     window.location.replace("/#homeSec4");
      //                 }
      //                 if (result.isDismissed) {
      //                     window.location.replace("/#homeSec4");
      //                 }
      //             });
      //           }   
            
      //       }catch(e){
      //           console.log(e.message)
      //       }
      //   })  
      // })
              
      
    },
    async mint2(stage) {
      if(stage != 0 ){
        if(this.tncCheck == true){
          this.$swal.fire({
            icon: 'warning',
            title: 'Please Wait !',
            html: 'NFT(s) minting is in progress.  Do not refresh or close this page.<br/> (Check Metamask confirmation pop-up window.)',
            background: "#FDF0E9",       
            allowOutsideClick: false,
            didOpen: () => {
                this.$swal.showLoading()
            },
          });
          try{

            let mintFee = 40;
            if(stage == 2){
                mintFee = 45;
            }
            let totalMintFee = mintFee*this.mintQty
            // console.log(totalMintFee)
            let provider = new ethers.providers.Web3Provider(this.providerType) 
            const signer = provider.getSigner()
            let message = 'Mint NFT';
            let signature = await signer.signMessage(message);
            // console.log(await signer.getAddress(),)
            // console.log(message, signature, this.mintQty, Object.values(this.selectedNFT))

            const contract = new ethers.Contract(this.mintAddress, this.mintAbi, signer);
            let mint = await contract.mint(parseInt(this.mintQty), {value: ethers.utils.parseEther(totalMintFee.toString())})
            // console.log(mint)
            // console.log(mint.hash)

            await axios.post('https://api.langkahsheraton.com/api/nft/mintNFT', {          
                address: await signer.getAddress(),
                signature: signature,
                message: message,
                amount: Number(this.mintQty),
                imgID: []
            },{ headers: {'Authorization' : `Bearer ${sessionStorage.bt}`} }).then(async (response)=>{
              console.log(response)
              await this.sleep(3000)
              if(mint.hash){
                // console.log('sleep')
                this.$swal.close()
                this.$swal.fire({
                  imageUrl:"../img/oilpaint1.fb5db1fb.png",
                  title: 'Congratulation!',
                  html: 'You have successfully minted <br/> your Langkah Sheraton NFT(s).<br/><br/> You can view your minted NFT here:<br/><a href="https://opensea.io/account" target="_blank" style="color:black;">opensea.io/account</a>',
                  confirmButtonText: 'CONTINUE',
                  background: "#FDF0E9",
                  // allowOutsideClick: false,                        
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                    if (result.isDismissed) {
                        window.location.reload();
                    }
                });
              }
            }).catch((error) =>{
              console.log(error)
              this.$swal.close()
              this.$swal.fire({
                icon: "error",
                title: 'Minting failed',
                html: 'Metamask minting transaction failed. Please ensure you have sufficient $MATIC and try again.',
                confirmButtonText: 'CONTINUE',
                background: "#FDF0E9",
                // allowOutsideClick: false,                        
              }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload()
                      // window.location.replace("/#homeSec4");
                  }
                  if (result.isDismissed) { 
                    window.location.reload()                  
                  }
              });
            })
        
          }catch(e){
              this.$swal.close()
              this.$swal.fire({
                icon: "error",
                title: 'Minting failed',
                html: 'Metamask minting transaction failed. Please ensure you have sufficient $MATIC and try again.',
                confirmButtonText: 'CONTINUE',
                background: "#FDF0E9",
                // allowOutsideClick: false,                        
              }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload()
                      // window.location.replace("/#homeSec4");
                  }
                  if (result.isDismissed) { 
                    window.location.reload()                  
                  }
              });
              
              console.log(e.message)
          }
        }else{
          this.$swal.fire({
            icon: "error",
            title: 'Terms and Conditions not agreed.',
            html: 'Please agree to the Terms and Conditions and Privacy Policy',
            confirmButtonText: 'CONTINUE',
            background: "#FDF0E9",                       
          })
        }        
      }
    },
    nftModalPage() {
      this.display1st = !this.display1st;
      this.display2nd = !this.display2nd;
    },
    setStep () {
      if (this.$refs.nftUp) {
          const innerWidth = this.$refs.nftUp.scrollWidth
          const totalCards = this.nfts.length
          this.initialStepUpper = `${innerWidth % totalCards }px`
          this.step = `${innerWidth / totalCards * 3}px`
      }
      if (this.$refs.nftDown) {
          const innerWidth = this.$refs.nftDown.scrollWidth
          const totalCards = this.nfts2.length
          this.initialStepLower = `${innerWidth % totalCards }px`
          this.step = `${innerWidth / totalCards * 3}px`
      }
    },
    prevUp () {
      if (this.transitioning) return
      this.transitioning = true
      
      this.moveRightUpper()

      this.afterTransition(() => { 
          let card = this.nfts.pop() 
          this.nfts.unshift(card)
          card = this.nfts.pop() 
          this.nfts.unshift(card)
          card = this.nfts.pop() 
          this.nfts.unshift(card)
          this.resetTranslate("upper")
          this.transitioning = false
      })
    },
    prevDown () {
      // console.log("prev");
      if (this.transitioning) return
      this.transitioning = true
      
      this.moveRightLower()

      this.afterTransition(() => { 
          let card = this.nfts.pop() 
          this.nfts2.unshift(card)
          card = this.nfts2.pop() 
          this.nfts2.unshift(card)
          card = this.nfts2.pop() 
          this.nfts2.unshift(card)
          this.resetTranslate("lower")
          this.transitioning = false
      })
    },
    nextUp () {
      if (this.transitioning) return
      this.transitioning = true
      
      this.moveLeftUpper()

      this.afterTransition(() => { 
          let card = this.nfts.shift()
          this.nfts.push(card)
          card = this.nfts.shift()
          this.nfts.push(card)
          card = this.nfts.shift()
          this.nfts.push(card)
          this.resetTranslate("upper")
          this.transitioning = false
      })
    },
    nextDown () {
      if (this.transitioning) return
      this.transitioning = true
      
      this.moveLeftLower()

      this.afterTransition(() => { 
          let card = this.nfts2.shift()
          this.nfts2.push(card)
          card = this.nfts2.shift()
          this.nfts2.push(card)
          card = this.nfts2.shift()
          this.nfts2.push(card)
          this.resetTranslate("lower")
          this.transitioning = false
      })
    },
    // sleep func
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    // adjust movement of upper NFT Collection
    moveLeftUpper () {
        this.innerStylesUp = {
            transform: `translateX(-${this.stepNftUp})
                        translateX(-${this.stepNftUp})`
        }
    },
    moveRightUpper () {
        this.innerStylesUp = {
            transform: `translateX(${this.stepNftUp})
                        translateX(-${this.stepNftUp})`
        }
    },
    // adjust movement of lower NFT Collection
    moveLeftLower () {
        this.innerStylesDown = {
            transform: `translateX(-${this.stepNftDown})
                        translateX(-${this.stepNftDown})`
        }
    },
    moveRightLower () {
        this.innerStylesDown = {
            transform: `translateX(${this.stepNftDown})
                        translateX(-${this.stepNftDown})`
        }
    },
    afterTransition (callback) {
        const listener = () => { 
            callback()
            this.$refs.nftUp.removeEventListener('transitionend', listener)
            this.$refs.nftDown.removeEventListener('transitionend', listener)
        }
        this.$refs.nftUp.addEventListener('transitionend', listener) 
        this.$refs.nftDown.addEventListener('transitionend', listener) 
    },
    resetTranslate (collection) {
      if (collection == "upper"){
        this.innerStylesUp = {
            transition: 'none',
            transform: `translateX(-${this.initialStepUpper})`
        }
      } else if (collection == "upper") {
        this.innerStylesDown = {
            transition: 'none',
            transform: `translateX(-${this.initialStepLower})`
        }
      }
    }
  }
}
</script>
<style>
.section4 .nft-collection-carousel {
  display: flex;
  align-items: center;
  margin: auto;
  
}
.section4 .nft-carousel {
  width: 350px;
  /* height: 580px; */
  overflow-x: auto;
  overflow-y: hidden;
  text-align: center;
  margin: auto;
}
.section4 .nft-carousel .nft-card {
  margin: 70px auto 0 auto;
  width: 350px;
  height: 400px;
  display: inline-flex;
  /* optional */
  /* background-color: var(--bs-gray-900); */
  color: white;
  align-items: center;
  justify-content: center;
}

.home .tncBox {
    display: flex;
    align-items: center;
    width: 25px;
}

.home .tnc{
    text-align: left;
    padding-left: 15px;
    color: white;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.home .tnc a{
    /* font-weight: bold;     */
}

.home .tnc a:hover{
    text-decoration: underline;   
}

.modal-tnc{
    width: 100%;
    display: flex;
    justify-content: center;
}

.modal-tnc .tnc, .modal-tnc .tnc a{
    color: rgba(45,45,65,255) !important;
}

@media screen and (min-width: 100px) {
  .mobi-content{
    display: none;
  }

  .home {
    padding-bottom: 5%;
    /* width: 80%; */
    margin: auto;
    
  }
  /* Langkah Sheraton NFT Project */
  .home .section1BG {
    /* background: url("../img/home-top-bg.png") no-repeat; */
    background-color: rgba(40,41,62,255);
    /* background-position: 40% 900%; */
    background-position: center;
    background-size: auto;
    /* background-size: cover; */
    /* height: 875px; */
    background-attachment:inherit;
    /* padding-bottom: 10%; */
  }
  .home .section1 {
    display: grid;
    grid-template-columns: 35% 65%;
    width: 80%;
    align-items: center;
    padding-top: 20px;
    /* padding-bottom: 10%; */
    margin: auto;
    color: white;
    flex-wrap:wrap;

    gap: 3%;
  }

  .section1 .left-content p.home_title {
    font-size: 72px;
    line-height: 1.2;
  }
  .section1 .left-content p.desc {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.6);
  }
  .section1 .left-content .button-wrapper{
    display: flex;
    gap: 20px;
    margin-top: 15%;
  }
  .section2 .right-content .button-wrapper {
    display: flex;
    gap: 20px;
  }
  .section1 .right-content {
    text-align: right;
    padding-left: 30px;
  }
  .section1 .right-content video {
    height: auto;
    width: 100%;
  }

  /* Langkah Sheraton painting */
  .home .section2 {
    display: flex;
    /* grid-template-columns: 50% 50%; */
    width: 80%;
    align-items: center;
    margin: auto;
    justify-content: center;
    color: #391400;
    /* flex-wrap: wrap; */
    max-width: 1900px;
  }
  .section2 .left-content .painting-img {
    position: relative;
  }
  .section2 .left-content .painting-img .splat {
    position: absolute;
    left: -150px;
  }
  .section2 .left-content .painting-img img {
    max-width: 568px;
  }
  .section2 .right-content p.home_title {
    font-size: 60px;
    line-height: 1.2;
  }
  .section2 .right-content p.desc {
    font-size: 20px;
  }

  /* Reviews: What People are Saying */
  .home .section3 {
    padding-bottom: 5%;
    width: 85%;
    margin: auto;
    text-align: center;
  }
  .section3 .home_title {
    font-size: 60px;
    line-height: 1.2;
    color: #391400;
    padding-top: 10%;
  }
  .home .review-wrapper {
    /* width: 80%; */
    margin: 5% 0  ;
    /* display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2%;
  }
  .home .review-card {
    padding: 30px;
    margin-bottom: 30px;
    border: 1px solid #F3D1BF;
    border-radius: 18px;
    width: 400px;
  }
  .home .review {
    font-weight: 100;
    color: #391400;
    text-align: left;
    line-height: 2;
    height:350px;
  }
  .home .review-wrapper .user-review {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 30px;
    height: 130px;
  }
  .home .review-wrapper .user-review .name {
    font-size: 16px;
    font-weight: 700;
    color: #391400;
    width: auto;
    text-align: left;
    line-height: 1;
  }

  .home .review-wrapper .user-review p {
    font-size: 14px;
    /* font-weight: 700; */
    color: #391400;
    width: auto;
    text-align: left;
    line-height: 1;
  }

  .home .review-wrapper .user-review img {
    border-radius: 50%;
    width: 80px;
  }


  .home .review-more a {
    border: none;
    border-radius: 28px;
    width: 84px;
    height: 48px;
    color: #391400;
    background-color: white;
    transition: .5s;
    padding: 15px 25px;
  }

  .home .review-more a:hover {
    color: white;
    background-color: #EE6B57;
  }

  /* nft collection */
  .home .section4BG, #mintnft .section4BG {
    background: #28293E;
    background-position: bottom, 0 0;
    background-size: 110% auto;
    background-attachment:inherit;
    padding-bottom: 10%;
  }
  .home .section4, #mintnft .section4 {
    background: #28293E;
    width: 80%;
    margin: 20px auto;
    position: relative;
    padding: 50px 0;
  }
  .section4 .home_title {
    display: flex;
    justify-content: space-between;
    color: white;
    margin-bottom: 50px;
    align-items: center;
  }
  .section4 .home_title span:first-child {
    font-size: 60px;
  }
  .section4 .mint-section {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .section4 .mint-section .qty-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section4 .mint-section input {
    text-align: center;
    width: 50px;
    height: 30px;
    border: none;
  }
  .section4 .mint-section input:focus-visible {
    outline: none;
  }
  .section4 input::-webkit-outer-spin-button,
  .section4 input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .section4 input[type=number] {
    -moz-appearance: textfield;
  }

  /* How to get started && What's in it for you */
  .section5 {
    width: 80%;
    margin: auto;
    /* margin-bottom: 10%; */
  }
  .section5 .home_title {
    padding-top: 10%;
    font-size: 60px;
    line-height: 1;
  }
  .section5 .home_title+.desc {
    color: rgba(57, 20, 0, 0.5);
  }
  .section5 .content-container {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    gap: 20px;
  }
  .section5 .content-wrapper {
    padding: 40px;
    background-color: white;
  }

  @media screen and (min-width: 1024px) {
    #home_howto .content-wrapper{
      height: 620px;
    }
    #exampleModal .modal-dialog{
      max-width: 1100px !important;
    }
    
    .home .section2 .left-content,
    .home .section2 .right-content{
      flex-grow: 5;
      max-width: 750px;
    }
  }

  #home_howto .content-wrapper a {
    border: none !important;
    padding: 0 !important;
    transition: .5s;
    color: black;
  }

  #home_howto .content-wrapper a:hover {
    background-color: transparent !important;
    color: black !important;
    text-decoration: underline !important;
  }

  .section5 .content-wrapper.dark {
    color: white;
    background-color: #28293E;
  }
  .section5 .content-wrapper .sub-title {
    font-size: 24px;
    color: #391400;
    line-height: 1;
    font-weight: 700;
  }
  .section5 .content-wrapper img {
    padding-bottom: 10px;
  }
  .section5 .content-wrapper div {
    margin-top: 10px;
  }
  .section5 .content-wrapper .desc {
    font-size: 20px;
    margin-bottom: 20%;
  }
  
  /* Let us make Malaysia a better society for all! */
  .section6 {
    width: 80%;
    margin: auto;
    background-color: #28293E;
    color: white;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 80px;
    border-radius: 6px;
    margin-top: 10%;
  }

  .section6 .home_title {
    font-size: 60px;
    line-height: 1.2;
  }
  .section6 .right-content {
    text-align: right;
  }
  .section6 .left-content .desc {
    color: rgba(255, 255, 255, 0.5);
  }

  .section6 a {
    border: none;
    width: 138px;
    height: 48px;
    border-radius: 6px;
    font-size: 14px;
    margin-top: 30px;
    
    color: #391400;
    background-color: white;
    transition: .5s;
    padding: 15px 25px;
  }

  .section6 a:hover {
    color: white;
    background-color: #EE6B57;
  }

  /* Buttons */
  .home .button-wrapper button {
    width: 138px;
    height: 48px;
    border: none;
    background-color: white;
    color: #391400;
    text-transform: uppercase;
    border-radius: 6px;
    transition: .5s;
    font-size: 14px;
  }
  .home .button-wrapper button:hover {
    background-color: #EE6B57;
    color: white;
  }

    .home .button-wrapper a {
    width: 138px;
    height: 48px;
    border: none;
    background-color: white;
    color: #391400;
    text-transform: uppercase;
    border-radius: 6px;
    transition: .5s;
    font-size: 14px;
    text-align: center;
    padding-top:13px;
  }
  .home .button-wrapper a:hover {
    background-color: #EE6B57;
    color: white;
  }

  .section4 .dir-arrows,
  #exampleModal .dir-arrows {
    border-radius: 50%;
    border: none;
    background: #EE6B57;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    font-size: 40px;
    /* position: absolute; */
    top: 50%;
    z-index: 2;
  }

  .section4 .dir-arrows img,
  #exampleModal .dir-arrows img{
    width:35px;
  }


  /* .section4 .home_title span:nth-child(2) {
    border: 1px solid rgb(255, 255, 255, 0.2);
    padding: 10px 18px;
    border-radius: 8px;
    transition: .5s;
    cursor: pointer;
  }
  .section4 .home_title span:nth-child(2):hover {
    border: 1px solid #EF6D58;
    color: #EF6D58;
  } */

  .section4 .home_title a {
    border: 1px solid rgb(255, 255, 255, 0.2);
    padding: 10px 18px;
    border-radius: 8px;
    transition: .5s;
    cursor: pointer;
  }
  .section4 .home_title a:hover {
    border: 1px solid #EF6D58;
    color: #EF6D58;
  }

  .section4 .dir-arrows.left {    
    left: -20px;
  }
  .section4 .dir-arrows.right {
    right: 0;
  }
  .section4 .vueperslides--rtl .vueperslides__arrow--next, .vueperslides__arrow--prev {
    left: -2em;
  }
  .section4 .vueperslides--rtl .vueperslides__arrow--prev, .vueperslides__arrow--next {
    right: -2em;
  }
  .section4 .mint-section .qty-wrapper button {
    text-align: center;
    width: 30px;
    border: none;
    background-color: #EF6D58;
    color: white;
    height: 30px;
  }
  .section4 .mint-section .qty-wrapper button:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .section4 .mint-section .qty-wrapper button:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .section4 .mint-section .mintBtn {
    border: none;
    width: 110px;
    margin-top: 20px;
    height: 48px;
    border-radius: 6px;
    font-size: 14px;
    transition: .5s;
  }

  .section4 .mint-section .mintAlert {
    color: red;
    margin-top: 20px;
  }

  .section5 .content-wrapper button {
    margin-top: 20%;
    border: none;
    padding: 6px 26px;
    border-radius: 20px;
    transition: .5s;
  }

  .section5 .content-wrapper a {
    margin-top: 20%;
    border: none;
    padding: 10px 26px;
    border-radius: 20px;
    transition: .5s;
    background-color: white;
    color: black;
  }

  .section4 .mint-section .qty-wrapper+button:hover,
  .section5 .content-wrapper button:hover {
    background-color: #EF6D58;
    color: white;
  }

  .section4 .mint-section .qty-wrapper+button:hover,
  .section5 .content-wrapper a:hover {
    background-color: #EF6D58;
    color: white;
  }

  .section4 .mint-section .nftAmount {
    color: white;
    margin-bottom: 20px;
    
  }

  .nftAmount .nftStage{
    margin-bottom: 10px;
  }
  .nftAmount .nftStage span{
    font-size: 18px;
  }

  .section6 button {
    border: none;
    width: 138px;
    height: 48px;
    border-radius: 6px;
    font-size: 14px;
    margin-top: 30px;
  }

  .section2 .right-content{
    display:flex;
    flex-wrap: wrap;
    padding-left: 40px;
  }

  .home .nft-box{
    height: 350px;
    width: 300px;
  }

  .home .testClass{
    background: rgba(45,45,65,255) !important;
    outline: 7px solid rgba(150,76,63,255);
    border: 3px solid rgba(150,76,63,255);
  }

  .home .testClass .nft-img{
    opacity: 1;
  }

  .home .testClass .nft-title{
    text-shadow: 0px 0px 10px #000000;
  }

  #exampleModal .nft-img > img{
    height:343px;
    width: 293px;
    object-fit: contain;
  }

  #exampleModal .mint-select-info{
    display: flex;
    flex-direction: column;
  }

  #exampleModal .mint-select-info p{
    margin-bottom: 5px;
  }

  #exampleModal .modal-title{
    color: rgb(112, 112, 112);
  }

  #exampleModal .nft-modal {
    display: flex;    
    flex-wrap: wrap;
    justify-content: center;
  }

  #exampleModal .nft-modal-btn {
    display: flex;    
    align-items: center;
  }

  #exampleModal .nft-modal-btn-btm{
    display:none;
    justify-content: center;
    gap: 20%;
  }

  .home .nft-title  {
    position: absolute;
    top:73%;
    left: 8%;
    width: 80%;
  } 

  .home .nft-title > h3 {
    color: white;
  } 

  #exampleModal .flip-checkbox {
    display: none;
  }

  #exampleModal .modal-footer > button {
    background: rgba(45,45,65,255);
    border: none;
    padding: 10px 20px;  
  }

  #exampleModal .modal-footer {
    display: flex;
    justify-content: center;
  }

  .modal-content{
    background: rgba(252,239,231,255);
  }
}

@media screen and (min-width: 769px){
  .section5{
    /* margin-top: 5%; */
  }
}

/* mobile */
@media screen and (max-width: 1024px) {

  .section1{
    width: 75% !important;
    display: flex !important;
  }

  .right-content{
    padding-left: 0 !important;
    margin-top: 50px;
  }

  .section1 .right-content {
    display:none;
  }

  .section1 .left-content .button-wrapper{
    flex-wrap: wrap;
    width:150px;
    margin-top: 10%;
  }

  .section2{
    display:flex !important;
  }

  .section2 .left-content .painting-img img{
    width: 100%;
    height: auto;
  }

  .section2 .left-content .painting-img img:last-child{
    width: 40%;
    height: auto;
    left: -15% !important;
    top: 25% !important;
  }

  .home .section2{
    flex-wrap: wrap;
  }

  .section3{
    width: 80% !important;
  }

  .section3 .home_title{
    text-align: left;
  }

  .home_title{
    margin-top: 20px;
  }

  .review{
    height: auto !important;
  }

  .review-card {
    margin-bottom: 20px !important;
    width: auto !important;
    padding: 20px !important;
  }


  .section4 .home_title span:first-child
  {
    font-size: 50px !important;
  } 

  .home_title {
    font-size: 50px !important;
  }

  .desc{
    font-size: 16px !important;
  }

  .section4 .home_title a{
    display: none;
  }

  .section5 .content-container {
    display: flex;
    flex-wrap: wrap;
  }

  .section6 {
    display: flex;
    flex-wrap: wrap;
    padding: 10% !important;
  }

  .section6 .right-content {
    display: none;
  }
  
  .mobi-content {
    display: block;
    padding-bottom: 40px;
  }
  .mobi-content img{
    width: 100%;
    height: auto;
  }
  .mobi-content video{
    width: 100%;
    height: auto;
  }

  .section1BG .mobi-content{
    padding: 25px 15px 0 15px;
  }

  #exampleModal .nft-modal-btn {
    display: none;
  }

  #exampleModal .nft-modal-btn-btm{
    display: flex !important;
  }
}
</style>