<template >
    <div id="propose">
        <div class="title row">
            <div class="container">
                <div class="row title-div">
                    <div class="col-10">
                        <div class="text-center d-flex flex-wrap justify-content-center">
                            <div>
                                <h1 class="mb-3">ADMIN PAGE</h1>
                            </div>                            
                            <div >
                                <p>Publish proposal for public voting by interacting through smart contract.</p>                       
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="title-bg"></div>
       
        <div id="main-wrapper">                      
            <div class="site-wrapper">
                <div class="container">
                    <div class="propose-content mb-5" v-if="nftAdmin"> 
                        <form @submit.prevent="changeStage">
                            <div class="my-4" >
                                <h5><b>Change Minting Stage</b></h5>
                            </div>       
                            <div class="mb-3">
                                <div class="d-flex"><div class="me-5">Current stage:</div><b>{{currentStage}}</b></div>
                            </div>                     
                            <div class="chg-stage-wrap" >
                                <div class="d-flex flex-wrap">
                                    <div class="me-4" style="display: flex; align-items: center;">
                                        Change stage to:
                                    </div>
                                    <select class="form-select me-4" aria-label="Default select example" style="width:180px;" v-model="changeStageTo">
                                        <option selected value="">Select stage</option>
                                        <option value="0">Pre-Sales stage</option>
                                        <option value="1">Whitelist stage</option>
                                        <option value="2">Public stage</option>
                                    </select>
                                </div>
                                <div v-if="changeStageTo !='' && changeStageTo != stageNow">
                                    <input type="submit" value="CHANGE" class="preBtn activeBtn"/> 
                                </div>
                                <div v-else>
                                    <input type="submit" value="CHANGE" class="preBtn activeBtn disabled" disabled/>
                                </div>
                            </div>
                        </form>
                        <form @submit.prevent="whitelistAdd" class="mt-4">
                            <div class="my-4" >
                                <h5><b>Whitelist Address</b></h5>
                                <textarea v-model="whitelistAddress" id="whitelistAdd" class="form-control" aria-label="With textarea" placeholder="Enter whitelist address here" style="resize: none; height:40px; width:100%;" maxlength="150" required></textarea>
                            </div>                            
                            <div class="wl-btn-wrap" >
                                <select class="form-select mb-4 me-4" aria-label="Default select example" style="width:180px;" v-model="wlSelectStage">
                                    <option selected value="">Select stage</option>
                                    <option value="0">Pre-Sales stage</option>
                                    <option value="1">Whitelist stage</option>
                                </select>
                                <div v-if="wlSelectStage !=''">
                                    <input type="submit" value="ADD ADDRESS" class="preBtn activeBtn"/> 
                                </div>
                                <div v-else>
                                    <input type="submit" value="ADD ADDRESS" class="preBtn activeBtn disabled" disabled/>
                                </div>
                            </div>
                        </form>   
                        <!-- <form @submit.prevent="testing">
                            <div>
                                <h5>test hash</h5>
                                <textarea v-model="hashTest" id="title" class="form-control" aria-label="With textarea" placeholder="Enter your hash here" style="resize: none; height:40px; width:100%;" maxlength="150" required></textarea>
                            </div>
                            <div class="btn-wrap" >
                                <input type="submit" value="SUBMIT PROPOSAL" class="preBtn activeBtn"/>     
                            </div>
                        </form>      -->
                    </div>
                    <div class=" propose-publish mt-5">
                        <h5><b>Publish Proposal</b></h5>
                        <div class="support" v-for="proposal in proposalData" :key="proposal.id">
                            <div style="width:70%;">
                                <div>
                                    <span>POSTED {{((String(new Date(proposal.createdAt))).substring(0,21)).toUpperCase()}} | POLL ID {{("00"+ proposal.id).slice(-3)}}</span>
                                </div>
                                <div class="bot-wallet-address my-2">
                                    <h6><b>{{proposal.proposalTitle}}</b></h6>
                                </div>
                                <div v-if="((proposal.proposalDesc).length)<180" style="min-height:50px">
                                    <p>{{(proposal.proposalDesc).substr(0, 95)}}</p>
                                </div>
                                <div v-else style="min-height:50px">
                                    <p>{{(proposal.proposalDesc).substr(0, 95) + "...."}}</p>                        
                                </div>
                            </div>
                            <div style="text-align:right; display:flex; align-items: center;">
                                <div>
                                    <button class="motion-btn activeBtn" @click="submit(proposal.proposalTitle)"><b style="font-size:13px;">PUBLISH</b></button>                       
                                </div>
                            </div>                
                        </div>
                    </div>
                </div>
            </div>   
        </div>  
        
        <!-- Modal -->
        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">                    
                    <div class="modal-body">
                        <video width="100%" controls loop>
                            <source src="../img/LS_Guide.mp4" type="video/mp4"> 
                        </video>
                    </div>                    
                </div>
            </div>
        </div>
        
        
    </div>

</template>

<script>
import axios from "axios";
import daoABI from "/src/ABI/dao.json";
import nftABI from "/src/ABI/mint.json";
import {ethers} from "ethers";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";

const providerWC = new WalletConnectProvider({
    rpc: {
    //   56:"https://bsc-dataseed.binance.org/"
        137:"https://polygon-rpc.com/"
    },
    qrcodeModalOptions: {
      mobileLinks: [
        "rainbow",
        "metamask",
        "argent",
        "trust",
        "imtoken",
        "pillar",
      ]
    }
});


export default {
    name: 'propose',
    data(){
        return{
            daoAddress: process.env.VUE_APP_DAO_ADDRESS,
            nftAddress: process.env.VUE_APP_MINT_ADDRESS,
            daoAbi: daoABI,
            nftAbi: nftABI,
            title:"",
            balance:"",
            calldata: "",
            target: "",
            value: "",
            hashTest: "",
            proposalData:  [],
            nftAdmin: false,
            whitelistAddress: "",    
            wlSelectStage: "",
            changeStageTo: "",
            currentStage: "",
            stageNow: "",
            providerType:"",

        }
    },

    async mounted() {
        if(sessionStorage.connectType == "WC"){
            console.log('testing');
            console.log(providerWC);
            await providerWC.enable();
            this.providerType = providerWC;
        }else{
            this.providerType = window.ethereum;
        }

        // this.checkRole();
        this.getEncode();
        this.getProposal();
        this.metaMask();
        this.checkNFTOwner();
    },

    methods: {
        
        // sleep func
        sleep(ms) {
            return new Promise((resolve) => {
                setTimeout(resolve, ms);
            });
        },
        submit(proposalTitle){
            let title = proposalTitle;
            let provider = new ethers.providers.Web3Provider(this.providerType)             
            provider.send("eth_requestAccounts", []);
            this.providerType.request({method: 'eth_requestAccounts'})
            .catch((error) => {
                console.log(error); 
            })
            .then(async () => {
                const signer = provider.getSigner()  
                try{
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'Please Wait !',
                        html: 'Your Proposal is in the midst of being published.  Do not refresh or close this page.<br/> (Check Metamask confirmation pop-up window.)',
                        background: "#FDF0E9",       
                        allowOutsideClick: false,
                        didOpen: () => {
                            this.$swal.showLoading()
                        },
                    });

                    // console.log(title, this.calldata, this.target, this.value)
                    const contract = new ethers.Contract(this.daoAddress, this.daoAbi, signer);
                    // console.log(contract)
                    let propose = await contract["propose(address[],uint256[],bytes[],string)"](this.target,this.value,this.calldata,title)
                    await propose.wait(1)
                    // console.log(propose)
                    // console.log(propose.hash)
                    this.hashTest = propose.hash
                    // console.log('1')                    
                    await this.sleep(5000)
                    // console.log('2')
                    await axios.post('https://api.langkahsheraton.com/api/dao/proposeDAO', {
                        trxHash: propose.hash,
                    },
                    { headers: {'Authorization' : `Bearer ${sessionStorage.bt}`} }
                    ).then((response)=>{
                        // console.log(response)
                        if (response.statusText == "Created"){
                            this.$swal.close()
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Your proposal has been published.',                                       
                                confirmButtonText: 'OK',                       
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.replace("/admin");
                                }
                                if (result.isDismissed) {
                                    window.location.replace("/admin");
                                }
                            });
                        }
                    }) 

                }catch(e){
                    // console.log(e.message)
                    this.$swal.close()
                    this.$swal.fire({
                    icon: "error",
                    title: 'Publish Failed',
                    html: 'The Proposal failed to publish. Please try again.',
                    confirmButtonText: 'CONTINUE',
                    background: "#FDF0E9",                       
                    }).then((result) => {
                        if (result.isConfirmed) {
                        window.location.reload()
                        }
                        if (result.isDismissed) { 
                        window.location.reload()                  
                        }
                    });
                }
            })
        },
        getEncode(){
            axios.get(            
            'https://api.langkahsheraton.com/api/dao/getEncodeFunction'
            ).then((response)=>{
                // console.log(response)
                // console.log(response.data)
                this.calldata = response.data.calldata
                this.target = response.data.target
                this.value = response.data.value
            })
        },
        async metaMask(){
            if(sessionStorage.connect != "true"){
                this.$swal.fire({
                    icon: 'error',
                    title: 'MetaMask not connected',
                    text: 'Please connect to your Metamask wallet.',
                    confirmButtonText: 'Return',
                    allowOutsideClick: false,                        
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/home");
                    }
                });
            }            
        },   
        getProposal(){
            axios.get(            
            'https://api.langkahsheraton.com/api/proposal/getAllProposals', {
                params: {
                    address: this.userAddress,
                }
            })
            .then((response) => {
                let pendingData = response.data.data                
                // this.daoDataLength = this.daoData.length;
                // this.totalDaoRows = response.data.count
                for (let i = 0; i < pendingData.length; i++){ 
                    if(pendingData[i].preproposal_status == "Approve"){
                        this.proposalData.push(pendingData[i])
                    }                    
                }
                // console.log(this.proposalData)
            })
            .catch(error =>{
                console.log(error)
            });
        },
        checkRole(){
            let provider = new ethers.providers.Web3Provider(this.providerType)
            provider.send("eth_requestAccounts", []);
            this.providerType.request({method: 'eth_requestAccounts'})
            .catch((error) => {
                console.log(error); 
            })
            .then(async () => {
                const signer = provider.getSigner()
                const address = await signer.getAddress()
                // const address = window.ethereum.selectedAddress;
                try{
                    const contract = new ethers.Contract(this.daoAddress, this.daoAbi, signer);
                    let result = await contract.secretory(address);
                    let owner = await contract.owner();
                    // console.log(result)
                    // console.log(typeof(owner))
                    // console.log(typeof(address))
                    
                    if(result != true && address != owner.toString()){
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Access blocked',
                            text: "You do not have the permission to access this page.",
                            confirmButtonText: 'Return',
                            allowOutsideClick: false,                        
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.replace("/home");
                            }
                        });
                    }
                }catch(e){
                    console.log(e.message)
                }
            })
        },
        checkNFTOwner(){
            // console.log('check owner')
            let provider = new ethers.providers.Web3Provider(this.providerType)
            provider.send("eth_requestAccounts", []);
            this.providerType.request({method: 'eth_requestAccounts'})
            .catch((error) => {
                console.log(error); 
            })
            .then(async () => {
                // console.log('get owner')
                const signer = provider.getSigner()
                const address = await signer.getAddress()
                // const address = window.ethereum.selectedAddress;
                // console.log(signer)
                // console.log(address)
                try{
                    const contract = new ethers.Contract(this.nftAddress, this.nftAbi, signer);
                    // console.log(contract)
                    let owner = await contract.owner();
                    let admin = await contract.secretory(address);
                    // console.log(owner)
                    if(address == owner || admin == true){
                        this.nftAdmin = true;
                        // console.log(this.nftAdmin)
                        this.stageNow = await contract.stageNow();
                        if(this.stageNow == 0){
                            this.currentStage = "Pre-Sales"
                        }else if(this.stageNow == 1){
                            this.currentStage = "Whitelist"
                        }else{
                            this.currentStage = "Public"
                        }
                    }
                }catch(e){
                    console.log(e.message)
                }
            })
        },
        whitelistAdd(){
            if(Web3.utils.isAddress(this.whitelistAddress) == true){
                // console.log(this.wlSelectStage)
                let provider = new ethers.providers.Web3Provider(this.providerType);
                provider.send("eth_requestAccounts", []);
                this.providerType.request({method: 'eth_requestAccounts'})
                .catch((error) => {
                    console.log(error); 
                })
                .then(async () => {
                    const signer = provider.getSigner()  
                    try{
                        this.$swal.fire({
                            icon: 'warning',
                            title: 'Please Wait !',
                            html: 'Whitelisting in progress, do not refresh/close page....<br/> (check MetaMask comfirmation pop-up window)',
                            background: "#FDF0E9",       
                            allowOutsideClick: false,
                            didOpen: () => {
                                this.$swal.showLoading()
                            },
                        });

                        // console.log(this.whitelistAddress)
                        const contract = new ethers.Contract(this.nftAddress, this.nftAbi, signer);
                        // console.log(contract)
                        let propose

                        if(this.wlSelectStage == "0"){
                            
                            propose = await contract.updatePresales(this.whitelistAddress)
                            await propose.wait(1)
                            // console.log(propose)
                            // console.log(propose.hash)
                            if(propose.hash){
                                this.$swal.close()
                                this.$swal.fire({
                                    icon: 'success',
                                    title: 'Address Whitelisted',                                       
                                    confirmButtonText: 'OK',                       
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        window.location.replace("/admin");
                                    }
                                    if (result.isDismissed) {
                                        window.location.replace("/admin");
                                    }
                                });
                            }
                        }else if(this.wlSelectStage == "1"){
                            propose = await contract.updateWhitelist(this.whitelistAddress)
                            await propose.wait(1)
                            // console.log(propose)
                            // console.log(propose.hash)
                            if(propose.hash){
                                this.$swal.close()
                                this.$swal.fire({
                                    icon: 'success',
                                    title: 'Address Whitelisted',                                       
                                    confirmButtonText: 'OK',                       
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        window.location.replace("/admin");
                                    }
                                    if (result.isDismissed) {
                                        window.location.replace("/admin");
                                    }
                                });
                            }
                        }

                    }catch(e){
                        console.log(e.message)
                        this.$swal.close()
                        this.$swal.fire({
                        icon: "error",
                        title: 'Whitelist Failed',
                        html: 'The Proposal failed to publish. Please try again.',
                        confirmButtonText: 'CONTINUE',
                        background: "#FDF0E9",                       
                        }).then((result) => {
                            if (result.isConfirmed) {
                            window.location.reload()
                            }
                            if (result.isDismissed) { 
                            window.location.reload()                  
                            }
                        });
                    }
                })
            }else{
                this.$swal.fire({
                    icon: 'error',
                    title: 'Invalid wallet address',
                    confirmButtonText: 'return',                    
                })
            }            
        },
        changeStage(){
            let provider = new ethers.providers.Web3Provider(this.providerType);
            provider.send("eth_requestAccounts", []);
            this.providerType.request({method: 'eth_requestAccounts'})
            .catch((error) => {
                console.log(error); 
            })
            .then(async () => {
                const signer = provider.getSigner()  
                try{
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'Please Wait !',
                        html: 'Changing minting stage, do not refresh/close page....<br/> (check MetaMask comfirmation pop-up window)',
                        background: "#FDF0E9",       
                        allowOutsideClick: false,
                        didOpen: () => {
                            this.$swal.showLoading()
                        },
                    });

                    // console.log(this.whitelistAddress)
                    const contract = new ethers.Contract(this.nftAddress, this.nftAbi, signer);
                    // console.log(contract)
                    let propose

                    if(this.stageNow != this.changeStageTo){
                        
                        propose = await contract.updateStage(this.changeStageTo)
                        await propose.wait(1)
                        // console.log(propose)
                        // console.log(propose.hash)
                        if(propose.hash){
                            this.$swal.close()
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Minting Stage Changed',                                       
                                confirmButtonText: 'OK',                       
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.replace("/admin");
                                }
                                if (result.isDismissed) {
                                    window.location.replace("/admin");
                                }
                            });
                        }
                    }else {
                        this.$swal.close()
                        this.$swal.fire({
                        icon: "error",
                        title: 'Change Stage Failed',
                        html: 'The Proposal failed to publish. Please try again.',
                        confirmButtonText: 'CONTINUE',
                        background: "#FDF0E9",                       
                        }).then((result) => {
                            if (result.isConfirmed) {
                            window.location.reload()
                            }
                            if (result.isDismissed) { 
                            window.location.reload()                  
                            }
                        });
                    }
                    

                }catch(e){
                    console.log(e.message)
                    this.$swal.close()
                    this.$swal.fire({
                    icon: "error",
                    title: 'Change Stage Failed',
                    html: 'The Proposal failed to publish. Please try again.',
                    confirmButtonText: 'CONTINUE',
                    background: "#FDF0E9",                       
                    }).then((result) => {
                        if (result.isConfirmed) {
                        window.location.reload()
                        }
                        if (result.isDismissed) { 
                        window.location.reload()                  
                        }
                    });
                }
            })
        }
    }
    
}
</script>


<style scoped>

    .container{
        display:flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .propose-content{
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        width:800px;
    }

    .propose-publish{
        display:flex;
        flex-wrap: wrap;
        width:800px;
    }

    .propose-content > form {
        width: 100%;
    }

    .btn-wrap{
        display: flex;
        justify-content: flex-end;
    }

    .wl-btn-wrap{
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }

    .chg-stage-wrap{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }

    @media screen and (max-width: 1024px){
        .propose-content, .propose-publish{
            width:100%;
        }    
        
        .btn-wrap{
            display: flex;
            justify-content: center;
        }   
        .chg-stage-wrap .form-select{
            margin-top: 10px;
            margin-bottom: 15px;
        } 
        .wl-btn-wrap{
            justify-content: flex-start;
        }
    }

    @media screen and (min-width: 1025px){
        .preBtn{
            float:right;
        }
    }



    

    /* body.swal2-shown > [aria-hidden="true"] {
        background-color: rgba(0,0,0,0.7);
        transition: 0.1s filter;
        filter: blur(10px);
    } */

    
</style>