<template >
    <div id="tnc">
        <div class="title">
            <div class="container">
                <div class="row title-div">
                    <div class="col-9">
                        <div class="text-center">
                            <h1>Terms and Conditions</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="title-bg"></div>
        <div class="row content-wrap">
            
            <div class="content">
                <img src="../img/tnc/tnc1.jpg" alt="">            
            
                <img src="../img/tnc/tnc2.jpg" alt="">             
            
                <img src="../img/tnc/tnc3.jpg" alt="">             
            
                <img src="../img/tnc/tnc4.jpg" alt="">             
            
                <img src="../img/tnc/tnc5.jpg" alt="">             
            
                <img src="../img/tnc/tnc6.jpg" alt="">             
            
                <img src="../img/tnc/tnc7.jpg" alt="">             
            
                <img src="../img/tnc/tnc8.jpg" alt="">             
            
                <img src="../img/tnc/tnc9.jpg" alt="">             
            
                <img src="../img/tnc/tnc10.jpg" alt="">
            </div>             
        </div>
    </div>
</template>

<script>

export default {

    async mounted () {
        window.scrollTo(0, 0)
    }
}
</script>

<style>
/* #dap .img-main img{
    max-width: 1167px;
} */
#tnc .content img{
    width: 100%;
}

</style>