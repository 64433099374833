<template >
    <div id="artist">
        <div class="title">
            <div class="container">
                <div class="row title-div">
                    <div class="col-10 d-flex justify-content-center flex-wrap">
                        <div class="col-12 text-center">
                            <!-- <h1>The <b>Artist</b></h1> -->
                            <h1>The Artist</h1>
                        </div>
                        <div class="mt-4 d-flex">
                            <div class="mx-3"><router-link to="/painting" >THE PAINTING</router-link></div>
                            <div class="mx-3"><router-link to="/artist" style="color:orange">THE ARTIST</router-link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="title-bg"></div>
        <div class="row content-wrap">
            <div class="img-main d-flex justify-content-center">
                <img src="../img/nft/tony_artwork.jpg" alt="">
                <div class="paint d-flex justify-content-center">
                    <img src="../img/oilpaint2.png" alt="">
                </div>
            </div>
            <div class="content">
                <p>Tony Pua is a 50-year-old over-achiever who has led a colourful career.</p>
                <p>He is a third term Member of Parliament for Damansara, which was won with the largest ever majority in Malaysian election history - 106,903 votes.  His popularity had increased throughout the decade, increasing from 67.9% to 81.8% to 89.0% of total votes cast over the 3 elections in 2008, 2013 and 2018.</p>

                <p>Tony has built a reputation as an intelligent, fearless and straight-talking politician, driven by a deep-rooted sense of justice and righteousness. He is unafraid to speak up against power abuse by the powers-that-be; neither is he frightened to voice his views on sensitive and taboo subjects, even if they prove to be politically unpopular.</p>

                <p>His star shone over his role in the explosive 1MDB scandal, which rocked globally renown financial institutions and was dubbed the single largest case of kleptocracy by the US Department of Justice in 2016.  Tony became the leading advocate in Malaysia, exposing the corruption and financial shenanigans puppeteered by the then Prime Minister, Najib Razak.</p>

                <p>In doing so, he faced multiple threats and political persecution, including countless trips to the police station for interrogations, and becoming the first Member of Parliament to be sued for defamation by the Prime Minister, twice(!)</p>

                <p>His perseverance and relentless pursuit to expose the truth against those who caused and abetted the RM50 billion scandal ultimately contributed to Najib Razak’s downfall and the collapse of the Barisan Nasional government.</p>
            </div>
            <content-pic img1='artist1.jpg' img2='artist2.jpg' img3='artist3.jpg' img4='artist4.jpg' img5='artist5.jpg'></content-pic>
            <div class="content">
                <p>Tony has featured prominently in multiple international documentaries over the issue, including  <a href="https://www.facebook.com/thekleptocrats/" target="_blank"><b>‘The Kleptocrats’</b></a>, <a href="https://www.youtube.com/watch?v=p4lj6lC4lrk" target="_blank"><b>Al-Jazeera’s ‘Jho Low: Hunt for a Fugitive’</b></a>, BBC’s <a href="https://www.dailymotion.com/video/x6dj2w4" target="_blank">‘<b>House of Saud</b>’</a> and Netflix’s <b>Dirty Money ‘The Man at the Top’</b>.  He has even produced his own satirical videos - <a href="https://www.facebook.com/DAPMalaysia/videos/10156260562594192/" target="_blank"> <b>‘The 1MDB Chronicles’</b> </a> , to explain the scandal in the simplest terms to the general populace.</p>

                <p>Tony is also the author of <a href="https://store.ubah.my/phone/shop.html?fbclid=IwAR3mhKfFH18p_ScO7JLYLUsGvU22bp3ZGwLMcmkISDw16KdCQyG68LGW5ss#!/The-Tiger-That-Lost-Its-Roar-A-Tale-of-Malaysias-Political-Economy/p/88318488/category=24794853" target="_blank"><b>‘The Tiger That Lost Its Roar’</b></a>, a book exploring the underlying malaise afflicting Malaysia’s political economy.  He explained why, after initial decades of rapid economic growth, the country has since struggled for impetus and direction, failing to meet its obvious potentials.</p>

                <p>Prior to joining politics full-time in 2007, Tony was the CEO of an e-business consulting company he founded in 1997 in Malaysia, which was subsequently listed on the Singapore Exchange in 2001.  At the age of 29, he became the then youngest chief executive of a Singapore listed entity.</p>

                <p>Having accumulated sufficient wealth to take care of a rainy day, he disposed of his entire stake in the company in 2006 to pursue his passion in politics, as a means of giving back to society.  He is eternally grateful for the opportunities he had received – scholarships to Raffles Institution in Singapore, and to Oxford University to read Philosophy, Politics and Economics – for the best education one could ever ask for, having been born to a modest farming family in the rural outskirts of Batu Pahat, Johor.</p>

                <p>Drawing and painting have been Tony’s first love, since before he even started school. He excelled in the subject and was the go-to person for all art-related projects in school all the way to ‘A’-Levels.  He has often publicly stated that he looks forward to returning to his roots after his second retirement to enjoy his days as a full-time artist.</p>

                <p>‘Langkah Sheraton’ is his first major endeavour in decades. With his raw talent, matched with an inimitable experience and achievements in the corporate and political scene, Tony will certainly be producing some of the most intimate, thought-provoking and passionate paintings of exceptional quality in the coming years.</p>
                
            </div>
        </div>
    </div>
</template>

<script>
import contentPic from "../components/contentPic.vue"

export default {
    components:{contentPic},

    async mounted () {
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
.content > p > a > b{
    color:#9013FE !important;
}
.content > p > b{
    color:#9013FE !important;
}

.content a:hover{
    text-decoration: underline !important;
    color:#9013FE
}
.img-main img{
    max-width: 1167px;
}
</style>