<template >
    <div id="guide">
        <div class="title">
            <div class="container">
                <div class="row title-div">
                    <div class="col-9">
                        <div class="text-center">
                            <h1>FAQ</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="title-bg"></div>
        <div class="row d-flex justify-content-center">
            <!-- <div class="img-main d-flex justify-content-center">
                <img src="../img/dap.png" alt="">
                <div class="paint d-flex justify-content-center">
                    <img src="../img/oilpaint1.png" alt="">
                </div>
            </div> -->
            <div class="content d-flex flex-wrap">
                <div class="con-sec-inner justify-content-center">                        
                    <div style="width:90%" >
                        <div class="d-flex justify-content-center flex-wrap" >
                            <button type="button" class="collapsible"><h5>1. What's an NFT?</h5><img src="../img/button.png" alt=""></button>
                            <div class="desc">
                                <p>
                                    NFT stands for “Non-fungible token” – it is a unique token that exists on a blockchain and cannot be replicated.
                                    <br/><br/>
                                    NFTs can be used to digitally represent real-world items like physical artwork and real-estate. "Tokenizing" these real-world tangible assets allows them to be bought, sold and traded more efficiently while reducing the probability of fraud.
                                    <br/><br/>
                                    Some NFT’s main function are purely in the form of digital art and their value lie entirely in aesthetics of the digital items.
                                    <br/><br/>
                                    There are many NFT cryptocurrency platforms (e.g., Ethereum, Binance, Solana). Langkah Sheraton NFTs will be based on Polygon ($MATIC), a decentralised Ethereum scaling platform that enables low transaction fees without sacrificing security. 
                                    <br/><br/>
                                    Ethereum is the current market leader for NFT platforms and is the platform of choice for the largest NFT marketplaces such as <a href="OpenSea.io" target="_blank">OpenSea.io</a> and <a href="Rarible.com" target="_blank">Rarible.com</a>.
                                </p>
                            </div>
                            <button type="button" class="collapsible"><h5>2. Why mint or purchase the Langkah Sheraton NFT?</h5><img src="../img/button.png" alt=""></button>
                            <div class="desc">
                                <p>
                                    The Langkah Sheration NFT project is a novel and innovative venture to enable thousands of fans to own a piece of critically-acclaimed artwork which recorded a pivotal moment in Malaysian political history.  The painting records moments leading to the notorious ‘Sheraton Move’ as key political players manoeuvred and out-manoeuvred each other in the attempt to secure majority control of the Malaysian Parliament. 
                                    <br/><br/>
                                    All proceeds will go towards the Democratic Action Party (DAP) Malaysia’s election campaign fund, to revive democracy and redeem the people's mandate in the next General Election expected by 2023.
                                </p>
                            </div> 
                            <button type="button" class="collapsible"><h5>3. How do I mint an NFT from the LangkahSheraton.com website? </h5><img src="../img/button.png" alt=""></button>
                            <div class="desc">
                                <p>
                                    <ol>
                                        <li>Simply connect your <b>Metamask wallet</b>  </li>
                                        <li>Ensure that you have <b>sufficient $MATIC balance</b> </li>
                                        <li>Select quantity of NFTs to mint and click the Mint button on the <a href="https://langkahsheraton.com/mintnft" target="_blank"><b>minting page</b></a>.</li>
                                    </ol>
                                </p>
                            </div> 
                            <button type="button" class="collapsible"><h5>4. What is MetaMask?</h5><img src="../img/button.png" alt=""></button>
                            <div class="desc">
                                <p>
                                    MetaMask is a cryptocurrency wallet that can store your compatible digital tokens. and It is needed to mint and purchase your NFTs. 
                                    <br/><br/>
                                    Having a wallet gives you an Ethereum address where your cryptocurrency, tokens and NFTs will be digitally stored. Learn more about MetaMask <a href="https://metamask.io" target="_blank"><b>here</b></a>.
                                </p>
                            </div>
                            <button type="button" class="collapsible"><h5>5. How much does it cost to mint Langkah Sheraton NFTs?</h5><img src="../img/button.png" alt=""></button>
                            <div class="desc">
                                <p>
                                    The Langkah Sheraton NFTs will mint for <b>[45 $MATIC] each after its official launch</b> on [31 August 2022].  
                                    <br/><br/>
                                    You can mint up to 250 NFTs (250 x 45 $MATIC) for every transaction.  There will be a minimal additional ‘gas fee’ charge for every transaction.
                                </p>
                            </div> 
                            <button type="button" class="collapsible"><h5>6. What are ‘gas fees’?</h5><img src="../img/button.png" alt=""></button>
                            <div class="desc">
                                <p>
                                    Every transaction carried out on cryptocurrency blockchains requires a transaction fee or better known as the ‘gas fee’.  This fee is payable to the crypto-mining community (miners) that processes the transaction, and ensures the platforms security and authenticity. 
                                    <br/><br/>
                                    These ‘gas fees’ vary in cost, depending on the choice of blockchain platform.  Hence, we have chosen Polygon, a decentralised Ethereum-scaling platform to minimise ‘gas fees’.
                                </p>
                            </div> 
                            <button type="button" class="collapsible"><h5>7. How to purchase Polygon $MATICs currency?</h5><img src="../img/button.png" alt=""></button>
                            <div class="desc">
                                <p>
                                    There are 2 ways to acquire $MATIC tokens.
                                    <ol>
                                        <li>If you already have Ethereum (ETH) in your Metamask wallet, you could bridge your ETH to $MATIC by logging in to the Polygon Web Wallet (within the Metamask wallet).
                                            <br/><br/>
                                            Details on how to do this via how-to write ups online such as <a href="https://cointelegraph.com/news/how-to-bridge-tokens-from-other-chains-to-the-polygon-network" target="_blank">https://cointelegraph.com/news/how-to-bridge-tokens-from-other-chains-to-the-polygon-network</a>, or via Youtube videos such as <a href="https://youtu.be/5EQFsxH9zFw" target="_blank">https://youtu.be/5EQFsxH9zFw</a>
                                        </li><br/>
                                        <li>
                                            You can also purchase $MATICs directly with fiat currency via decentralised exchanges such as Coinbase, Kraken or Crypto.com.
                                            <br/><br/>
                                            You can find how-to write ups online such as <a href="https://www.forbes.com/advisor/investing/cryptocurrency/how-to-buy-polygon-matic/" target="_blank">https://www.forbes.com/advisor/investing/cryptocurrency/how-to-buy-polygon-matic/</a>.
                                        </li>
                                    </ol>
                                </p>
                            </div> 
                            <button type="button" class="collapsible"><h5>8. Is there a limit on how many NFTs I can mint?</h5><img src="../img/button.png" alt=""></button>
                            <div class="desc">
                                <p>
                                    There’s no limit on the number of NFTs you can mint.  
                                    <br/><br/>
                                    However, to ensure the stability and security of the transactions, we have limited it to a maximum of 250 NFTs in a single transaction.  You can carry out multiple transactions.

                                </p>
                            </div> 
                            <button type="button" class="collapsible"><h5>9. Where can I check the history of an NFT?</h5><img src="../img/button.png" alt=""></button>
                            <div class="desc">
                                <p>
                                    Once you successfully purchase an NFT, you will get a unique link for the NFT that being listed in OpenSea.io.
                                    <br/><br/>
                                    Clicking in will reveal important information about the NFT, such as:<br/>
                                    <ul>
                                        <li>Contract address of the collection.</li>
                                        <li>Token ID of this particular NFT.</li>
                                        <li>Blockchain the NFT resides on, eg: Ethereum, Polygon etc.</li>
                                    </ul>
                                    <!-- After minting your NFTs on LangkahSheraton.com, your ownership of the unique NFTs will be ‘registered’ in the Ethereum blockchain.
                                    <br/><br/>
                                    The NFT will then be made available on global NFT marketplaces, such as OpenSea.io to be traded. 
                                    <br/><br/>
                                    (OpenSea.io may need few minutes to update your purchased NFTs. You can refresh the listing after few minutes.) -->

                                </p>
                            </div> 
                            <button type="button" class="collapsible"><h5>10. How do I trade the NFT?</h5><img src="../img/button.png" alt=""></button>
                            <div class="desc">
                                <p>
                                    You can trade the NFT on major global NFT marketplaces such as <b>Opensea.io</b> or <b>Rarible.com</b>.  
                                    <br/><br/>
                                    Once the 8,888 NFTs are fully-minted and no longer available on LangkahSheraton.com; or if you have a particular NFT design which you specifically desire, you can purchase the NFT(s) from Opensea.io.
                                    <br/><br/>
                                    You can visit the Langkah Sheraton page on OpenSea <a href="https://opensea.io/collection/langkah-sheraton" target="_blank"><b>here</b></a>. 

                                </p>
                            </div> 
                            <button type="button" class="collapsible"><h5>11. Does DAP benefit when the NFTs are traded in the secondary marketplaces?</h5><img src="../img/button.png" alt=""></button>
                            <div class="desc">
                                <p>
                                    Yes! Every time the Langkah Sheraton NFTs are traded in these marketplaces, such as Opensea.io, an 8 % of the transacted price is paid as ‘royalty’.  The royalty will be payable to:
                                    <ol>
                                        <li>DAP Malaysia campaign fund (4%)</li>
                                        <li>The Artist to create future works (2.5%)</li>
                                        <li>The Decentralised Autonomous Organisation (DAO) for the purposes of managing and maintaining the physical Painting. (1.5%)</li>
                                    </ol>
                                </p>
                            </div> 
                            <button type="button" class="collapsible"><h5>12. How do I get an Ultra Rare NFT?</h5><img src="../img/button.png" alt=""></button>
                            <div class="desc">
                                <p>
                                    All NFTs are minted and allocated by random.  We have 6,000 Original, 2,400 Limited, 400 Rare and 88 Ultra-Rare NFTs.
                                    <br/><br/>
                                    However, for every 100 NFTs minted, the purchaser will have a guaranteed (randomly generated) Ultra Rare NFT. There is no limit for the individual minting volume so take the opportunity fast. 
                                </p>
                            </div> 
                            <button type="button" class="collapsible"><h5>13. When can I start minting Langkah Sheraton NFTs?</h5><img src="../img/button.png" alt=""></button>
                            <div class="desc">
                                <p>
                                    There will 3 phases of Langkah Sheration NFT launch.<br/><br/>
                                    <ol>
                                        <li>There will be a <b>Private Sale</b> for cornerstone participants with a minimum purchase 300 NFTs at an special price of 35 $MATIC for each NFT.  There is a maximum of 4,500 NFTs allocated for cornerstone participants.  Those interested in becoming cornerstone participants are welcome to email <b>langkahsheraton@dapmalaysia.org</b> with your interest level and contact details.</li>
                                        <br/>
                                        <li>After the soft launch of the Langkah Sheraton NFT project on [1 August 2022], interested participants can get ‘whitelisted’ to participate in the <b>Pre-Launch Sale</b> of the Langkah Sheraton NFTs.  Those in the ‘whitelist’ will qualify to mint the NFTs at 40 $MATIC each.  They must mint a minimum of 20 NFTs, up to a maximum of 100 NFTs.
                                            <br/><br/>
                                            To join the whitelist, head over to our <a href="https://discord.gg/Kq3CXUY23j" target="_blank"><b>Discord server</b></a> and join our community. Engage and have fun with our community to secure a ticket. Once you receive a ticket, you'll be automatically eligible for the Pre-Launch Sale. Only a maximum of 2,500 NFTs are allocated for the ‘whitelist’, so act fast!
                                        </li><br/>
                                        <li>The Langkah Sheraton NFT Project will be <b>officially launched on 31 Aug 2022</b> , in conjunction with Malaysia’s National Day. The NFTs will be available for minting by everyone at <b>45 $MATIC</b> each.  The NFTs will then be available for trade on OpenSea.io.</li>
                                    </ol>
                                </p>
                            </div> 
                            <button type="button" class="collapsible"><h5>14. How can I reach Langkah Sheraton support?</h5><img src="../img/button.png" alt=""></button>
                            <div class="desc">
                                <p>
                                    You can raise your queries concern at our Discord Community. We are happy to assist. <a href="https://discord.gg/Kq3CXUY23j" target="_blank"><b>Discord link</b></a>
                                </p>
                            </div> 
                            <button type="button" class="collapsible"><h5>15. What are my privileges as a Langkah Sheraton NFT owner?</h5><img src="../img/button.png" alt=""></button>
                            <div class="desc last-desc">
                                <p>
                                    As an owner of the Langkah Sheraton NFT, you are a legal fractional owner of the Langkah Sheration painting.  The terms and conditions of your ownership is enshrined in the legal contract document which can be downloaded <a href="/files/03D - NFT Sale Agreement.pdf" download><b>here</b></a>.
                                    <br/><br/>
                                    All Langkah Sheraton NFT owners may participate in a virtual joint management body known as the ‘Decentralised Autonomous Organisation’ or DAO.
                                    <br/><br/>
                                    The DAO will be led by a Board of Trustees (BoT) comprising of 5 members. 3 of these members will be selected from the Top-3 Langkah Sheraton NFT owners, renewed on a monthly basis.  The remaining 2 members will be elected by the remainder of the NFT owners (excluding the Top-3) on an annual basis.  
                                    <br/><br/>
                                    The BoT will carry out administrative functions, as well as decisions (proposals) approved by the DAO, with the assistance of an appointed Secretariat.  Such duties will include maintenance, storage, insurance or even the sale of the physical painting, if so decided by the DAO.
                                    <br/><br/>
                                    The DAO is autonomous and transparent, programmed based on smart contracts. Qualified NFT owners will have the right to submit proposals for consideration and voting by all NFT owners.
                                    <br/><br/>                                 
                                    The detailed guidelines and processes of the DAO is available <a href="/polling" target="_blank"><b>here</b></a>.
                                </p>
                            </div>  
                        </div>               
                    </div>
                </div>        
            </div>
        </div>
    </div>
</template>

<script>
// import HelloWorldVue from '../components/HelloWorld.vue';


export default {
    // components:{HelloWorldVue},

    mounted: async function () {
       
        window.scrollTo(0, 0)
    
        var coll = document.getElementsByClassName("collapsible");
        var i;

        for (i = 0; i < coll.length; i++) {
            coll[i].addEventListener("click", function() {
                this.classList.toggle("active");
                var content = this.nextElementSibling;
                if (content.style.maxHeight){
                content.style.maxHeight = null;
                } else {
                content.style.maxHeight = content.scrollHeight + "px";
                } 
            });
        }

     
        
    }
}
</script>

<style scoped>

.content{
    width:1300px;
    margin-top: 2rem;
}

.con-sec{
    display: flex;
    flex-wrap: wrap;
}

.con-sec-inner{
    display: flex;
    flex-wrap: wrap;
    /* border-bottom: 1px solid #F3D1BF; */
    /* padding: 100px 0 100px 0; */
    width: 100%;
}

@media screen and (min-width:769px){
    .con-sec{
        padding-left:90px;
    }
    /* .con-sec-inner .con-left{
        width: 45%;
    }
    .con-sec-inner .con-right{
        width: 55%;
    } */
}

@media screen and (max-width:768px) {
    .con-sec{
        padding-left: 5%;
        padding-right: 5%;
    }
    .con-left{
        padding-bottom: 20px;
    }
    .collapsible{
        height:auto !important;
    }
    
    #faq-general .con-sec-inner{
        padding-top: 0px !important;
    }
}


.collapsible {
    transition: 0.4s ease-out;
    border: solid 1px;
    border-color: #F3D1BF !important;
    background-color: transparent;
    /* color: white; */
    cursor: pointer;
    padding: 18px;
    width: 100%;
    height: 120px;
    text-align: left;
    outline: none;
    font-size: 15px;
    padding-left: 35px;
    padding-top: 28px;
    border-bottom: transparent !important;
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.button-content{
    width: inherit;
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.last-desc {
    border-bottom: solid 1px !important;
    /* border-bottom: solid 1px !important; */

}

.desc {
    width: 100%;
    padding: 0 35px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #ffffff;
    border: solid 1px;
    border-color: #F3D1BF !important;
    border-top: transparent ;
    border-bottom: transparent;

}
.desc > p {
    padding-bottom: 10px;
}

.active {
    background-color: white;
}

.active > img{
    transform: rotate(180deg);
}

.content-desc{
    margin-top: 180px;
}

#guide a:hover{
    text-decoration: underline;
}

#guide a{
    color: black;
}

</style>
