<template>
    <div class="contentPic">
        <div class="left-content">
            <img :src="require('../img/'+(img1))" alt="">
        </div>
        <div class="right-content">
            <div class="right-content-top">
                <img :src="require('../img/'+(img2))" alt="">
                <img :src="require('../img/'+(img3))" alt="">
            </div>
            <div class="right-content-bot">
                <img :src="require('../img/'+(img4))" alt="">
                <img :src="require('../img/'+(img5))" alt="">
            </div>
        </div>
    </div>  
</template>

<script>
export default {
    name: 'contentPic',
    props: ['img1', 'img2', 'img3', 'img4', 'img5']
}
</script>

<style scoped>

@media screen and (min-width: 1025px){
    .contentPic{
        width:80%;
        display:flex;
        margin: 3rem 0;
        max-width: 1170px;
    }
    .contentPic .left-content{
        width:50%;
        text-align: center;
        display: flex
    }
    .contentPic .right-content{
        width:50%;
        text-align: center;    
    }
    .contentPic .left-content img{
        width:96%;
    }
    .contentPic .right-content img:first-child{
        width:45%;
        margin: 0 15px 0 0;
    }
    .contentPic .right-content img:last-child{
        width:45%;
        margin: 0 0 0 15px;
    }
    .contentPic .right-content .right-content-top{   
        margin: 0 0 30px 0;
    }
}

/* @media screen and (max-width: 768px){ */
@media screen and (max-width: 1024px){
    .contentPic{
        /* width:95%; */
        display:flex;
        margin: 3rem 0;
        flex-wrap: wrap;
    }
    .contentPic .left-content{
        /* width:50%; */
        text-align: center
    }
    .contentPic .right-content{
        /* width:50%; */
        text-align: center; 
        margin-top: 20px !important;   
    }
    .contentPic .left-content img{
        width:100%;
    }
    .contentPic .right-content img:first-child{
        width:45%;
        margin: 0 10px 0 0;
    }
    .contentPic .right-content img:last-child{
        width:45%;
        margin: 0 0 0 10px;
    }
    .contentPic .right-content .right-content-top{   
        margin: 0 0 20px 0;
    }
}


</style>