<template>
<div id="mint">
    <div class="title">
        <div class="container">
            <div class="row title-div">
                <div class="col-9">
                    <div class="text-center">
                        <h1><b>NFT</b> Presales</h1>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <div class="title-bg"></div>
    <div id="main-wrapper">
        <div class="site-wrapper">
            <div class="container">
                <div class="row mt-5">
                    <div class="col-12 ">
                        <div class="d-flex justify-content-center">
                            <h3>Presales Minting <i style="font-size:18px;">(250 NFTs)</i></h3>
                            <button type="button" class="ms-5 btn btn-light mint-btn" @click="mint">Mint</button>
                        </div>
                        <div class="nft-filter gap-4 my-5" > 
                            <a href="" type="button"><h4>ULTRA RARE</h4></a>
                            <a href="" type="button"><h4>SUPER RARE</h4></a>
                            <a href="" type="button"><h4>RARE</h4></a>                                    
                            <a href="" type="button"><h4>ORIGIN</h4></a>
                        </div>
                        <div class="row">
                            <div class="col-md-4 d-flex justify-content-center my-3" v-for="nft in nfts" :key="nft.title"> 
                                <div class="nft-box d-flex align-items-center justify-content-center">
                                    <div class="nft-img">
                                        <img :src="nft.img" alt="">
                                    </div>
                                    <div class="nft-title">
                                        <button type="button" class="btn btn-light mint-btn">Mint</button>
                                        <h2>{{nft.title}}</h2>
                                    </div> 
                                </div>
                            </div>  
                        </div>
                        <div class="nft-filter gap-3 my-5"> 
                            <a href="" class="btn" ><h6>1</h6></a>
                            <a href="" class="btn" ><h6>2</h6></a>
                            <a href="" class="btn" ><h6>3</h6></a>                                    
                            <a href="" class="btn" ><h6>4</h6></a>
                            <a href="" class="btn" ><h6>5</h6></a>
                            <a href="" class="btn" ><h6>6</h6></a>
                            <a href="" class="btn" ><h6>7</h6></a>                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>
</template>

<script>
import {ethers} from "ethers";
import ABI from "/src/ABI/mint.json";
import WalletConnectProvider from "@walletconnect/web3-provider";

const providerWC = new WalletConnectProvider({
    rpc: {
    //   56:"https://bsc-dataseed.binance.org/"
        137:"https://polygon-rpc.com/"
    },
    qrcodeModalOptions: {
      mobileLinks: [
        "rainbow",
        "metamask",
        "argent",
        "trust",
        "imtoken",
        "pillar",
      ]
    }
});

export default {
    name: 'mint',
    data(){
        return{
            mintAddress: process.env.VUE_APP_MINT_ADDRESS,
            mintAbi: ABI,
            providerType:"",
            
            nfts:[
                {title:'Title1',img:require('../img/nft1.png')},
                {title:'Title2',img:require('../img/nft2.png')},
                {title:'Title3',img:require('../img/nft3.png')},
                {title:'Title4',img:require('../img/nft1.png')},
                {title:'Title5',img:require('../img/nft2.png')},
                {title:'Title6',img:require('../img/nft3.png')},
                {title:'Title7',img:require('../img/nft1.png')},
                {title:'Title8',img:require('../img/nft2.png')},
                {title:'Title9',img:require('../img/nft3.png')},
                {title:'Title10',img:require('../img/nft1.png')},
                {title:'Title11',img:require('../img/nft2.png')},
                {title:'Title12',img:require('../img/nft3.png')},
                {title:'Title13',img:require('../img/nft1.png')},
                {title:'Title14',img:require('../img/nft2.png')},
                {title:'Title15',img:require('../img/nft3.png')},
            ]
        }
    },

    async mounted(){
        if(sessionStorage.connectType == "WC"){
            console.log('testing');
            console.log(providerWC);
            await providerWC.enable();
            this.providerType = providerWC;
        }else{
            this.providerType = window.ethereum;
        }
    },

    methods: {
        wallet(){
            this.$emit("connectWallet");
        },

        mint(){
            // console.log(this.mintAddress)
            let provider = new ethers.providers.Web3Provider(this.providerType)                
            provider.send("eth_requestAccounts", []);

            this.providerType.request({method: 'eth_requestAccounts'})
            .catch((error) => {
                console.log(error); 
            })
            .then(async () => {
                const signer = provider.getSigner()
                // const address = window.ethereum.selectedAddress;
                // let mintAmt = document.getElementById('mintAmt').value;
                let mintAmt = 250;
                // console.log(address, mintAmt)
                // console.log(this.mintAddress)
                try{
                    const contract = new ethers.Contract(this.mintAddress, this.mintAbi, signer);
                    let mint = await contract.mint(parseInt(mintAmt))
                    console.log(mint)
                    // console.log(mint.hash)


                }catch(e){
                    console.log(e.message)
                }
            })
        }
    }
}
</script>

<style>

    h1{
        font-size: 72px;
    }

    body{
        background-color: rgba(252,239,231,255);
    }

    .header-wrap{
        background-color: rgba(40,41,62,255);
    }

    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

    }

   

    .footer{
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        position: relative;
        

    }

    
    .wallet {
        padding: 0 35px;
    }


    .title-div{
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;

    }

    .nft-box{
        background-color: rgb(0, 0, 0);
        height: 267px;
        width: 335px;
        position: relative;
        border: 2px solid #eee;
        box-shadow: 0 0 40px rgba(51, 51, 51, 0.25) !important;
    }

    .nft-title{
        position: absolute;
        top: 65%;
        left: 10%;
    }

    .mint-btn{
        margin-bottom: 15px;
        border-radius: 15px;
        width: 100px;
    }

    .nft-title > h2 {
        color: white;
    }

    .nft-filter{
        display: flex;
        justify-content: center;    
    }

    .nft-filter > a {
        color: black;
    }

    .nft-img{
        opacity: 0.8;
    }

    .nft-img > img{
        width: 335px;
    }

    .nft-box > img{
        width: 335px;
    }

    .nft-filter > .btn {
        background-color: white; 
        width: 40px; 
        height: 38px;
    }
    
</style>
