<template >
    <div id="review">
        <div class="title">
            <div class="container">
                <div class="row title-div">
                    <div class="col-9 d-flex justify-content-center flex-wrap">
                        <div class="col-12 text-center">
                            <h1>Review</h1>
                        </div>
                        <div class="mt-3 d-flex">
                            <h5>Shalini Ganendra</h5>                            
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="title-bg"></div>
        <div class="row d-flex justify-content-center">
            <div class="img-main d-flex justify-content-center">
                <img src="../img/home.jpg" alt="">
                <div class="paint d-flex justify-content-center">
                    <img src="../img/oilpaint2.png" alt="">
                </div>
            </div>
            <div class="content">
                <div class="content-head">
                    <h5> <b>Langkah Sheraton</b> </h5>
                    <span>A Review by Shalini Ganendra</span>
                </div>
                <p>Langkah Sheraton  paints  moments leading to the infamous ‘Sheraton Move’ on 23 February 2020.    Dr Mahathir Mohamad resigned the next day, and a new Perikatan Nasional government was installed on 29 February, with Muhyiddin Yassin as the country’s 8th Prime Minister.</p>    

                <p>The painting description explains <i>“ [k]ey Malaysian political players had maneuverer and out-maneuvered each other in a race against time to secure majority control of the Malaysian Parliament.   It was a dark moment democratic expectation.   The people had granted the Pakatan Harapan coalition the mandate to implement reforms and change for the country in the 13th General Election on 9 May 2018, more than 60 years of Barisan Nasional rule. “</i> </p>   

                <p>From pen to brush, we consider the seminal artwork by self-taught painter and noteworthy MP, Tony Pua,  offering  visceral appeal to all audiences.  The work in fact does not require any appreciation of the 15 depicted characters.  If a picture paints a 1000 words, this one writes a book that is easily understandable and viscerally accessible.</p>  

                <p>We see a crowd of people, with ghosts of the Covid19.  No one is looking at each other;  no one is talking to each other directly.  But, two characters, are on celphones.  To whom are they speaking?  What could they be saying?  What is the plan?  What is the plot?  Reality showed us that there were many, with none working in favour of democratic processes. </p> 

                <p>Symbols include a chess set with a real game prompting the question ‘who is really playing the game?  Who will win?’   There is a treasure chest with label <i>‘xoxo JLow’</i>  and box of fishing tackle with worms.  To those familiar with the  characters, the scene has layered meaning.  But even so, the overarching question remains <i>‘What happened?’</i>   </p> 

                <p>Through these painted images, Pua records reflections and imagined   moments leading to that historic political betrayal by members of the Harapan coalition in February 2020; that moment in which democracy was crushed and which resulted in the young 22-month old   coalition’s collapse. A tragical tale of missed national opportunities thwarted by personal agendas and ambitions.  The failure of a few affecting the hopes of many. </p> 

                <p>The moment was painful and sobering for millions of Malaysians who had only just 22 months earlier in 2018, celebrated the historic peaceful and democratic overthrow of an entrenched Barisan Nasional government that had ruled Malaysia since its 1957 independence.  The people spoke and their actions were finally relevant.</p>  

                <p>Well, now, through a novel opportunity representing a democratizing platform  in itself, up to 8000 fortunate collectors  can own a piece of this history as well as  a piece of the  critically-acclaimed artwork, now universally relevant and locally iconic. </p> 

                <p>The Langkah Sheraton NFT is uniquely backed by shared ownership of the original artwork with its historical value, artistic voice, and community interface – a work and platform that will last for generations to come. The Langkah Sheraton NFT allows collectors to own a piece of history with a sharing of truths, the consideration of which, should be collected to make change.  The lucky 8000 first owners will have pride of place to be pioneer collectors, in a novel construct, that provides equal access, at a very affordable entry level.  Additionally, and importantly the Langkah Sheraton NFT also represents collective memory well worth investing in.  Watch this space … and canvas.</p> 



                <p><i style="color: #7FA5F9">Shalini Amerasinghe Ganendra is an independent scholar,  cultural advisor and frequent contributor to local and international publications. She read law at Cambridge University, has an LL.M. from Columbia University Law School,  and is a  qualified  Barrister and New York Attorney.  She is  currently a Visiting Fellow at Oxford University researching image and identity.</i>  </p> 

            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style>

#review .content-head {
    border-bottom: 1px solid;
    padding-bottom: 10px;
    margin:20px 0;

}

#review .content-head > span {
    font-size: 20px;

}
</style>