<template>
    <div id="app">
        <div class="header-wrap">
            <div class="col-lg-12">
                <div class="header">
                    <div class="logo">
                        <!-- <router-link to="/"><h3>Langkah Sheraton<br/><b>NFT Project</b></h3></router-link> -->
                        <a href="/#home"><h3>Langkah Sheraton<br/><b>NFT Project</b></h3></a>
                    </div>
                    <div class="nav-bar">
                        <nav class="navigation-menu">
                            <ul>
                                <li class="nav-item">
                                    <router-link to="/mintnft">MINT NFT</router-link>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="" href="#" > THE PROJECT </a>
                                    <ul class="dropdown-menu">
                                        <router-link class="dropdown-item" to="/about">ABOUT</router-link>
                                        <router-link class="dropdown-item" to="/origin">ORIGIN</router-link>
                                        <router-link class="dropdown-item" to="/painting">THE PAINTING</router-link>
                                        <router-link class="dropdown-item" to="/artist">THE ARTIST</router-link>
                                        <a class="dropdown-item" href="/#home_review">REVIEWS</a>
                                    </ul>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="" href="#" > NFT COLLECTION </a>
                                    <ul class="dropdown-menu">
                                        <router-link class="dropdown-item" to="/nft">THE COLLECTION</router-link>
                                        <a class="dropdown-item" href="/#home_howto">GETTING STARTED</a>
                                        <a class="dropdown-item" href="/#home_whatsin">WHAT'S IN IT FOR YOU</a>
                                        <router-link class="dropdown-item" to="/tnc">TERMS AND CONDITIONS</router-link>

                                    </ul>
                                </li>
                                <li>
                                    <router-link to="/dap">DAP MALAYSIA</router-link>
                                </li>
                                <li >
                                    <router-link to="/guide">FAQ</router-link>
                                </li>

                                <li class="nav-item">
                                    <!-- <a href="https://discord.com/" class="nav-btn"><img src="../src/img/discord.png" alt="" ></a>  -->
                                    <a href="https://discord.gg/Kq3CXUY23j" target="_blank" class="mobi-nav-btn-2 disc-btn" v-b-tooltip.hover.top title="Join our Discord"><img class="mobi-nav-logo" src="../src/img/discord-logo.png" alt=""></a>  
                                </li>
                                <li >
                                    <button class="mobi-nav-btn-2 wallet-btn" v-bind:class="{ hideBtn: connect}"  data-bs-toggle="modal" data-bs-target="#walletModal" v-b-tooltip.hover.top title="Connect MetaMask wallet">
                                        <img class="mobi-nav-logo" src="../src/img/wallet-logo.png" alt="">
                                    </button>
                                    <!-- <button class="nav-btn" v-bind:class="{ hideBtn: connect}"  data-bs-toggle="modal" data-bs-target="#walletModal">
                                        <img src="../src/img/wallet.png" alt="" >
                                    </button> -->
                                    <div v-bind:class="{ hideBtn: connected}">
                                        <button class="connect" v-bind:class="{ hideBtn: !isMeta}">
                                            {{ userAddress ? userAddress : "Connect Wallet" }}
                                        </button>
                                        <button class="connect" v-bind:class="{ hideBtn: isMeta}" data-bs-toggle="modal" data-bs-target="#walletModal" v-b-tooltip.hover.top title="Click to disconnect wallet">
                                            {{ userAddress ? userAddress : "Connect Wallet" }}
                                        </button>
                                    </div> 
                                    <!-- <a href="" class="nav-btn"><img src="../src/img/wallet.png" alt="" ></a> -->
                                </li>
                                <li>
                                    <button class="dao-btn" @click="daoPage">
                                        <img class="dao-logo" src="../src/img/dao-logo.svg" alt=""><span>DAO NFT</span>
                                    </button> 
                                    <!-- <router-link  to="/polling" class="dao-btn"><img class="dao-logo" src="../src/img/dao-logo.svg" alt=""></router-link> -->
                                    <!-- <a href="/polling" class="dao-btn">
                                        <img class="dao-logo" src="../src/img/dao-logo.svg" alt="">                                       
                                    </a>                                                                                                                  -->
                                </li>                                
                          </ul>
                      </nav>
                                        
                      <!-- <div class="wallet">
                            <button class="connect" data-bs-toggle="modal" data-bs-target="#walletModal">
                                {{ userAddress ? userAddress : "Connect Wallet" }}
                            </button>
                            <button>Connect Wallet</button>
                      </div> -->
                    </div>
                    <div class="nav-side">
                    <b-button v-b-toggle.sidebar-backdrop><img src="../src/img/nav-side.png" alt=""></b-button>

                    <b-sidebar
                    id="sidebar-backdrop"
                    title=""
                    backdrop
                    shadow
                    right
                    >
                    <div class="px-3 py-2">
                        <div class="ps-5">
                            <div class="nav-link-s"><a href="/"><span><h6 class="">HOME</h6></span></a></div>
                            <div class="nav-link-s nav-dap-s"><a href="/mintnft"><span>MINT NFT</span></a></div>                            
                            <button type="button" class="side-nav"><span>THE PROJECT</span></button>
                            <div class="side-nav-sub">
                                <div class="nav-link-s"><a href="/about"><span></span>ABOUT</a></div>
                                <div class="nav-link-s"><a href="/origin"><span>ORIGIN</span></a></div>
                                <div class="nav-link-s"><a href="/painting"><span>THE PAINTING</span></a></div>
                                <div class="nav-link-s"><a href="/artist"><span>THE ARTIST</span></a></div>
                                <div class="nav-link-s"><a href="/#home_review"><span>REVIEWS</span></a></div>
                            </div>
                            <button type="button" class="side-nav"><span>NFT COLLECTION</span></button>
                            <div class="side-nav-sub">
                                <div class="nav-link-s"><a href="/nft"><span>THE COLLECTION</span></a></div>
                                <div class="nav-link-s"><a href="/#home_howto"><span>GETTING STARTED</span></a></div>
                                <div class="nav-link-s"><a href="/#home_whatsin"><span>WHAT'S IN IT FOR YOU</span></a></div>
                                <div class="nav-link-s"><a href="/tnc"><span>TERMS AND CONDITIONS</span></a></div>
                            </div>
                            <div class="nav-link-s nav-dap-s"><a href="/dap"><span>DAP MALAYSIA</span></a></div>
                            <div class="nav-link-s nav-dap-s"><a href="/guide"><span>FAQ</span></a></div>                       
                            
                            <div class="nav-link-s mt-2">
                                <!-- <div>
                                    <a href="https://discord.com/" class="nav-btn"><img src="../src/img/discord2.png" alt="" ></a>
                                </div> -->
                                <div class="mt-4">                                        
                                    <a href="https://discord.gg/Kq3CXUY23j" target="_blank" class="mobi-nav-btn" ><img class="mobi-nav-logo" src="../src/img/discord-logo.png" alt=""></a>  
                                </div>                                
                                <div class="mt-4">
                                    <button class="mobi-nav-btn-2" v-bind:class="{ hideBtn: connect}"  data-bs-toggle="modal" data-bs-target="#walletModal">
                                        <img class="mobi-nav-logo" src="../src/img/wallet-logo.png" alt="">
                                    </button>
                                    <div v-bind:class="{ hideBtn: connected}">
                                        <button class="connect" v-bind:class="{ hideBtn: !isMeta}">
                                            {{ userAddress ? userAddress : "Connect Wallet" }}
                                        </button>
                                        <button class="connect" v-bind:class="{ hideBtn: isMeta}" data-bs-toggle="modal" data-bs-target="#walletModal">
                                            {{ userAddress ? userAddress : "Connect Wallet" }}
                                        </button>
                                    </div>                                    
                                </div>
                                <div class="mt-3">
                                    <button class="dao-btn" @click="daoPage">
                                        <img class="dao-logo" src="../src/img/dao-logo.svg" alt=""><span>DAO NFT</span>
                                    </button>                                     
                                    <!-- <a href="/polling" class="dao-btn"><img class="dao-logo" src="../src/img/dao-logo.svg" alt=""></a> -->
                                    <!-- <router-link  to="/polling" class="dao-btn"><img class="dao-logo" src="../src/img/dao-logo.svg" alt=""></router-link> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    </b-sidebar>
                    </div>
              </div>
          </div>
      </div>    
      <!-- <router-view :connectStatus="connectStatus" :userAddress="user" @connect="connect" /> -->
      
      <div class="router-wrap" id="home"><router-view  @connect="connect" /></div>

    <div class="footer-bg">
        <div class="footer-wrapper row" >
            <div class="col-lg-10">
                <div class="footer">
                    <div class="logo-f" style="padding-top: 100px; margin-right: 70px">                    
                      <a href="/#home"><h4>Langkah Sheraton <b>NFT Project</b></h4></a>
                      <div style="margin-top: 65px">
                        <a href="https://www.techtics.io/index.html" target="_blank"><img src="../src/img/techtics_crop.png" alt="" style="max-width: 150px;"></a>
                      </div>
                      <div style="margin-top: 25px"><span style="font-size: 14px;">In collaboration with</span></div>                      
                      <div class="my-2"><a href="http://www.cyber-village.net/" target="_blank"><img src="../src/img/fermion.png" alt="" style="max-width: 170px;"></a></div>
                      <div class="mt-3"><a href="https://www.380degrees.co/" target="_blank"><img src="../src/img/mxo.png" alt="" style="max-width: 170px;"></a></div>  
                      
                    </div>
                    <div class="footer-line"></div>
                    <div class="nav-wrap-f ms-2">
                        <h6 class="">MENU</h6>
                        <div class="nav-bar-f">
                            <div class="me-5">
                                <div class="nav-link-f"><a href="/about"><span>The Project</span></a></div>
                                <div class="nav-link-f"><a href="/origin"><span>Origin</span></a></div>
                                <div class="nav-link-f"><a href="/painting"><span>The Painting</span></a></div>
                                <div class="nav-link-f"><a href="/artist"><span>The Artist</span></a></div>
                                <div class="nav-link-f"><a href="/#home_review"><span>Reviews</span></a></div>
                            </div>
                            <div class="me-5">
                                <div class="nav-link-f"><a href="/nft"><span>The Collection</span></a></div>
                                <div class="nav-link-f"><a href="/#home_howto"><span>Getting Started</span></a></div>
                                <div class="nav-link-f"><a href="/#home_whatsin"><span>What's in it for you</span></a></div>

                            </div>  
                            <div class="thirdCol me-5">
                                <div class="nav-link-f"><a href="/dap"><span>DAP Malaysia</span></a></div>
                                <div class="nav-link-f"><a href="/guide/#faq-nft"><span>General FAQ</span></a></div>
                                <div class="nav-link-f"><a href="/guide/#faq-mint"><span>Minting FAQ</span></a></div>
                                <div class="nav-link-f"><a href="/guide/#faq-general"><span>Guidelines</span></a></div> 
                                <div class="nav-link-f"><a href="/tnc"><span>Terms and Conditions</span></a></div>
                                <div class="nav-link-f"><a href="/contact"><span>Contact Us</span></a></div>                                     
                            </div>
                            <div>
                                <div class="nav-link-f nav-btn-f mt-2">
                                    <div style="margin-right: 10px; margin-bottom: 5px;">
                                        <!-- <a href="https://discord.com/" class="nav-btn"><img src="../src/img/discord.png" alt="" ></a> -->
                                        <a href="https://discord.gg/Kq3CXUY23j" target="_blank" class="mobi-nav-btn-2 disc-btn" v-b-tooltip.hover.topleft title="Join our Discord"><img class="mobi-nav-logo" src="../src/img/discord-logo.png" alt=""></a>  

                                    </div>
                                    <div style="width: 100%">
                                        <!-- <button class="nav-btn" v-bind:class="{ hideBtn: connect}"  data-bs-toggle="modal" data-bs-target="#walletModal">
                                            <img src="../src/img/wallet.png" alt="" >
                                        </button> -->
                                        <button class="mobi-nav-btn-2 wallet-btn" v-bind:class="{ hideBtn: connect}"  data-bs-toggle="modal" data-bs-target="#walletModal" v-b-tooltip.hover.topleft title="Connect MetaMask wallet">
                                            <img class="mobi-nav-logo" src="../src/img/wallet-logo.png" alt="">
                                        </button>
                                        <div v-bind:class="{ hideBtn: connected}">
                                            <button class="connect" v-bind:class="{ hideBtn: !isMeta}">
                                                {{ userAddress ? userAddress : "Connect Wallet" }}
                                            </button>
                                            <button class="connect" v-bind:class="{ hideBtn: isMeta}" data-bs-toggle="modal" data-bs-target="#walletModal" v-b-tooltip.hover.topleft title="Click to disconnect wallet">
                                                {{ userAddress ? userAddress : "Connect Wallet" }}
                                            </button>
                                        </div>
                                    </div>
                                    <div class=""> 
                                         <button class="dao-btn" @click="daoPage">
                                            <img class="dao-logo" src="../src/img/dao-logo.svg" alt=""><span>DAO NFT</span>
                                        </button>                                      
                                        <!-- <a href="/polling" class="dao-btn"><img class="dao-logo" src="../src/img/dao-logo.svg" alt=""></a> -->
                                        <!-- <router-link  to="/polling" class="dao-btn"><img class="dao-logo" src="../src/img/dao-logo.svg" alt=""></router-link> -->
                                        <button class="dao-btn" @click="importNFT"  v-b-tooltip.hover.topleft title="Import Langkah Sheraton NFT to your MetaMask">   
                                            <div class="importBtn"><img src="../src/img/metamask.png" alt="" style="width: 22px"> Import NFT</div> 
                                        </button>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>

    <!-- modal -->
    <div class="modal fade" tabindex="-1" id="walletModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-bind:class="{ hideBtn: !connected}">Connect Wallet</h5>
                    <h5 class="modal-title" v-bind:class="{ hideBtn: connected}">Disconnect Wallet?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" v-bind:class="{ hideBtn: !connected}">                     
                    <div class="wallet-choice">
                        <button @click="connectWallet">
                            <img src="../src/img/metamask.png" alt="" style="width: 40px">
                            <div>Metamask</div>
                        </button>
                    </div>
                    <div class="wallet-choice">
                        <button @click="connectWalletWC">
                            <img src="../src/img/wallet_connect.png" alt="" style="width: 40px">
                            <div>Wallet Connect</div>
                        </button>
                    </div>
                </div>
                <div class="modal-body" v-bind:class="{ hideBtn: connected}">
                    <div>
                        <button class="disconnect-btn" @click="disconnectWC"> <b>Disconnect</b>  <img src="../src/img/logout.png" alt=""></button>        
                    </div>
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>      
  
  </div>
</template>

<script>
import {ethers} from "ethers";
import axios from "axios";
import WalletConnectProvider from "@walletconnect/web3-provider";


const providerWC = new WalletConnectProvider({
    rpc: {
    //   56:"https://bsc-dataseed.binance.org/"
        137:"https://polygon-rpc.com/"
    },
    qrcodeModalOptions: {
      mobileLinks: [
        "rainbow",
        "metamask",
        "argent",
        "trust",
        "imtoken",
        "pillar",
      ]
    }
  });


export default {

    name: 'App',
    data(){
        return{
            userAddress: "",
            walletStatus: false,
            connectStatus: false,
            connect: false,   
            connected: true,   
            user: "",   
            isMeta: false,
        }
    },

    mounted: async function (){
        
        // await this.connectWallet();        
        var sideNav = document.getElementsByClassName("side-nav");
        var i;

        for (i = 0; i < sideNav.length; i++) {
            sideNav[i].addEventListener("click", function() {
                this.classList.toggle("active");
                var content = this.nextElementSibling;
                if (content.style.maxHeight){
                content.style.maxHeight = null;
                } else {
                content.style.maxHeight = content.scrollHeight + "px";
                } 
            });
        }

        await this.getAddress();

        window.ethereum.on('disconnect', () => {
            if(sessionStorage.connectType != "WC"){
                this.connectStatus = false;
                sessionStorage.address = "";            
                window.location.reload();
            }
            
        })

        window.ethereum.on('accountsChanged', () => {
        // console.log('test')
            // if(accounts.length == 0){
            //     console.log('acc0')
            //     this.connectStatus = false;
            //     this.connected = false;
            //     this.connect = true;
            //     console.log(this.connected , this.connect);
            //     sessionStorage.connect = "";
            //     sessionStorage.bt = "";
            //     console.log(sessionStorage.connect)
            //     console.log(sessionStorage.bt)
            //     console.log('disconnected')             
                            
            // }else{
            //     console.log('??')                
            //     this.connected = true;
            //     this.connect = false;
            //     this.connectStatus = true;
            //     console.log(this.connect , this.connected);

            // }
            if(sessionStorage.connectType != "WC"){
                this.connectStatus = false;        
                this.connected = false;
                this.connect  = true;
                sessionStorage.connect = "";
                sessionStorage.bt = "";
                window.location.reload();
            }
        })

        window.ethereum.on('chainChanged', (res) => {
            // console.log(res)
            if(sessionStorage.connectType != "WC"){
                if(res != '0x89'){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Not connected to Polygon network',
                        text: 'Please connect to Polygon Mainnet chain.',
                        confirmButtonText: 'Return',
                        // allowOutsideClick: false,                        
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.replace("/home");
                        }
                    });
                }
            }
            // window.location.reload();
        })

        

    },

    methods: {
        async importNFT() {
            if (sessionStorage.connect == "true"){
                try {
                    let wasAdded = "";
                    if(sessionStorage.connectType == "WC"){
                        await providerWC.enable();
                        wasAdded = await providerWC.request({
                            method: 'wallet_watchAsset',
                            params: {
                            type: 'ERC20', // Initially only supports ERC20, but eventually more!
                            options: {
                                address: "0xE63b8E3fC67AF4aFa4022e2b28e3D7761aC8F74f", // The address that the token is at.
                                symbol: "LSN", // A ticker symbol or shorthand, up to 5 chars.
                                decimals: 0, // The number of decimals in the token
                                // image: tokenImage, // A string url of the token logo
                            },
                            },
                        });
                    }else{
                        wasAdded = await window.ethereum.request({
                            method: 'wallet_watchAsset',
                            params: {
                            type: 'ERC20', // Initially only supports ERC20, but eventually more!
                            options: {
                                address: "0xE63b8E3fC67AF4aFa4022e2b28e3D7761aC8F74f", // The address that the token is at.
                                symbol: "LSN", // A ticker symbol or shorthand, up to 5 chars.
                                decimals: 0, // The number of decimals in the token
                                // image: tokenImage, // A string url of the token logo
                            },
                            },
                        });
                    }
                    console.log(wasAdded)
                    if(wasAdded){
                        this.$swal.fire({
                            icon: 'success',
                            title: 'NFT token imported',
                            text: 'Langkah Sheraton NFT token has been successfully imported to your MetaMask account.',
                            confirmButtonText: 'Return',
                            // allowOutsideClick: false,                        
                        })
                    }
                } catch (error) {
                    console.log(error);
                }
            }else{
                this.$swal.fire({
                    icon: 'error',
                    title: 'MetaMask not connected',
                    text: 'Please connect to your MetaMask wallet.',
                    confirmButtonText: 'Return',
                    allowOutsideClick: false,                        
                });
            }            
        },
        async disconnectWC(){
            await providerWC.disconnect();
            sessionStorage.connect = "";
            sessionStorage.connectType = "";
            sessionStorage.bt = "";
            window.location.reload();
        },
        async connectWalletWC(){
            await providerWC.enable();
            try {
                const chainId = await providerWC.request({ method: "eth_chainId" });                

                this.$swal.fire({
                    icon: 'warning',
                    title: 'Connecting to wallet',
                    html: 'Please select Polygon Network in your Metamask App',
                    background: "#FDF0E9",       
                    allowOutsideClick: false,
                    didOpen: () => {
                        this.$swal.showLoading()
                    },
                });
                if(chainId != 137){
                    await providerWC.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x89" }],
                    });
                }                
            } catch (switchError) {
                // alert("Please add polygon network to your metamask")       
                // this.$swal.close()                
                this.$swal.fire({
                    icon: 'error',
                    title: 'Not connected to Polygon network',
                    text: 'Please add and select Polygon Mainnet chain in your Metamask App',
                    confirmButtonText: 'Return',
                    // allowOutsideClick: false,                        
                }).then(async (result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        sessionStorage.connect = "";
                        sessionStorage.connectType = "";
                        await providerWC.disconnect();
                        window.location.reload()
                    }
                });        
            }
            


            const web3Provider = new ethers.providers.Web3Provider(providerWC);
            console.log(web3Provider);
            // const signer = await web3Provider.getSigner();
            // const address = await signer.getAddress();
            const chainId = await providerWC.request({ method: "eth_chainId" });
            console.log(chainId);

            if(chainId == 137){
                this.$swal.close()
                // this.user = address;
                if(sessionStorage.connect == "true"){
                    // console.log("can")
                }else{                
                    let provider = new ethers.providers.Web3Provider(providerWC)                
                    const signer = provider.getSigner()
                    let a = await axios.post('https://api.langkahsheraton.com/api/sheraton/auth/users/login', {
                        address: await signer.getAddress(),
                    })
                    sessionStorage.connect = true
                    sessionStorage.bt = a.data.access_token
                    sessionStorage.connectType = "WC";
                    this.connectStatus = true;
                    this.walletStatus = !this.walletStatus;
                    this.userAddress = this.user.substr(0, 5) + "...." + this.user.substr(-4, 4);
                    this.connect = true;
                    this.connected = false;
                    window.location.reload()
                }  
            }
              
        },

        async getAddress(){
            if(sessionStorage.connect == "true"){
                // console.log('test')
                // await window.ethereum.request({ method: "eth_requestAccounts" });
                // this.user = window.ethereum.selectedAddress;
                let provider;
                if(sessionStorage.connectType == "WC"){
                    await providerWC.enable();
                    provider = new ethers.providers.Web3Provider(providerWC)
                    const chainId = await providerWC.request({ method: "eth_chainId" });
                    if (chainId != 137){
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Not connected to Polygon network',
                            text: 'Please select Polygon Mainnet chain in your Metamask App and reconnect.',
                            confirmButtonText: 'Return',
                            // allowOutsideClick: false,                        
                        }).then(async (result) => {
                            if (result.isConfirmed || result.isDismissed) {
                                sessionStorage.connect = "";
                                sessionStorage.connectType = "";
                                await providerWC.disconnect();
                                window.location.reload()
                            }
                        });
                    }
                    this.isMeta = false;                   
                }else{
                    provider = new ethers.providers.Web3Provider(window.ethereum)
                    this.isMeta = true;
                }

                console.log(provider);
                const signer = provider.getSigner()
                console.log(signer);
                this.user = await signer.getAddress(),
                console.log(this.user)
                this.connectStatus = true;
                this.walletStatus = !this.walletStatus;
                this.userAddress = this.user.substr(0, 5) + "...." + this.user.substr(-4, 4);
                this.connect = true;
                this.connected = false;              
                // console.log(this.userAddress)
            }
        },

        async connectWallet() {
            // console.log(sessionStorage.connect)
            if(window.ethereum == undefined){
                alert("Please install metamask");
                // return false;
            }else{
                let checkNetwork = this.switchNetwork();
                // console.log(checkNetwork)
                if(!checkNetwork){
                    // return false;
                    console.log('no');
                }

                // const { ethers } = require("ethers");
                // let provider = new ethers.providers.Web3Provider(window.ethereum,"any");                
                // console.log(window.ethereum.isConnected())

                await window.ethereum.request({ method: 'eth_requestAccounts' });
        
                if(sessionStorage.connect == "true"){
                    // console.log("can")
                }else{
                    
                    let provider = new ethers.providers.Web3Provider(window.ethereum)                
                    const signer = provider.getSigner()
                    // let message = 'Connect Wallet';
                    // let signature = await signer.signMessage(message);
                    // console.log(message, signature);

                    let a = await axios.post('https://api.langkahsheraton.com/api/sheraton/auth/users/login', {
                        address: await signer.getAddress(),
                    })
                    // console.log(a);
                    // console.log(a.data.access_token);
                    sessionStorage.connect = true
                    sessionStorage.bt = a.data.access_token
                    

                    await window.ethereum.request({ method: "eth_requestAccounts" });
                    this.user = window.ethereum.selectedAddress;
                    // console.log('connected')
                    this.connectStatus = true;
                    this.walletStatus = !this.walletStatus;
                    this.userAddress = this.user.substr(0, 5) + "...." + this.user.substr(-4, 4);
                    this.connect = true;
                    this.connected = false;
                    // console.log('reload')
                    window.location.reload()
                }


                if(this.connectStatus == false){
                    // console.log(window.ethereum.isConnected())
                    // let user = sessionStorage.user;
                    this.user = window.ethereum.selectedAddress;       
                    this.connectStatus = true;
                    this.walletStatus = !this.walletStatus;
                    this.userAddress = this.user.substr(0, 5) + "...." + this.user.substr(-4, 4);
                    
                    this.connect = true;
                    this.connected = false;
                    window.location.reload()

                }else{
                    // console.log("test3")
                    await window.ethereum.request({ method: "eth_requestAccounts" });
                    this.user = window.ethereum.selectedAddress;
                    // console.log('connected')
                    this.connectStatus = true;
                    this.walletStatus = !this.walletStatus;
                    this.userAddress = this.user.substr(0, 5) + "...." + this.user.substr(-4, 4);
                    this.connect = true;
                    this.connected = false;
                    window.location.reload()
                }

                
            }            

        },

        async switchNetwork(){
            // const { ethers } = require("ethers");
            // let networkId = ethers.utils.hexlify(80001);
            // alert(networkId)

            //97 bsc chain
            //137 (0x89) polygon chain (testnet 80001, hex: 0x13881)

            try {
                await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: "0x89" }],
                //   params: [{ chainId: networkId }],
                });
            } catch (switchError) {
                alert("Please add polygon network to your metamask")
                return false
            }
            return true;
        },

        daoPage(){
            // if(sessionStorage.connect != "true"){
            //     this.$swal.fire({
            //         icon: 'error',
            //         title: 'MetaMask not connected',
            //         text: 'Please connect to your MetaMask wallet.',
            //         confirmButtonText: 'Return',
            //         allowOutsideClick: false,                        
            //     }).then((result) => {
            //         if (result.isConfirmed) {
            //             window.location.replace("/home");
            //         }
            //     });
            // }else{
            //     window.location.replace("/polling");
            // }
            window.location.replace("/polling");
        },

        // async testing() {
        //     // console.log(sessionStorage.bt)
        //     let provider = new ethers.providers.Web3Provider(window.ethereum)       
        //     const signer = provider.getSigner()
        //     let message = 'test token';
        //     let signature = await signer.signMessage(message);
        //     let a = await axios.post('https://api.langkahsheraton.com/api/sheraton/setUserEmail',
        //         {
        //             address: await signer.getAddress(),
        //             signature: signature,
        //             message: message,
        //             email: 'test12888856@test.com'
        //         },
        //         { headers: {'Authorization' : `Bearer ${sessionStorage.bt}`} })
        //     console.log(a)
        // }

    }
}

</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}


.row{
    margin-left: 0 !important;
    margin-right: 0 !important;

}

body{
  margin: 0px;
}

a{
    text-decoration: none;
    color:white;
}

a:hover{
    color:white;
}

h1{
    font-size: 56px;
}

body{
    background-color: rgba(252,239,231,255);
}

.router-wrap{
    /* background: rgba(40,41,62,255); */
    padding-top: 100px;
}


/* side-nav-bar */
.side-nav {
    transition: 0.4s ease-out;
    border: none !important;
    background-color: transparent;
    cursor: pointer;
    width: 80%;
    height: auto;
    text-align: left;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 5px 0;
}
.side-nav  span{
    font-size: 16px;
}
.side-nav-sub {
    width: 100%;
    padding: 5px 35px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: transparent;
    border: none;
    /* margin: 20px 0; */

}
.active {
    border-bottom: 2px solid #28293E !important;
}
/* --- */

@media all and (min-width: 1025px) {
    .nav-side{
        display: none !important;
    } 
}

@media all and (max-width: 1024px) {
    .nav-bar{
        display: none !important;
    }
    .router-wrap{
        padding-top:50px;
    }
}


.header-wrap{
    background-color: rgba(45,45,65,255);
    position: fixed;
    top:0;
    width: 100%;
    z-index: 99;
}


.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    -webkit-box-shadow: 0px 5px 15px 0px #000000; 
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
}

.footer-wrapper{
    background-color: #28293E;
    /* padding: 250px 0 0 0; */
    display:flex;
    justify-content: center;
    padding-bottom: 5em;
}

.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.footer-line{
    width:1px;
    height:360px;
    border-right: 1px solid rgba(128, 128, 128, 0.7);
    margin-top: 110px;
}
#walletModal .modal-body{
    display: flex;
    justify-content: center;

}

#walletModal .modal-content{
    width: 90%;
    margin: 10rem auto;
    
}


#walletModal .wallet-choice{
    width: 50%;
    text-align: center;
}

#walletModal .wallet-choice button{
    background: transparent;
    border: none;
}

#walletModal .wallet-choice button:hover{
    opacity: 0.8;
}

#walletModal .disconnect-btn{
    background-color: rgba(150,76,63,255);
    border: transparent;
    color:white;
    border-radius: 20px;
    padding: 7px 13px;
    font-size: 20px;
}

#walletModal .disconnect-btn img{
    width: 24px;
    margin-left: 10px;
}



@media all and (min-width: 440px) and (max-width: 1024px) {
    .paint img{
        width: 70% !important;
    }
    
    .footer-line{
        width:81% !important;
        margin-left: 7% !important;
    }

}

/* @media all and (max-width: 768px) { */
@media all and (max-width: 1024px) {
    .footer-line{
        width:71%;
        height:2px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.7);
        margin-bottom: 60px;
        margin-left: 14%;
        margin-top: 0;
    }

	.footer{ 
        flex-wrap: wrap ;
        justify-content: left !important; 
    }
    .logo-f{
        padding: 100px 0 50px 0;
        margin: 0 60px ;
    }

    .logo-f h4{
        font-size: 24px;
    }

    .nav-wrap-f{
        padding: 0 0 0 50px !important;
    }


    .logo h3{
        font-size: 18px;
    }

    .b-sidebar-header {
        margin-left:250px;
    }


    .content > p {
    font-size: 16px !important;
    }

    .img-main img{
    width: 100%;
    height: auto !important;
    }

    .img-main {
    margin-bottom: 5rem !important;
    }

    .paint img{
    width: 40%;
    height: auto !important;
    }

    #about .paint{
    top: 90% !important;
    left: auto !important;
    }

    #dap .paint{
    top:74% !important;
    left: auto !important;
    }

    #origin .paint{
    top:81% !important;
    left: auto !important;
    }

    #artist .paint{
    top:80% !important;
    left: auto !important;
    }

    #review .paint{
    top:74% !important;
    left: auto !important;
    }

    .title-div h1{
        font-size: 60px !important;
    }

    .nav-link-f{
        display: flex;
        justify-content: space-between;
        /* width: auto !important; */
        gap: 15%;
    }

    .nav-link-f span{
        font-size: 20px;
    }

    .nav-link-f div:first-child{
        margin-top: 15px;
    }

    .nav-link-f .dao-btn{
        margin-top: 8px;
    }

    .nav-link-f .wallet-btn {
        margin-top: 10px;
    }

    .nav-link-f .connect {
        margin-top: 10px;
    }


}

@media screen and (min-width: 1210px){
    .nav-bar-f .thirdCol {
        margin-left:50px;
        margin-right: 5%;
    }

    .nav-link-f .dao-btn{
        margin-top: 12px;
    }

    /* .nav-link-f .disc-btn{
        margin-top: 12px;
    } */

    .nav-link-f .wallet-btn {
        margin-top: 15px;
    }

    .nav-link-f .connect {
        margin-top: 15px;
    }
}

.nav-link-f span{
        font-size: 20px;
    }

.logo-f h4{
        font-size: 24px;
    }

.logo{
    /* background-color: aqua; */
    width: 500px;
    color: white;
    text-align: left;
    padding-left: 10%;
    padding-top:10px;
}

.logo-f{
    /* background-color: aqua; */
    width: 200px;
    color: white;
    text-align: left;
}

.nav-side .btn-secondary{
    background: #28293E;
}


.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 8%;
}
.navigation-menu > ul {
    display: flex;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    align-items: center;
}

.navigation-menu > ul > li:first-child{
    /* margin: 0 24px 0 0; */
}

.navigation-menu > ul > li{
    margin: 0 10px;
}

.navigation-menu > ul > li > a{
    padding: 31px 2px;
    font-size: 14px;
    font-weight: 500;  
    display:inline-block;
}

.navigation-menu .disc-btn{
    margin: 31px 2px;
}

.dropdown-menu{
    font-size: 14px;
}

.nav-wrap-f{
    padding: 5% 0 0 80px;
}

.nav-wrap-f > h6 {
    color: rgb(255, 102, 0);
}

.nav-bar-f{
    /* flex-wrap: wrap; */
    /* width: 600px; */
    display: flex;
    flex-wrap: wrap;
    /* gap: 15%; */
}

.nav-link-f {
    width: 170px;
    padding: 3px 0;
}

.nav-btn-f{
    width: 170px;
    padding: 3px 0;
    display: flex;
    flex-wrap: wrap;
}

.nav-btn{
    padding: 0;
    border: 0;
    background-color: transparent;
    border-radius: 50%;
}

.disc-btn{
    padding: 8px 9px 10px 9px !important;

}

.nav-link-f .disc-btn{
    padding: 10px 9px 12px 10px !important;

}

.wallet-btn{
    padding: 7px 9px 8px 10px !important;
}

.dao-btn{
    background-color: rgba(150,76,63,255);
    border: transparent;
    color:white;
    /* border-radius: 50%;
    padding: 6px 12px 9px 12px !important; */

    border-radius: 20px;
    padding: 7px 13px;
    /* width: 104px; */
    width: auto;
}

.dao-btn > img{    
    width:15px;
}

.dao-btn > span{
    margin-left: 5px;
    margin-top: 50px;
    font-size: 13px;
}


.mobi-nav-btn{

    background-color: rgba(150,76,63,255);
    border: transparent;
    color:white;
    border-radius: 50%;
    padding: 9.2px 9px 11px 9.2px ;
}

.mobi-nav-btn-2{

    background-color: rgba(150,76,63,255);
    border: transparent;
    color:white;
    border-radius: 50%;
    padding:7px 9px 8px 9px ;
}

.mobi-nav-btn > img,
.mobi-nav-btn-2 > img{    
    width:20px;
    height: auto;
}

.nav-btn > img{    
    width:40px;
}

.connect {
    background-color: rgba(150,76,63,255);
    border: transparent;
    color:white;
    border-radius: 20px;
    padding: 7px 13px;
}

.wallet {
    padding: 0 35px;
}

.hideBtn {
    display: none !important;
}

@media all and (min-width: 992px) {
	.nav-bar .nav-item .dropdown-menu{ display: none; }
	.nav-bar .nav-item:hover .nav-link{   }
	.nav-bar .nav-item:hover .dropdown-menu{ display: block; }
	.nav-bar .nav-item .dropdown-menu{ margin-top:0; }
}


.title{
    /* height: 300px; */
    background-color: rgba(40,41,62,255);
    padding-top: 90px;
    /* padding-bottom: 131px; */
}

.title-div{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.title-div h1{
    font-family: "OneBrush", Helvetica, Arial;
    font-size: 140px;
}

.nft-box{
    background-color: rgb(0, 0, 0);
    height: 267px;
    width: 335px;
    position: relative;
    border: 2px solid #eee;
    box-shadow: 0 0 40px rgba(51, 51, 51, 0.25) !important;
}

.nft-title{
    position: absolute;
    top: 65%;
    left: 10%;
}

.mint-btn{
    margin-bottom: 15px;
    border-radius: 15px;
    width: 100px;
}

.nft-title > h2 {
    color: white;
}

.nft-filter{
    display: flex;
    justify-content: center;    
}

.nft-filter > a {
    color: black;
}

.nft-img{
    opacity: 0.8;
}

.footer-bg{
    background-image: url('../src/img/footer.png');
    background-size: cover;
    height: 680px;
    background-repeat: no-repeat;
    background-position: center;
    padding: 250px 0 0 0;

}

.title-bg{
    background-image: url('../src/img/header.png') !important;
    background-size:cover;
    height: 175px;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 50px;
}

.img-main{
    width: auto;
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 8rem;
}

#review .img-main img{
    max-width: 1167px;
}

#about .paint{
    position: absolute;
    top:89%;
    left: 23%;
}

#dap .paint{
    position: absolute;
    top:80%;
    left: 38%;
}

#origin .paint{
    position: absolute;
    top:85%;
    left: 33%;
}

#artist .paint{
    position: absolute;
    top:85%;
    left: 33%;
}

#review .paint{
    position: absolute;
    top:80%;
    left: 33%;
}

.content {
    width:800px;
    /* margin-top: 8rem; */
}

.content > p {
    font-size: 20px;
    padding: 10px 0;
}

.dao-logo {
    /* filter: invert(99%) sepia(8%) saturate(421%) hue-rotate(257deg) brightness(120%) contrast(100%); */
    /* filter: invert(31%) sepia(8%) saturate(4591%) hue-rotate(322deg) brightness(98%) contrast(76%); */
    /* background: white; */
}

.nav-link-s a{
    color:#000000;
}

.nav-link-s {
    padding: 5px 0;
}

.nav-dap-s{
    margin-bottom:10px;
}

@media (min-width: 1200px){
    .container {
        max-width: 1200px;
    }
}

.activeBtn{
    background-color: rgba(45,45,65,255) !important;   
    color:#ffffff !important;
}

.content-wrap{
    display: flex;
    justify-content: center;    
}

@media (min-width: 1970px){
    .logo{
        width:800px;
    }

    .content-wrap{
        width:50%;
        margin-left: auto !important;
        margin-right: auto !important;
        margin-bottom: 3%;
        max-width: 1900px;
    }

    .title-div h1{
        font-size: 200px;
    }
}

@font-face {
  font-family: "OneBrush";
  src: local("OneBrush"),
    url(./fonts/OneBrush.ttf) format("truetype");
}

@font-face {
  font-family: "darkRoast";
  src: local("darkRoast"),
    url(./fonts/darkRoast.ttf) format("truetype");
}

.importBtn {
    display: flex;
    /* align-content: center; */
    font-size: 15px;
    gap: 3px;
    margin-top: 0px !important;
} 

/* tooltip */
.tooltip {
  position: relative;
  /* display: inline-block; */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@import'~bootstrap/dist/css/bootstrap.css';

</style>
