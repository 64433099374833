<template>
  <div id="mintnft">
        <div class="title row">
            <div class="container">
                <div class="row title-div">
                    <div class="col-10">
                        <div class="text-center d-flex flex-wrap justify-content-center">
                            <div>
                                <h1 class="mb-3">MINT NFT</h1>
                            </div>                            
                            <div >
                                <p></p>                       
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="title-bg"></div>
        <div class="d-flex flex-wrap justify-content-center">                
            
        </div>
        <div id="main-wrapper">                      
            <div class="site-wrapper">
                <div class="section4BG">
                    <div id="homeSec4" class="section4">
                        <div class="home_title">
                        <span><b>NFT</b> Collection</span>
                        <!-- <span>EXPLORE MORE</span> -->
                        <a href="/nft">EXPLORE MORE</a>
                        </div>
                        <div class="mint-desc">
                            <b> <u>Mint NFT</u> </b><br/><br/>
                            <span>The Langkah Sheraton NFTs will mint for 45 $MATIC each after its official launch on 31 August 2022.</span><br/><br/>
                            <span>You can mint up to 250 NFTs (250 x 45 $MATIC) for every transaction. There is no limit on the number of transactions you can perform.There will be a minimal additional ‘gas fee’ charge for every transaction.</span><br/><br/>
                            <span>All NFTs are minted and randomly allocated. We have 6,000 Original, 2,400 Limited, 400 Rare and 88 Ultra-Rare NFTs.</span><br/><br/>
                            <span>However, for every 100 NFTs minted, the purchaser will have a guaranteed (randomly generated) Ultra Rare NFT.  Don’t miss this opportunity!</span>  
                        </div>                        
                        <vueper-slides
                        :arrows-outside="false"
                        class="no-shadow"
                        :visible-slides="3"
                        slide-multiple
                        :gap="3"
                        :slide-ratio="1 / 4"
                        :dragging-distance="200"
                        :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }"
                        >
                        <template #arrow-left>
                            <button class="dir-arrows"><img src="../img/caret-left-solid.svg" alt="left arrow"></button>
                        </template>
                        <template #arrow-right>
                            <button class="dir-arrows"><img src="../img/caret-right-solid.svg" alt="right arrow"></button>
                        </template>
                        <vueper-slide v-for="(slide, i) in nfts" :key="i" :image="slide" />
                        </vueper-slides>
                        <vueper-slides
                        :arrows-outside="false"
                        class="no-shadow"
                        :visible-slides="3"
                        slide-multiple
                        :gap="3"
                        :slide-ratio="1 / 4"
                        :dragging-distance="200"
                        :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }"
                        >
                        <template #arrow-left>
                            <button class="dir-arrows"><img src="../img/caret-left-solid.svg" alt="left arrow"></button>
                        </template>
                        <template #arrow-right>
                            <button class="dir-arrows"><img src="../img/caret-right-solid.svg" alt="right arrow"></button>
                        </template>
                        <vueper-slide v-for="(slide, i) in nfts2" :key="i" :image="slide" />
                        </vueper-slides>
                        <div v-if="mintingAvailable >= mintMin && connectStatus == 'true'" class="mint-section">
                        <form @submit.prevent="mint2(mintingStage)">                            
                            <div class="nftAmount">
                                <div v-if="mintingStage == 0" class="nftStage">
                                    <span><b>Minting stage: Pre-Sales</b> </span>
                                </div>
                                <div v-if="mintingStage == 1" class="nftStage">
                                    <span><b>Minting stage: Whitelist</b> </span>
                                </div>
                                <div v-if="mintingStage == 2" class="nftStage">
                                    <span><b>Minting stage: Public</b> </span>
                                </div>
                                <span>No. of NFT(s) available: {{mintingAvailable}}</span>
                            </div>
                            <div class="qty-wrapper">              
                                <button v-on:click="mintDEC()" type="button">-</button>
                                <input v-model="mintQty" :min="mintMin" :max="mintMax" type="number" v-on:input="mintQtyInput();">
                                <button v-on:click="mintINC()" type="button">+</button>
                            </div>
                            <div class="mintAlert" v-bind:class="{hideBtn: dontHideMint}"><span>Mint Quantity: min {{mintMin}} , max {{mintMax}}</span></div>
                            <div v-if="mintingStage == 0">
                                <button type="submit" class="mintBtn" v-bind:class="{hideBtn: hideMint}" data-bs-toggle="modal" data-bs-target="#exampleModal"  v-on:click="getLimit()">
                                    MINT NOW
                                </button>
                            </div>
                            <div v-else>
                                <div class="d-flex mt-3">
                                    <div class="tncBox"><input type="checkbox" @change="tncCheckbox" ></div>                                
                                    <div class="tnc"><span>I have read and agree to Langkah Sheraton NFT <a href="/tnc" target="_blank"><i>Terms and Conditions</i></a> and <a href="/files/Privacy Policy - NOTICE UNDER THE PERSONAL DATA PROTECTION ACT 2010.pdf" download><i>Privacy Policy</i></a> </span></div>

                                </div>
                                <button type="submit" class="mintBtn" v-bind:class="{hideBtn: hideMint}" v-on:click="getLimit()">
                                    MINT NOW
                                </button>
                            </div>
                                <button type="submit" class="mintBtn" v-bind:class="{hideBtn: dontHideMint}" data-bs-toggle="modal" data-bs-target="#exampleModal"  v-on:click="getLimit()" disabled>
                                    MINT NOW
                                </button>
                        </form>          
                        </div>
                        <div v-else class="mint-section">
                        <form @submit.prevent="mint2()">
                            <div class="nftAmount"><span>No. of NFT(s) available: {{mintingAvailable}}</span></div>
                            <div class="qty-wrapper">              
                            <button v-on:click="mintDEC()" type="button" disabled>-</button>
                            <input v-model="mintQty" :min="mintMin" :max="mintMax" type="number" v-on:input="mintQtyInput();" disabled>
                            <button v-on:click="mintINC()" type="button" disabled>+</button>
                            </div>
                            <div v-if="connectStatus == 'true'" class="mintAlert" ><span>Mintable NFTs for current stage are less than the minimum minting amount. </span></div>
                            <div v-else class="mintAlert"><span>Please connect to your Metamask wallet</span></div>
                            
                            <div class="d-flex mt-3">
                                <div class="tncBox"><input type="checkbox" @change="tncCheckbox"  disabled ></div>                                
                                <div class="tnc"><span>I have read and agree to Langkah Sheraton NFT <a href="/tnc" target="_blank"><i>Terms and Conditions</i></a> and <a href="/files/Privacy Policy - NOTICE UNDER THE PERSONAL DATA PROTECTION ACT 2010.pdf" download><i>Privacy Policy</i></a> </span></div>

                            </div>
                            <button type="submit" class="mintBtn"  data-bs-toggle="modal" data-bs-target="#exampleModal"  v-on:click="getLimit()" disabled>
                            MINT NOW
                            </button>
                        </form>
                        </div>
                    </div>                    
                </div>
            </div>   
        </div>

        <!-- modal -->
    <div class="modal fade" tabindex="-1" id="exampleModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">You will be guaranteed a Ultra Rare NFTs for every 100 NFTs purchased.</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- <p>{{mintQty}}</p> -->
            <div class="mint-select-info">
              <div class="me-5"><p>Mint amount: {{mintQty}}</p></div>
              <div class="me-5"><p>Selection available: {{selectLimit - selectedNFT.length}}</p></div>
                   
              <!-- <div v-if="selectedNFT.length != 0"><p>Rare NFT selected (id): {{selectedNFT}}</p></div>  -->
              <!-- <div v-else><p>Rare NFT selected: none</p></div> -->
            </div>             

            <div class="d-flex" >
              <div class="nft-modal-btn">
                <button class="dir-arrows" v-on:click="nftModalPage()"><img src="../img/caret-left-solid.png" alt="left arrow"></button>
              </div>
              <div class="nft-modal" :class="{hideBtn: display2nd}">
                <div class="col-md-4 d-flex justify-content-center my-3"  v-for="nft in availableNFT.slice(0,6)" :key="nft.id" id="itemList">
                  <label :for="'nftSelect'+nft.id">
                    <input :id="'nftSelect'+nft.id" :value="nft.id" type="checkbox" class="flip-checkbox selectNFT" v-on:change="selectNFT(nft.id)" />
                    <div class="nft-box d-flex align-items-center justify-content-chideBtnenter">
                        <div class="nft-img">
                            <img :src="require('../img/nft/'+(nft.title)+'.jpg')" alt="">
                        </div>
                        <div class="nft-title">
                            <!-- <h3>{{nft.title}}</h3> -->
                        </div> 
                    </div>
                  </label>                
                </div> 
              </div> 
              <div class="nft-modal" :class="{hideBtn: display1st}">
                <div class="col-md-4 d-flex justify-content-center my-3"  v-for="nft in availableNFT.slice(6,12)" :key="nft.id" id="itemList">
                  <label :for="'nftSelect'+nft.id">
                    <input :id="'nftSelect'+nft.id" :value="nft.id" type="checkbox" class="flip-checkbox selectNFT" v-on:change="selectNFT(nft.id)" />
                    <div class="nft-box d-flex align-items-center justify-content-center">
                        <div class="nft-img">
                            <img :src="require('../img/nft/'+(nft.title)+'.jpg')" alt="">
                        </div>
                        <div class="nft-title">
                            <!-- <h3>{{nft.title}}</h3> -->
                        </div> 
                    </div>
                  </label>                
                </div>                 
              </div>
              <div class="nft-modal-btn">
                <button class="dir-arrows" v-on:click="nftModalPage()"><img src="../img/caret-right-solid.png" alt="right arrow"></button>
              </div>           
            </div>

            <div class="nft-modal-btn-btm">
              <button class="dir-arrows" v-on:click="nftModalPage()"><img src="../img/caret-left-solid.png" alt="left arrow"></button>
              <button class="dir-arrows" v-on:click="nftModalPage()"><img src="../img/caret-right-solid.png" alt="right arrow"></button>
            </div>
            
          </div>
          <div class="modal-footer">
            <div class="d-flex my-3 modal-tnc">
                <div class="tncBox"><input type="checkbox" @change="tncCheckbox" ></div>                                
                <div class="tnc"><span>I have read and agree to Langkah Sheraton NFT <a href="/tnc" target="_blank"><i>Terms and Conditions</i></a> and <a href="/files/Privacy Policy - NOTICE UNDER THE PERSONAL DATA PROTECTION ACT 2010.pdf" download><i>Privacy Policy</i></a> </span></div>

            </div>
            <button type="button" class="btn btn-primary"  v-on:click="mint()">Confirm</button>
            <!-- <button type="button" class="btn btn-primary" v-on:click="mint()" >Mint</button> -->
          </div>
        </div>
      </div>
    </div>

  </div>

  
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import {ethers} from "ethers";
import ABI from "/src/ABI/mint.json";
import axios from "axios";
import WalletConnectProvider from "@walletconnect/web3-provider";

const providerWC = new WalletConnectProvider({
    rpc: {
    //   56:"https://bsc-dataseed.binance.org/"
        137:"https://polygon-rpc.com/"
    },
    qrcodeModalOptions: {
      mobileLinks: [
        "rainbow",
        "metamask",
        "argent",
        "trust",
        "imtoken",
        "pillar",
      ]
    }
});

export default {
    components: { VueperSlides, VueperSlide },
    data(){
        return{
            mintAddress: process.env.VUE_APP_MINT_ADDRESS,
            mintAbi: ABI,
            transitioning: false,
            initialStepUpper: '',
            initialStepLower: '',
            stepNftUp: '',
            stepNftDown: '',
            innerStylesUp: {},
            innerStylesDown: {},
            mintQty: 0,
            selectLimit: 0,
            baseAmt: 0,
            nftData: "",
            availableNFT: [],
            selectedNFT:[],
            paginatedItems: "",
            currentPage: 1,
            perPage: 6,
            totalRows: "",
            hideMint: false,
            dontHideMint: true,
            display1st: true,
            display2nd: false,
            mintingStage: 0,
            mintingAvailable: 0,
            mintMin: 300,
            mintMax: 500,
            connectStatus: "",
            providerType:"",
            tncCheck: false,
            nfts: [
                require("../img/nft/full_artwork.jpg"),
                require("../img/nft/full_black.jpg"),
                require("../img/nft/full_black2.jpg"),
                require("../img/nft/full_blue.jpg"),
                require("../img/nft/full_green.jpg"),
                require("../img/nft/full_lightblue.jpg"),        
            ],
            nfts2: [
                require("../img/nft/head_color.jpg"),
                require("../img/nft/item_color.jpg"),
                require("../img/nft/tony_artwork.jpg"),
                require("../img/nft/full_orange.jpg"),
                require("../img/nft/full_red.jpg")
            ],
        }
    },
    async mounted(){
        
        if(sessionStorage.connectType == "WC"){
            console.log('testing');
            console.log(providerWC);
            await providerWC.enable();
            this.providerType = providerWC;
        }else{
            this.providerType = window.ethereum;
        }

        window.scrollTo(0, 0) //load page from top
        this.getNFT()  //retrieve NFTs
        if(sessionStorage.connect == "true"){
            this.mintStatus('load') //get minting status from contract
        }

        //--nft imgs--//
        await this.setStep()
        this.innerStylesUp = {
            transition: 'none',
            transform: `translateX(-${this.initialStepUpper})`
        }
        this.innerStylesDown = {
            transition: 'none',
            transform: `translateX(-${this.initialStepLower})`
        }
        //-----//
    },

    methods: {
        tncCheckbox(){
            this.tncCheck = !this.tncCheck;
            console.log(this.tncCheck)
        },
        mintStatus(x){
            let provider = new ethers.providers.Web3Provider(this.providerType)
            provider.send("eth_requestAccounts", []);
            this.providerType.request({method: 'eth_requestAccounts'})
            .catch((error) => {
                console.log(error); 
            })
            .then(async () => {
                const signer = provider.getSigner()
                // const address = window.ethereum.selectedAddress;
                // console.log(address)
                try{
                    const contract = new ethers.Contract(this.mintAddress, this.mintAbi, signer);
                    let mintStage = await contract.stageNow();
                    console.log(mintStage);
                    this.mintingStage = parseInt(mintStage._hex);
                    // this.mintingStage = 0;
                    // let mintedAmount = await contract.totalSupply();
                    // this.mintingAvailable = 8888 - mintedAmount;
                    
                    axios.get('https://api.langkahsheraton.com/api/sheraton/getSales'
                    ).then((response) => {
                    // console.log(response)
                    this.mintingAvailable = response.data.availableMint
                    })

                    if(this.mintingStage == 0){
                    // this.mintingAvailable = 4500 - mintedAmount;
                    }else if(this.mintingStage == 1){
                    // this.mintingAvailable = 7000 - mintedAmount;
                    this.mintMin = 20;
                    this.mintMax = 100;
                    }else if(this.mintingStage == 2){
                    // this.mintingAvailable = 8888 - mintedAmount;
                    this.mintMin = 1;
                    this.mintMax = 500;
                    }
                    if (x == 'load'){
                    this.mintQty = this.mintMin;
                    // console.log(this.mintQty); 
                    }else if( x == 'check'){
                    if (this.mintQty > this.mintingAvailable){
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Exceeded mintable amount',
                            text: 'Selected mint amount exceeded amount available.',
                            confirmButtonText: 'Return',
                            // allowOutsideClick: false,                        
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload();
                            }
                            if (result.isDismissed) {
                                window.location.reload();
                            }
                        });
                    }
                    }
                    
                }catch(e){
                    console.log(e.message)
                }
            })
        },    
        mintQtyInput(){   
        this.mintQty = parseInt(this.mintQty)
        if(this.mintQty>this.mintMax || this.mintQty<this.mintMin){
            this.hideMint = true;
            this.dontHideMint = false;
        }else{
            this.hideMint = false;
            this.dontHideMint = true;
        }
        },
        //-- wallet validation --/
        metaMask(){
            if(sessionStorage.connect != "true"){
                this.$swal.fire({
                    icon: 'error',
                    title: 'MetaMask not connected',
                    text: 'Please connect to your Metamask wallet.',
                    confirmButtonText: 'Return',
                    // allowOutsideClick: false,                        
                })
            }
        },

        // sleep func
        sleep(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
        },
        // adjust movement of upper NFT Collection
        moveLeftUpper () {
            this.innerStylesUp = {
                transform: `translateX(-${this.stepNftUp})
                            translateX(-${this.stepNftUp})`
            }
        },
        moveRightUpper () {
            this.innerStylesUp = {
                transform: `translateX(${this.stepNftUp})
                            translateX(-${this.stepNftUp})`
            }
        },
        // adjust movement of lower NFT Collection
        moveLeftLower () {
            this.innerStylesDown = {
                transform: `translateX(-${this.stepNftDown})
                            translateX(-${this.stepNftDown})`
            }
        },
        moveRightLower () {
            this.innerStylesDown = {
                transform: `translateX(${this.stepNftDown})
                            translateX(-${this.stepNftDown})`
            }
        },
        afterTransition (callback) {
            const listener = () => { 
                callback()
                this.$refs.nftUp.removeEventListener('transitionend', listener)
                this.$refs.nftDown.removeEventListener('transitionend', listener)
            }
            this.$refs.nftUp.addEventListener('transitionend', listener) 
            this.$refs.nftDown.addEventListener('transitionend', listener) 
        },
        resetTranslate (collection) {
        if (collection == "upper"){
            this.innerStylesUp = {
                transition: 'none',
                transform: `translateX(-${this.initialStepUpper})`
            }
        } else if (collection == "upper") {
            this.innerStylesDown = {
                transition: 'none',
                transform: `translateX(-${this.initialStepLower})`
            }
        }
        },
            //-- nft selection --//
        selectNFT(nftId){
            // console.log('change')
            //-- nft select --//
            var divId = "nftSelect"+nftId
            var getDiv = document.getElementById(divId);
            var content = getDiv.nextElementSibling;   
            var array = this.selectedNFT     

            if(getDiv.checked){    
                // console.log('check')
                if(this.baseAmt < this.selectLimit)   {
                if(array.includes(nftId) == false){
                    content.classList.add("testClass")
                    this.baseAmt = this.baseAmt + 1;
                    array.push(nftId);
                }          
                }else{
                getDiv.checked = false;
                }      
            }else{
                // console.log('no')
                content.classList.remove("testClass")
                this.baseAmt = this.baseAmt - 1;
                var index = array.indexOf(nftId);
                array.splice(index, 1);
            }

            // console.log(array)

        },
        async getLimit(){
            if(sessionStorage.connect != "true"){
                this.$swal.fire({
                    icon: 'error',
                    title: 'MetaMask not connected',
                    text: 'Please connect to your Metamask wallet.',
                    confirmButtonText: 'Return',
                    allowOutsideClick: false,                        
                }).then((result) => {
                    if (result.isConfirmed) {
                        // window.location.reload();
                        window.location.reload();
                    }
                });
            }

            this.mintStatus('check');
            this.mintQtyInput();
            let provider = new ethers.providers.Web3Provider(this.providerType)
            const signer = provider.getSigner()
            let userAddress = await signer.getAddress()
            axios.get('https://api.langkahsheraton.com/api/nft/getAllowSelect', {
                params: {          
                address: userAddress,
                mintAmount: this.mintQty,
                }
            }
            ).then((response) => {
                this.selectLimit = response.data.allow   
                // console.log(this.selectLimit)
            })
            // this.selectLimit = Math.floor(mint/100);
            // return(this.selectLimit)
        },

        //-- retrieve nft --//
        async getNFT(){
        
            this.connectStatus = sessionStorage.connect;            

            axios.get(            
                'https://api.langkahsheraton.com/api/nft/getAvailableSelectedNft'
                )
                .then((response) => {
                    // console.log(response)
                    this.nftData = response.data.data
                    this.rows = this.nftData.length            
                    for (let i = 0; i < this.nftData.length; i++){
                        this.availableNFT.push(this.nftData[i])                    
                    }
                    
                }) 
                .then(()=>{
                this.paginatedItems = this.availableNFT;
                this.totalRows = this.availableNFT.length;
                this.paginate(this.perPage, 0); //pagination
                // console.log(this.paginatedItems , this.totalRows)
                })       
                .catch(error =>{
                    console.log(error)
                });
            
        
        },
        //-- mint nft --//
        mintINC(){
            // this.mintQty = this.mintQty + 1;
            this.mintQty++;
            this.mintQtyInput()
        },
        mintDEC(){
            this.mintQty--;
            this.mintQtyInput()
        },
        async mint(){
            // console.log(this.mintAddress) 
            // console.log(this.selectedNFT.length , this.selectLimit)     

            if(this.selectedNFT.length == this.selectLimit){
                if  (this.tncCheck == true){
                    this.$swal.fire({
                    icon: 'warning',
                    title: 'Please Wait !',
                    html: 'NFT(s) minting is in progress.  Do not refresh or close this page.<br/> (Check Metamask confirmation pop-up window.)',
                    background: "#FDF0E9",       
                    allowOutsideClick: false,
                    didOpen: () => {
                        this.$swal.showLoading()
                    },
                    });
                    try{
                    let totalMintFee = 35*this.mintQty
                    // console.log(parseInt(totalMintFee));
                    let provider = new ethers.providers.Web3Provider(this.providerType) 
                    const signer = provider.getSigner()
                    let message = 'Mint NFT';
                    let signature = await signer.signMessage(message);
                    // console.log(await signer.getAddress(),)
                    // console.log(message, signature, this.mintQty, Object.values(this.selectedNFT))

                    const contract = new ethers.Contract(this.mintAddress, this.mintAbi, signer);
                    let mint = await contract.mint(parseInt(this.mintQty), {value: ethers.utils.parseEther(totalMintFee.toString())});
                    // console.log(mint)
                    // console.log(mint.hash)
                    await this.sleep(3000)
                    // console.log('sleep')
                    await axios.post('https://api.langkahsheraton.com/api/nft/mintNFT', {          
                        address: await signer.getAddress(),
                        signature: signature,
                        message: message,
                        amount: Number(this.mintQty),
                        imgID: Object.values(this.selectedNFT)
                    },{ headers: {'Authorization' : `Bearer ${sessionStorage.bt}`} }).then(async (response)=>{
                        console.log(response)
                        if(mint.hash){
                        this.$swal.close()
                        this.$swal.fire({
                            imageUrl:"../img/oilpaint1.fb5db1fb.png",
                            title: 'Congratulation!',
                            html: 'You have successfully minted <br/> your Langkah Sheraton NFT(s).<br/><br/> You can view your minted NFT here:<br/><a href="https://opensea.io/account" target="_blank" style="color:black;">opensea.io/account</a>',
                            confirmButtonText: 'CONTINUE',
                            background: "#FDF0E9",
                            // allowOutsideClick: false,                        
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload();
                            }
                            if (result.isDismissed) {
                                window.location.reload();
                            }
                        });
                        }
                    }).catch((error) =>{
                        console.log(error)
                        this.$swal.close()
                        this.$swal.fire({
                        icon: "error",
                        title: 'Minting failed',
                        html: 'Metamask minting transaction failed. Please ensure you have sufficient $MATIC and try again.',
                        confirmButtonText: 'CONTINUE',
                        background: "#FDF0E9",
                        // allowOutsideClick: false,                        
                        }).then((result) => {
                            if (result.isConfirmed) {
                            window.location.reload()
                                // window.location.replace("/#homeSec4");
                            }
                            if (result.isDismissed) { 
                            window.location.reload()                  
                            }
                        });
                    })
                
                    }catch(e){
                        this.$swal.close()
                        this.$swal.fire({
                        icon: "error",
                        title: 'Minting failed',
                        html: 'Metamask minting transaction failed. Please ensure you have sufficient $MATIC and try again.',
                        confirmButtonText: 'CONTINUE',
                        background: "#FDF0E9",
                        // allowOutsideClick: false,                        
                        }).then((result) => {
                            if (result.isConfirmed) {
                            window.location.reload()
                                // window.location.replace("/#homeSec4");
                            }
                            if (result.isDismissed) { 
                            window.location.reload()                  
                            }
                        });
                        console.log(e.message)
                    }

                }else{
                    this.$swal.fire({
                    icon: "error",
                    title: 'Terms and Conditions not agreed.',
                    html: 'Please agree to the Terms and Conditions and Privacy Policy',
                    confirmButtonText: 'CONTINUE',
                    background: "#FDF0E9",                       
                    })
                }
                
            }else{
                this.$swal.fire({
                icon: "error",
                title: 'Available NFT Selection',
                html: 'Please select your available ultra rare NFT(s).',
                confirmButtonText: 'CONTINUE',
                background: "#FDF0E9",                       
                })
                // .then((result) => {            
                // });
            }
 
        
        },
        async mint2(stage) {
            if(stage != 0 ){
                if(this.tncCheck == true){
                    this.$swal.fire({
                    icon: 'warning',
                    title: 'Please Wait !',
                    html: 'NFT(s) minting is in progress.  Do not refresh or close this page.<br/> (Check Metamask confirmation pop-up window.)',
                    background: "#FDF0E9",       
                    allowOutsideClick: false,
                    didOpen: () => {
                        this.$swal.showLoading()
                    },
                    });
                    try{

                    let mintFee = 40;
                    if(stage == 2){
                        mintFee = 45;
                    }
                    let totalMintFee = mintFee*this.mintQty
                    // console.log(totalMintFee)
                    let provider = new ethers.providers.Web3Provider(this.providerType) 
                    const signer = provider.getSigner()
                    let message = 'Mint NFT';
                    let signature = await signer.signMessage(message);
                    // console.log(await signer.getAddress(),)
                    // console.log(message, signature, this.mintQty, Object.values(this.selectedNFT))

                    const contract = new ethers.Contract(this.mintAddress, this.mintAbi, signer);
                    
                    let mint = await contract.mint(parseInt(this.mintQty), {value: ethers.utils.parseEther(totalMintFee.toString())})
                    // console.log(mint.data.message)
                    // console.log(mint.hash)

                    await axios.post('https://api.langkahsheraton.com/api/nft/mintNFT', {          
                        address: await signer.getAddress(),
                        signature: signature,
                        message: message,
                        amount: Number(this.mintQty),
                        imgID: []
                    },{ headers: {'Authorization' : `Bearer ${sessionStorage.bt}`} }).then(async (response)=>{
                        console.log(response)
                        await this.sleep(3000)
                        if(mint.hash){
                        // console.log('sleep')
                        this.$swal.close()
                        this.$swal.fire({
                            imageUrl:"../img/oilpaint1.fb5db1fb.png",
                            title: 'Congratulation!',
                            html: 'You have successfully minted <br/> your Langkah Sheraton NFT(s).<br/><br/> You can view your minted NFT here:<br/><a href="https://opensea.io/account" target="_blank" style="color:black;">opensea.io/account</a>',
                            confirmButtonText: 'CONTINUE',
                            background: "#FDF0E9",
                            // allowOutsideClick: false,                        
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload();
                            }
                            if (result.isDismissed) {
                                window.location.reload();
                            }
                        });
                        }
                    }).catch((error) =>{
                        console.log(error)
                        this.$swal.close()
                        this.$swal.fire({
                        icon: "error",
                        title: 'Minting failed',
                        html: 'Metamask minting transaction failed. Please ensure you have sufficient $MATIC and try again.',
                        confirmButtonText: 'CONTINUE',
                        background: "#FDF0E9",
                        // allowOutsideClick: false,                        
                        }).then((result) => {
                            if (result.isConfirmed) {
                            window.location.reload()
                                // window.location.replace("/#homeSec4");
                            }
                            if (result.isDismissed) { 
                            window.location.reload()                  
                            }
                        });
                    })
                
                    }catch(e){
                        this.$swal.close()
                        this.$swal.fire({
                        icon: "error",
                        title: 'Minting failed',
                        html: 'Metamask minting transaction failed. Please ensure you have sufficient $MATIC and try again.',
                        confirmButtonText: 'CONTINUE',
                        background: "#FDF0E9",
                        // allowOutsideClick: false,                        
                        }).then((result) => {
                            if (result.isConfirmed) {
                            window.location.reload()
                                // window.location.replace("/#homeSec4");
                            }
                            if (result.isDismissed) { 
                            window.location.reload()                  
                            }
                        });
                        console.log(e.message)
                    }
                }else{
                    this.$swal.fire({
                    icon: "error",
                    title: 'Terms and Conditions not agreed.',
                    html: 'Please agree to the Terms and Conditions and Privacy Policy',
                    confirmButtonText: 'CONTINUE',
                    background: "#FDF0E9",                       
                    })
                }                
            }
        },
        nftModalPage() {
            this.display1st = !this.display1st;
            this.display2nd = !this.display2nd;
        },
    }

}
</script>

<style>
.mint-desc{
    color: white;
    margin: 0 auto;
    /* width: 80%; */
    margin-bottom: 7%;
}

#mintnft .tncBox {
    display: flex;
    align-items: center;
    width: 25px;
}

#mintnft .tnc{
    text-align: left;
    padding-left: 15px;
    color: white;
    display: flex;
    align-items: center;
    font-size: 14px;
}

#mintnft .tnc a{
    /* font-weight: bold;     */
}

#mintnft .tnc a:hover{
    text-decoration: underline;   
}

.modal-tnc{
    width: 100%;
    display: flex;
    justify-content: center;
}

.modal-tnc .tnc, .modal-tnc .tnc a{
    color: rgba(45,45,65,255) !important;
}

#mintnft .testClass{
background: rgba(45,45,65,255) !important;
outline: 7px solid rgba(150,76,63,255);
border: 3px solid rgba(150,76,63,255);
}

#mintnft .testClass .nft-img{
opacity: 1;
}

#mintnft .testClass .nft-title{
text-shadow: 0px 0px 10px #000000;
}


@media screen and (min-width: 100px) {

    #mintnft .nft-box{
        height: 350px;
        width: 300px;
    }
}

</style>