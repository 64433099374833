<template >
    <div id="origin">
        <div class="title">
            <div class="container">
                <div class="row title-div">
                    <div class="col-9 d-flex justify-content-center flex-wrap">
                        <div class="col-12 text-center">
                            <h1>Origin</h1>
                        </div>
                        <div class="mt-4 d-flex">
                            <div class="mx-3"><router-link to="/about">ABOUT</router-link></div>
                            <div class="mx-3"><router-link to="/origin" style="color:orange">ORIGIN</router-link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="title-bg"></div>
        <div class="row content-wrap">
            <div class="img-main d-flex justify-content-center">
                <img src="../img/origin.jpeg" alt="">
                <div class="paint d-flex justify-content-center">
                    <img src="../img/oilpaint2.png" alt="">
                </div>
            </div>            
            <div class="content">
                <p>Tony Pua, the Member of Parliament for Damansara, Malaysia, completed the ‘Langkah Sheraton’ oil painting in January 2021. The masterpiece, 
                which took nearly 10 months to complete was created as part of a curated project to celebrate veteran opposition leader, Lim Kit Siang’s 
                80th birthday in February 2021.</p>
                <p>The project was launched with great fanfare at <b>Theatre Impian</b> where all the 100 works are on display. The exhibition was very well-received, 
                with many positive reviews from art lovers and critics alike. </p>
                <p>However, it was Tony’s ‘Langkah Sheraton’ which stood out as the preeminent attraction at the exhibition, because of the sheer ambition of the painting, 
                the historical narrative and value, the incredible detailing accomplished and the surprising quality of the finished art work for a debut artist.</p>                              
            </div>
            <content-pic img1='origin1.jpg' img2='origin2.jpg' img3='origin3.jpg' img4='origin4.jpg' img5='origin5.jpg'></content-pic>
            <div class="content">
                <p>Tony wanted ‘Langkah Sheraton’ to be put up for sale, with the entire proceeds to go towards the Democratic Action Party (DAP) in preparation for the next General Election.</p>
                <p>However, he was not certain as to the best approach. A typical sale would often see a painting stored in a collector’s private gallery or 
                storeroom, while no Government-owned gallery would acquire such a ‘sensitive’ or controversial piece, even if it was of historical import.</p>
                <p>The advancement of blockchain technology and increasing maturity of non-fungible tokens (NFTs) however, opened up a whole new 
                    world of possibilities. The painting can now be acquired not just by one person, but instead be meaningfully owned, collectively among thousands 
                    of Malaysians. Via the rapidly developing NFT platforms, it could unlock a higher value, and hence raise more funds for the Party and the 
                    cause. With a digital smart contract and a decentralized autonomous organization (DAO), backed by a real world legally-binding document, the 
                    owners of the art piece can as a whole, decide on the future of the painting.</p>
                <p>Thus, the Langkah Sheraton NFT project was born. The painting has been donated to DAP Malaysia. 8,888 NFTs will be minted for sale, 
                    where each NFT will represent 1 of 8,888 share of the physical painting, ‘Langkah Sheraton’ by Tony Pua, currently on display at Theatre Impian.</p>
            </div>
        </div>
    </div>
</template>

<script>
import contentPic from "../components/contentPic.vue"

export default {
    components:{contentPic},

    async mounted () {
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
.content > p > b{
    color:#9013FE;
}

#origin .img-main img{
    max-width: 1167px;
}

</style>